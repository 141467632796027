import { URL } from '../../../constants'
import { bypassReducer } from '../../../helpers/ReduxHelper'
import { Action, CR } from '../../../types'
import { LOCATION_CHANGED } from '../common'
import { StatType } from './types'

export const GET_MAIN_STAT = 'GET_MAIN_STAT'
export function getMainStat(): Action {
	return {
		type: GET_MAIN_STAT,
		call: URL + `/v2/customer/dashboard/main-stat`,
		payload: {},
		need_auth_token: true,
	}
}
export const GET_MAIN_STAT_RESPONSE = 'GET_MAIN_STAT_RESPONSE'
export const mainStatReducer = bypassReducer<CR<StatType>>(GET_MAIN_STAT, true, {}, [LOCATION_CHANGED])


