import { URL } from '../../../constants'
import { bypassReducer } from '../../../helpers/ReduxHelper'
import { ID, Action, CR } from '../../../types'
import { LOCATION_CHANGED } from '../common'

const GET_CONTACT_NOTES = 'GET_CONTACT_NOTES'
export function getContactNotes(contact_id: ID): Action {
	return {
		type: GET_CONTACT_NOTES,
		call: URL + `/v2/customer/contact-note/${contact_id}/list`,
		need_auth_token: true,
	}
}
export const GET_CONTACT_NOTES_RESPONSE = 'GET_CONTACT_NOTES_RESPONSE'
export const getContactNotesReducer = bypassReducer<CR<any>>(GET_CONTACT_NOTES, true, {}, [LOCATION_CHANGED])


const ADD_CONTACT_NOTES = 'ADD_CONTACT_NOTES'
export function addContactNotes(contact_id: ID, message: string): Action {
	return {
		type: ADD_CONTACT_NOTES,
		callPOST: URL + `/v2/customer/contact-note/${contact_id}`,
		queryData: { message },
		payload: { contact_id, message },
		need_auth_token: true,
	}
}
export const ADD_CONTACT_NOTES_RESPONSE = 'ADD_CONTACT_NOTES_RESPONSE'
export const addContactNotesReducer = bypassReducer<CR<any>>(ADD_CONTACT_NOTES, true, {}, [LOCATION_CHANGED])


const DELETE_CONTACT_NOTES = 'DELETE_CONTACT_NOTES'
export function deleteContactNotes(contact_id: ID): Action {
	return {
		type: DELETE_CONTACT_NOTES,
		callPOST: URL + `/v2/customer/contact-note/${contact_id}/delete`,
		payload: { contact_id },
		need_auth_token: true,
	}
}
export const DELETE_CONTACT_NOTES_RESPONSE = 'DELETE_CONTACT_NOTES_RESPONSE'
export const deleteContactNotesReducer = bypassReducer<CR<any>>(DELETE_CONTACT_NOTES, true, {}, [LOCATION_CHANGED])


const UPDATE_CONTACT_NOTES = 'UPDATE_CONTACT_NOTES'
export function updateContactNotes(contact_id: ID, message: string): Action {
	return {
		type: UPDATE_CONTACT_NOTES,
		callPOST: URL + `/v2/customer/contact-note/${contact_id}/update`,
		queryData: { message },
		payload: { contact_id, message },
		need_auth_token: true,
	}
}
export const UPDATE_CONTACT_NOTES_RESPONSE = 'UPDATE_CONTACT_NOTES_RESPONSE'
export const updateContactNotesReducer = bypassReducer<CR<any>>(UPDATE_CONTACT_NOTES, true, {}, [LOCATION_CHANGED])
