import { URL } from '../../../constants'
import { bypassReducer } from '../../../helpers/ReduxHelper'
import { Action, CR, ID } from '../../../types'
import { TagType } from './types'


const GET_TAG_LIST = 'GET_TAG_LIST'
export function getTagList(id: ID): Action {
	return {
		type: GET_TAG_LIST,
		call: URL + `/v2/customer/audience/${id}/tags`,
		need_auth_token: true,
	}
}
export const GET_TAG_LIST_RESPONSE = 'GET_TAG_LIST_RESPONSE'
export const getTagListReducer = bypassReducer<CR<TagType[]>>(GET_TAG_LIST, true, {})


const CREATE_TAG_FOR_AUDIENCE = 'CREATE_TAG_FOR_AUDIENCE'
export function createTagForAudience(id: ID, name: string): Action {
	return {
		type: CREATE_TAG_FOR_AUDIENCE,
		callPOST: URL + `/v2/customer/audience/${id}`,
		payload: { name },
		queryData: { name },
		need_auth_token: true,
	}
}
export const CREATE_TAG_FOR_AUDIENCE_RESPONSE = 'CREATE_TAG_FOR_AUDIENCE_RESPONSE'
export const createTagForAudienceReducer = bypassReducer<CR<boolean>>(CREATE_TAG_FOR_AUDIENCE, true, {})


const DELETE_TAG = 'DELETE_TAG'
export function deleteTag(tag_id: ID): Action {
	return {
		type: DELETE_TAG,
		callPOST: URL + `/v2/customer/tag/${tag_id}/delete`,
		need_auth_token: true,
	}
}
export const DELETE_TAG_RESPONSE = 'DELETE_TAG_RESPONSE'
export const deleteTagReducer = bypassReducer<CR<boolean>>(DELETE_TAG, true, {})


const UPDATE_TAG = 'UPDATE_TAG'
export function updateTag(tag_id: ID, name: string): Action {
	return {
		type: UPDATE_TAG,
		callPOST: URL + `/v2/customer/tag/${tag_id}/name`,
		need_auth_token: true,
		queryData: { name },
		payload: { name },
	}
}
export const UPDATE_TAG_RESPONSE = 'UPDATE_TAG_RESPONSE'
export const updateTagReducer = bypassReducer<CR<boolean>>(UPDATE_TAG, true, {})


const GET_CONTACT_TAGS_LIST = 'GET_CONTACT_TAGS_LIST'
export function getContactTagsList(id: ID): Action {
	return {
		type: GET_CONTACT_TAGS_LIST,
		call: URL + `/v2/customer/contact/${id}/tags`,
		need_auth_token: true,
	}
}
export const GET_CONTACT_TAGS_LIST_RESPONSE = 'GET_CONTACT_TAGS_LIST_RESPONSE'
export const getContactTagsReducer = bypassReducer<CR<TagType[]>>(GET_CONTACT_TAGS_LIST, true, {})


const GET_POSSIBLE_CONTACT_TAGS_LIST = 'GET_POSSIBLE_CONTACT_TAGS_LIST'
export function getPossibleContactTags(id: ID): Action {
	return {
		type: GET_POSSIBLE_CONTACT_TAGS_LIST,
		call: URL + `/v2/customer/contact/${id}/possible-tags`,
		need_auth_token: true,
	}
}
export const GET_POSSIBLE_CONTACT_TAGS_LIST_RESPONSE = 'GET_POSSIBLE_CONTACT_TAGS_LIST_RESPONSE'
export const getPossibleContactTagsReducer = bypassReducer<CR<TagType[]>>(GET_POSSIBLE_CONTACT_TAGS_LIST, true, {})


const ADD_TAG_FOR_CONTACT = 'ADD_TAG_FOR_CONTACT'
export function addTagForContact(contact_id: ID, tag_id: ID): Action {
	return {
		type: ADD_TAG_FOR_CONTACT,
		callPOST: URL + `/v2/customer/contact/${contact_id}/tag`,
		queryData: { tag_id },
		payload: { tag_id },
		need_auth_token: true,
	}
}
export const ADD_TAG_FOR_CONTACT_RESPONSE = 'ADD_TAG_FOR_CONTACT_RESPONSE'
export const addTagForContactReducer = bypassReducer<CR<TagType[]>>(ADD_TAG_FOR_CONTACT, true, {})


const DELETE_TAG_FOR_CONTACT = 'DELETE_TAG_FOR_CONTACT'
export function deleteTagForContact(contact_id: ID, tag_id: ID): Action {
	return {
		type: DELETE_TAG_FOR_CONTACT,
		callPOST: URL + `/v2/customer/contact/${contact_id}/delete-tag`,
		need_auth_token: true,
		queryData: { tag_id },
		payload: { tag_id },
	}
}
export const DELETE_TAG_FOR_CONTACT_RESPONSE = 'DELETE_TAG_FOR_CONTACT_RESPONSE'
export const deleteTagForContactReducer = bypassReducer<CR<TagType[]>>(DELETE_TAG_FOR_CONTACT, true, {})


