import React from "react";

import async from "../components/Async";


const Alerts = async(() => import("../pages/components/Alerts"));
const Avatars = async(() => import("../pages/components/Avatars"));
const Badges = async(() => import("../pages/components/Badges"));
const Buttons = async(() => import("../pages/components/Buttons"));
const Cards = async(() => import("../pages/components/Cards"));
const Chips = async(() => import("../pages/components/Chips"));
const Dialogs = async(() => import("../pages/components/Dialogs"));
const ExpPanels = async(() => import("../pages/components/ExpansionPanels"));
const Lists = async(() => import("../pages/components/Lists"));
const Menus = async(() => import("../pages/components/Menus"));
const Pagination = async(() => import("../pages/components/Pagination"));
const Progress = async(() => import("../pages/components/Progress"));
const Snackbars = async(() => import("../pages/components/Snackbars"));
const Tooltips = async(() => import("../pages/components/Tooltips"));

// Dashboards components
const Default = async(() => import("../pages/dashboards/Default"));
const Analytics = async(() => import("../pages/dashboards/Analytics"));

// Forms components
const Pickers = async(() => import("../pages/forms/Pickers"));
const SelectionCtrls = async(() => import("../pages/forms/SelectionControls"));
const Selects = async(() => import("../pages/forms/Selects"));
const TextFields = async(() => import("../pages/forms/TextFields"));
const Dropzone = async(() => import("../pages/forms/Dropzone"));
const Editors = async(() => import("../pages/forms/Editors"));

// Icons components
const MaterialIcons = async(() => import("../pages/icons/MaterialIcons"));
const FeatherIcons = async(() => import("../pages/icons/FeatherIcons"));


// Tables components
const SimpleTable = async(() => import("../pages/tables/SimpleTable"));
const AdvancedTable = async(() => import("../pages/tables/AdvancedTable"));

// Chart components
const Chartjs = async(() => import("../pages/charts/Chartjs"));

// Maps components
const GoogleMaps = async(() => import("../pages/maps/GoogleMaps"));
const VectorMaps = async(() => import("../pages/maps/VectorMaps"));

// Documentation
const Docs = async(() => import("../pages/docs/Documentation"));
const Changelog = async(() => import("../pages/docs/Changelog"));
const Presentation = async(() => import("../pages/docs/Presentation"));
const Test = async(() => import("../components/TestRoute"))

const Blank = async(() => import("../pages/pages/Blank"));
const InvoiceDetails = async(() => import("../pages/pages/InvoiceDetails"));
const InvoiceList = async(() => import("../pages/pages/InvoiceList"));
const Orders = async(() => import("../pages/pages/Orders"));
const Pricing = async(() => import("../pages/pages/Pricing"));
const Settings = async(() => import("../pages/pages/Settings"));
const Tasks = async(() => import("../pages/pages/Tasks"));
const Projects = async(() => import("../pages/pages/Projects"));
const Calendar = async(() => import("../pages/pages/Calendar"));

const Dashboard = async(() => import("../components/Dashboard/Dashboard"))
const ChatView = async(() => import("../components/ChatView"))
// Admin components
const Users = async(() => import("../components/Users"))

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"))
const SignUp = async(() => import("../pages/auth/SignUp"))
const MailPage = async(() => import("../pages/auth/MailPage"))
const ResetPassword = async(() => import("../pages/auth/ResetPassword"))
const Page404 = async(() => import("../pages/auth/Page404"))
const Page500 = async(() => import("../pages/auth/Page500"))

// Audience
const AudienceSettings = async(() => import("../components/AudienceSettings"))
const SingleLanding = async(() => import("../components/SingleLanding"))
const Newsletters = async(() => import("../components/Newsletters"))
const Statistic = async(() => import("../components/Statistic"))
const Newsletter = async(() => import("../components/Newsletters/Newsletter"))
const EditNewsletter = async(() => import("../components/Newsletters/Edit/Edit"))
const NewChannel = async(() => import("../components/AudienceSettings/Channels/NewChannel"))
const SubscribersImport = async(() => import("../components/AudienceSettings/Channels/SubscribersImport"))


// Funnels
const Funnels = async(() => import("../components/Funnels"))
const FunnelBuilder = async(() => import("../components/FunnelBuilder"))

// Unitpay
const UnitPaySuccess = async(() => import("../components/UnitPay/Success"))
const UnitPayFail = async(() => import("../components/UnitPay/Fail"))

// Test
const TestRoute = async(() => import("../components/TestRoute"))
const VKCallback = async(() => import("../components/TestRoute/VKCallback"))


// Pages components

const Profile = async(() => import("../components/Profile"))
const Billing = async(() => import("../components/Billing"))
const Landings = async(() => import("../components/Landings"))
const LandingsVK = async(() => import("../components/LandingsVK"))
const Landing = async(() => import("../components/Landings/Landing"))
const LandingVK = async(() => import("../components/LandingsVK/Landing"))
const Faq = async(() => import("../components/Faq"))
const CustomPages = async(() => import("../components/CustomPages"))
const Partners = async(() => import("../components/Partners"))

// Layouts
const MainLanding = async(() => import("../components/MainLanding"))
const AuthLayout = async(() => import("../layouts/Auth"))
const DashboardLayout = async(() => import("../layouts/DashboardLayout"))
const LandingLayout = async(() => import("../layouts/Landing"))
const MainLandingLayout = async(() => import("../layouts/MainLandingLayout/MainLanding"))
const EmptyLayout = async(() => import("../layouts/EmptyLayout"))


const test = [
	{
		path: "/test",
		name: "Test",
		component: Test,
		layout: DashboardLayout,
	},
	{
		path: "/test-vk",
		name: "Test VKIcon",
		component: VKCallback,
	},
]

const pages = [
	{
		path: "/profile",
		name: "Профиль",
		component: Profile,
	},
	{
		path: "/billing",
		name: "Биллинг",
		component: Billing,
	},
	{
		path: "/faq",
		name: "Справка",
		component: Faq,
	},
	{
		path: "/partner",
		name: "Партнерская программа",
		component: Partners,
	},
]

const audience = [
	{
		path: "/audience/:id/thread/:threadKey?",
		name: "Сообщения",
		component: ChatView,
	},
	{
		path: "/statistic/:id",
		name: "Статистика",
		component: Statistic,
	},
	{
		path: "/audience-settings/:id",
		name: "Настройки",
		component: AudienceSettings,
	},
	{
		path: "/audience-settings/:id/channels/new",
		name: "Добавить канал",
		component: NewChannel,
	},
	{
		path: "/audience-settings/:id/channels/:channel_id/import",
		name: "Импорт подписчиков",
		component: SubscribersImport,
	},
	{
		path: "/audience/:id/funnels",
		name: "Автоворонки",
		component: Funnels,
	},
	{
		path: "/audience/:id/landings",
		name: "Лендинги",
		component: Landings,
	},
	{
		path: "/audience/:id/landings/:landing_id",
		name: "Лендинг",
		component: Landing,
	},
	{
		path: "/audience/:id/landings-vk",
		name: "Лендинги",
		component: LandingsVK,
	},
	{
		path: "/audience/:id/landings-vk/:landing_id",
		name: "Лендинг",
		component: LandingVK,
	},
	{
		path: "/audience/:id/newsletters",
		name: "Рассылки",
		component: Newsletters,
	},
	{
		path: "/audience/:id/newsletters/:mailing_id",
		name: "Рассылки",
		component: Newsletter,
	},
	{
		path: "/audience/:id/newsletters/:mailing_id/edit",
		name: "Рассылки редактировать",
		component: EditNewsletter,
	},
	{
		path: "/audience/:id/funnels",
		name: "Автоворонки",
		component: Funnels,
	},
	{
		path: "/admin/users",
		name: "Пользователи",
		component: Users
	},
	{
		path: "/audience/:id/funnels/:funnel_id",
		name: "Билдер",
		component: FunnelBuilder,
	},
	{
		path: "/unit-pay/success",
		name: "UnitPaySuccess",
		component: UnitPaySuccess,
	},
	{
		path: "/unit-pay/fail",
		name: "UnitPayFail",
		component: UnitPayFail,
	},
]


export const authRoutes = [
	{
		path: "/auth/sign-in",
		name: "Sign In",
		component: SignIn,
		layout: AuthLayout,

	},
	{
		path: "/auth/sign-up",
		name: "Sign Up",
		component: SignUp,
		layout: AuthLayout,

	},
	{
		path: "/auth/email",
		name: "Mail Page",
		component: MailPage,
		layout: AuthLayout,

	},
	{
		path: "/auth/reset-password",
		name: "Reset Password",
		component: ResetPassword,
		layout: AuthLayout,

	},
	{
		path: "/auth/404",
		name: "404 Page",
		component: Page404,
		layout: AuthLayout,

	},
	{
		path: "/auth/500",
		name: "500 Page",
		component: Page500,
		layout: AuthLayout,

	},
]

export const guestRoutes = [
	{
		path: "/l/:token",
		name: "Лендинг",
		component: SingleLanding,
		layout: LandingLayout,
	},
	{
		path: "/eng/:url",
		name: "Справка",
		component: CustomPages,
		layout: MainLandingLayout,
		routeProps: {
			path: 'eng',
		},
	},
	{
		path: "/rus/:url",
		name: "Справка",
		component: CustomPages,
		layout: MainLandingLayout,
		routeProps: {
			path: 'rus',
		},
	},

]
export const mainRoutes = [
	{
		path: "/",
		exact: true,
		name: 'Дашборд',
		component: Dashboard,
	},

	...audience,
	...pages,
	...test,
]


type RoutesType = {
	path: string
	name: string
	component: string
	layout: any
}
export const testRoutes: RoutesType[] = [
	{
		path: "/auth/sign-in",
		name: "Sign In",
		component: SignIn,
		layout: AuthLayout,
	},
	{
		path: "/",
		name: 'Лендинг',
		component: MainLanding,
		layout: LandingLayout,
	},
]
