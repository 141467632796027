import { SUCCESS, FAIL, START } from '../../constants'


export default store => next => (action) => {
	const { callDELETE, type, token, thenAction, queryData } = action

	if (!callDELETE) return next(action)


	next({
		...action,
		type: type + START
	})

	const xhr = new XMLHttpRequest();

	xhr.open('DELETE', callDELETE, true);

	if (token) 	xhr.setRequestHeader('Authorization', 'Bearer ' + token)

	const body = queryData ? JSON.stringify(queryData) : ''

	xhr.send(body); // (1)

	xhr.onreadystatechange = function () { // (3)
		if (xhr.readyState != 4) return;

		if (xhr.status != 200) {
			next({
				...action,
				type: type + FAIL,
				error: xhr.status + ': ' + xhr.statusText,
				success: false,
			})


		} else {
			const resp = JSON.parse(xhr.responseText)

			next({
				...action,
				type: type + SUCCESS,
				response: {
					data: resp.response,
					error: resp.status === 100 || resp.messages[0].type === 2
						? resp.messages[0].message : null,
					status: 'success',
					success: true,
					messages: resp.messages,
					message: resp.status === 0 || resp.messages[0].type === 0
						? resp.messages[0].message : null
				}

			})
			if (thenAction) {
				store.dispatch(thenAction.func(...thenAction.args))
			}
		}

	}

}
