import { stringify } from 'qs'

type AdditionalParams = {
	arrayFormat?: string
	ignoreQueryPrefix?: boolean
}
export function urlBuild(params: any, additionalParams?: AdditionalParams): string {
	additionalParams = additionalParams ?? {}
	const { arrayFormat = 'brackets', ignoreQueryPrefix = false } = additionalParams
	// arrayFormat can be 'indices'
	return stringify(params, {
		arrayFormat,
		encode: false,
		skipNulls: true,
		addQueryPrefix: !ignoreQueryPrefix,
		ignoreQueryPrefix,
	})
}

export function declOfNum(n, text_forms) {
	n = Math.abs(n) % 100;
	var n1 = n % 10;
	if (n > 10 && n < 20) {
		return text_forms[2];
	}
	if (n1 > 1 && n1 < 5) {
		return text_forms[1];
	}
	if (n1 == 1) {
		return text_forms[0];
	}
	return text_forms[2];
}

export function priceSet(data) {
	/*
	 * В переменной price приводим получаемую переменную в нужный вид:
	 * 1. принудительно приводим тип в число с плавающей точкой,
	 *    учли результат 'NAN' то по умолчанию 0
	 * 2. фиксируем, что после точки только в сотых долях
	 */
	var price = Number.prototype.toFixed.call(parseFloat(data) || 0, 2),
		//заменяем точку на запятую
		price_sep = price.replace(/'.'/g, ","),
		//добавляем пробел как разделитель в целых
		price_sep = price_sep.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");

	return price_sep
}
function isAnchor(str) {
	return /<a[\s]+([^>]+)>/gi.test(str);
}

export function createTextLinks(text) {
	if (isAnchor(text)) return text
	const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
	return text.replace(urlRegex, function (url) {
		return '<a target="_blank" href="' + url + '">' + url + '</a>';
	})
}
export function getFileNameFromPath(path){
	return path.replace(/^.*[\\\/]/, '')
}
