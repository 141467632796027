import { ListItem } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import { darken, rgba } from 'polished'

import NavLink from '../../components/common/NavLink'


export const SidebarHeaderLink = styled(ListItem)<{ activeClassName: string, component?: any, exact?: boolean, to?: string }>`
  color: ${props => props.theme.sidebar.color};
  padding: 10px 24px;
  ${props => props.theme.spacing(6)}px ${props => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: flex;
  align-items: center;

  span {
    font-size: ${props => props.theme.typography.body1.fontSize}px;
  }

  margin-top: 0;
  margin-bottom: 0;

  &:hover span {
    color: ${props => rgba(props.theme.sidebar.color, 0.9)};
  }

  &.${props => props.activeClassName} {
    background-color: ${props => darken(0.06, props.theme.sidebar.background)};

    span {
      color: ${props => props.theme.sidebar.color};
    }
  }

  svg {
    margin-right: 10px;
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.8;
  }
`
const AHref = styled.a<{ activeClassName: string }>`
  color: ${props => props.theme.sidebar.color};
  padding: 10px 24px;
  ${props => props.theme.spacing(6)}px ${props => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: flex;
  align-items: center;
  text-decoration: none;

  margin-top: 0;
  margin-bottom: 0;
  span {
    font-size: ${props => props.theme.typography.body1.fontSize}px;
  }


  &:hover span {
    color: ${props => rgba(props.theme.sidebar.color, 0.9)};
  }

  &.${props => props.activeClassName} {
    background-color: ${props => darken(0.06, props.theme.sidebar.background)};

    span {
      color: ${props => props.theme.sidebar.color};
    }
  }
  
  svg {
    margin-right: 10px;
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.8;
  }
`

interface Props {
	name: string
	to: string
	absolute?: boolean
	target?: boolean
	icon?: any
	component?: any
	handleClick?: (ev: any) => void
}


const HeaderLink: React.FC<Props> = (props) => {
	const { name, to, absolute = false, target = true, icon = null, component = NavLink, handleClick } = props
	if (absolute) {
		return (
			<AHref
				href={to}
				rel="noopener noreferrer"
				activeClassName="active"
				target={target ? '_blank' : "_self"}
			>
				{icon}
				{name}
			</AHref>)
	}
	return (
		<SidebarHeaderLink
			button
			dense
			component={component}
			onClick={handleClick}
			exact
			to={to}
			activeClassName="active"
		>
			{icon}
			{name}
		</SidebarHeaderLink>
	)
}

export default HeaderLink
