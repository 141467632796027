import { useEffect } from 'react'
import { MONTH_SEC } from '../constants'
import { setCookie, getCookie } from '../helpers'

export default () => {
	const { search } = window.location
	useEffect(() => {
		if (search) {
			const domain = window.location.hostname === 'localhost' ? 'localhost' : '.' + window.location.hostname
			let cookie = getCookie('utm_guest') ? getCookie('utm_guest') + '%%%' : ''
			const date_cookie = Math.round(new Date().getTime() / 1000)
			cookie += search + '$$$date_cookie=' + date_cookie
			setCookie('utm_guest', cookie.replace('?', ''), { domain, path: '/', 'max-age': MONTH_SEC })
		}
	}, [search])

}
