import { mobileCheck } from './helpers/dom'
import * as serviceWorker from './serviceWorker';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import Notify from './components/Notify'
import store from './redux/store';
import { SnackbarProvider } from 'notistack'
import io from 'socket.io-client'
import { SOCKET_URL } from './constants';
import './index.css'
// export const socket = io.connect('http://localhost:3000')
export const socket = io.connect(SOCKET_URL, {
	secure: true,
	rejectUnauthorized: false,
})

socket.on('disconnect', () => {
	console.log('socket disconnect')
});
socket.on('connect', () => {
	console.log('socket connect')
});

socket.io.on("reconnection_attempt", () => {
	console.log('reconnection_attempt')
});

socket.io.on("reconnect", () => {
	console.log('reconnect')
});
socket.on("connect_error", () => {
	console.log(`connect_error`)
	// setTimeout(() => {
	// 	socket.connect();
	// }, 1000);
});

const notifyOrientation = mobileCheck()
	? { vertical: 'bottom', horizontal: 'center' }
	: { vertical: 'top', horizontal: 'right' }

ReactDOM.render(
	<Provider store={store}>
		<SnackbarProvider
			// @ts-ignore
			anchorOrigin={notifyOrientation}
		>
			<Notify />
			<App />
		</SnackbarProvider>
	</Provider>, document.getElementById('root'),
)

serviceWorker.unregister();

//
