import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet'
import { connect, ConnectedProps } from 'react-redux'
import { Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import {
	CHECK_EMAIL_RESPONSE,
	checkEmail,
	CONFIRM_EMAIL_RESPONSE,
	confirmEmail,
	moduleName,
	SEND_EMAIL_RESPONSE,
	sendEmail, clearCheckEmail, clearConfirmEmailPassword,
} from '../../../redux/ducks/auth'
import { CR } from '../../../types'
import EnterMail from './EnterMail'
import EnterCode from './EnterCode'
import { Button, Wrapper } from '../../../styles'
import { RootState } from "../../../redux/store";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
		},
		backButton: {
			marginRight: theme.spacing(1),
		},
		instructions: {
			textAlign: 'center',
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	}),
)

function getSteps() {
	return ['Введите почту', 'Введите код из письма с подтверждением'];
}


interface Props extends PropsFromRedux {
	getUserData: () => void
	handleClose: () => void
}

const Index: React.FC<Props> = (props) => {
	const { checkEmailAvailableResponse, confirmEmailResponse, userData } = props
	const [activeStep, setActiveStep] = useState(0)
	const [email, setEmail] = useState('')
	const [code, setCode] = useState('')
	const [error, setError] = useState({ email: false, code: false })
	const classes = useStyles()
	const steps = getSteps()

	const anotherEmail = () => setActiveStep(0)
	const checkCodeStep = () => setActiveStep(1)
	const successStep = () => setActiveStep(2)

	useEffect(() => {
		if (userData?.data?.email) {
			setEmail(userData.data.email)
			if (!confirmEmailResponse?.success && userData.data.status_id === 2) setActiveStep(1)
		}
	}, [userData])

	useEffect(() => {
		if (checkEmailAvailableResponse?.data) {
			props.sendEmail(email)
			setError((prev) => ({ ...prev, email: false }))
			checkCodeStep()
			props.getUserData()
			props.clearCheckEmail()
		}
		if (checkEmailAvailableResponse?.data === false) {
			setError((prev) => ({ ...prev, email: true }))
		}
	}, [checkEmailAvailableResponse])

	useEffect(() => {
		if (confirmEmailResponse.success) {
			successStep()
			setTimeout(() => {
				window.location.href = window.origin
			}, 3000)

		}
	}, [confirmEmailResponse])

	const handleBlur = (ev) => {
		ev.preventDefault()
		setError((prev) => ({ ...prev, email: false }))
		const { value } = ev.target
		if (value) setEmail(value)
	}

	const handleBlurCode = (ev) => {
		ev.preventDefault()
		setError((prev) => ({ ...prev, code: false }))
		const { value } = ev.target
		if (value) setCode(value)
	}

	const confirmCodeStep = () => {
		if (activeStep === 1) {
			if (code) props.confirmEmail(code)
		}
	}

	const confirmEmailStep = () => {
		if (activeStep === 0) {
			if (email) props.checkEmail(email)
		}

	}


	const getStepContent = (stepIndex: number) => {
		switch (stepIndex) {
			case 0:
				return <EnterMail error={error} handleBlur={handleBlur} />
			case 1:
				return <EnterCode error={error} handleBlur={handleBlurCode} />
			default:
				return 'Ошибка'
		}
	}

	return (
		<Wrapper>
			<Helmet title="Войти" />
			<div className={classes.root}>
				<Stepper activeStep={activeStep} alternativeLabel>
					{steps.map((label) => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>
				<div>
					{activeStep === steps.length
						? <Typography variant="h4" className={classes.instructions}>Спасибо</Typography>
						: (
							<div>
								<Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
								<div>
									{activeStep === 1 && (
										<Button
											onClick={anotherEmail}
											className={classes.backButton}
										>
											Ввести другой e-mail
										</Button>)}

									{activeStep === 0 && (
										<Button variant="contained" color="primary" onClick={confirmEmailStep}>
											Проверить почту
										</Button>
									)}
									{activeStep === 1 && (
										<Button variant="contained" color="primary" onClick={confirmCodeStep}>
											Проверить код
										</Button>
									)}

									{activeStep === 0 && userData?.data?.email && (
										<Button
											onClick={checkCodeStep}
											className={classes.backButton}
										>
											Оставить {userData.data.email}
										</Button>
									)}
								</div>
							</div>
						)}
				</div>
			</div>
		</Wrapper>
	);
}

interface cState {
	checkEmailAvailableResponse: any
	confirmEmailResponse: any
	sendResponse: any
	userData: CR<any>
}

interface cProps {
	checkEmail: typeof checkEmail
	sendEmail: typeof sendEmail
	confirmEmail: typeof confirmEmail
	clearCheckEmail: typeof clearCheckEmail
	clearConfirmEmailPassword: typeof clearConfirmEmailPassword
}

const connector = connect((state: RootState) => ({
	checkEmailAvailableResponse: state[moduleName + CHECK_EMAIL_RESPONSE],
	sendResponse: state[moduleName + SEND_EMAIL_RESPONSE],
	confirmEmailResponse: state[moduleName + CONFIRM_EMAIL_RESPONSE],
	userData: state.common.userData,
}), { checkEmail, sendEmail, confirmEmail, clearConfirmEmailPassword, clearCheckEmail })
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(Index)