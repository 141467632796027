import React from "react";

import Loader from "./Loader";


export default function asyncComponent(importComponent) {
	return class AsyncComponent extends React.Component {

		constructor(props) {
			super(props);

			this.state = {
				component: null
			};
		}
		sleep = (time) => new Promise((resolve) => {
			this.timeout = setTimeout(resolve, time)
		})

		async componentDidMount() {
			await this.sleep(150);
			const { default: component } = await importComponent();

			this.setState({
				component: component
			})
		}

		componentWillUnmount() {
			clearTimeout(this.timeout);
		}

		render() {
			const C = this.state.component;

			return C ? <C {...this.props} /> : <Loader />
		}
	}
}
