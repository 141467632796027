import {
	addFileReducer, changePassword,
	commonReducer,
	deleteTaskMessagesReducer,
	faqReducer, getJivoForDashboard,
	getJivoForLanding,
	getLandingInfoReducer,
	getMainLandingReducer,
	getMenuReducer,
	getUserData,
	imageFilePreviewReducer,
	landingVisitFormReducer, sendErrorMessageToBot, setJivoActive, setJivoDisable,
	softDeleteTaskMessagesReducer,
	updateMenuReducer,
} from '../index'
import { combineReducers } from "redux";

export default combineReducers({
	addFileResponse: addFileReducer,
	commonValues: commonReducer,
	changePasswordResponse: changePassword.reducer,
	deleteTaskFileResponse: deleteTaskMessagesReducer,
	faqResponse: faqReducer,
	getMenuResponse: getMenuReducer,
	imageFilePreviewResponse: imageFilePreviewReducer,
	jivoForLanding: getJivoForLanding.reducer,
	jivoForDashboard: getJivoForDashboard.reducer,
	jivoActive: setJivoActive.reducer,
	jivoDisable: setJivoDisable.reducer,
	landingVisitFormResponse: landingVisitFormReducer,
	landingInfoResponse: getLandingInfoReducer,
	mainLandingResponse: getMainLandingReducer,
	softDeleteTaskImageResponse: softDeleteTaskMessagesReducer,
	sendErrorMessageToBotResponse: sendErrorMessageToBot.reducer,
	userData: getUserData.reducer,
	updateMenuResponse: updateMenuReducer,
})