import { bypassReducer, reduxToolkit } from "helpers/ReduxHelper"
import { Action, CR, ID } from "types"
import { URL, SUCCESS } from '../../../constants'
import { LOCATION_CHANGED } from '../common'
import { FunnelType } from './types'
import produce from "immer"


const CLEAR_FUNNEL_RESPONSE = 'CLEAR_FUNNEL_RESPONSE'

export function clearFunnelResponse() {
	return { type: CLEAR_FUNNEL_RESPONSE }
}


const GET_AUDIENCE_FUNNELS_TAGS = 'GET_AUDIENCE_FUNNELS_TAGS'

export function getAudienceFunnelsTags(id: ID): Action {
	return {
		type: GET_AUDIENCE_FUNNELS_TAGS,
		call: URL + `/v2/customer/audience-funnel/${id}/tags`,
		need_auth_token: true,
	}
}

export const GET_AUDIENCE_FUNNELS_TAGS_RESPONSE = 'GET_AUDIENCE_FUNNELS_TAGS_RESPONSE'
export const getAudienceFunnelsTagsReducer = bypassReducer<CR<any>>(GET_AUDIENCE_FUNNELS_TAGS, true, {})


const GET_AUDIENCE_FUNNELS = 'GET_AUDIENCE_FUNNELS'

export function getAudienceFunnels(id: ID): Action {
	return {
		type: GET_AUDIENCE_FUNNELS,
		call: URL + `/v2/customer/audience-funnel/${id}`,
		need_auth_token: true,
	}
}

export const GET_AUDIENCE_FUNNELS_RESPONSE = 'GET_AUDIENCE_FUNNELS_RESPONSE'
export const getAudienceFunnelsReducer = bypassReducer<CR<FunnelType[]>>(GET_AUDIENCE_FUNNELS, true, {})


const GET_AUDIENCE_FUNNEL = 'GET_AUDIENCE_FUNNEL'

export function getAudienceFunnel(id: ID): Action {
	return {
		type: GET_AUDIENCE_FUNNEL,
		call: URL + `/v2/customer/audience-funnel/${id}/view`,
		need_auth_token: true,
	}
}

export const GET_AUDIENCE_FUNNEL_RESPONSE = 'GET_AUDIENCE_FUNNEL_RESPONSE'
export const getAudienceFunnelReducer = bypassReducer<CR<any>>(GET_AUDIENCE_FUNNEL, true, {})


const GET_AUDIENCE_FUNNEL_ADDITIONAL = 'GET_AUDIENCE_FUNNEL_ADDITIONAL'

export function getAudienceFunnelAdditional(id: ID): Action {
	return {
		type: GET_AUDIENCE_FUNNEL_ADDITIONAL,
		call: URL + `/v2/customer/audience-funnel/${id}/info`,
		need_auth_token: true,
	}
}

export const GET_AUDIENCE_FUNNEL_ADDITIONAL_RESPONSE = 'GET_AUDIENCE_FUNNEL_ADDITIONAL_RESPONSE'
export const getAudienceFunnelAdditionalReducer = bypassReducer<CR<any>>(GET_AUDIENCE_FUNNEL_ADDITIONAL, true, {})


type AddParams = {
	name: string
}
const CREATE_AUDIENCE_FUNNEL = 'CREATE_AUDIENCE_FUNNEL'

export function createAudienceFunnel(id: ID, params: AddParams): Action {
	return {
		type: CREATE_AUDIENCE_FUNNEL,
		callPOST: URL + `/v2/customer/audience-funnel/${id}`,
		payload: { params },
		queryData: {
			AudienceFunnelForm: params,
		},
		need_auth_token: true,
	}
}

export const CREATE_AUDIENCE_FUNNEL_RESPONSE = 'CREATE_AUDIENCE_FUNNEL_RESPONSE'
export const createAudienceFunnelReducer = bypassReducer<CR<any>>(CREATE_AUDIENCE_FUNNEL, true, {}, [LOCATION_CHANGED, CLEAR_FUNNEL_RESPONSE])


const UPDATE_AUDIENCE_FUNNEL = 'UPDATE_AUDIENCE_FUNNEL'

export function updateAudienceFunnel(id: ID, params: AddParams): Action {
	return {
		type: UPDATE_AUDIENCE_FUNNEL,
		callPOST: URL + `/v2/customer/audience-funnel/${id}/update`,
		payload: { id, params },
		queryData: {
			AudienceFunnelForm: params,
		},
		need_auth_token: true,
	}
}

export const UPDATE_AUDIENCE_FUNNEL_RESPONSE = 'UPDATE_AUDIENCE_FUNNEL_RESPONSE'
export const updateAudienceFunnelReducer = bypassReducer<CR<any>>(UPDATE_AUDIENCE_FUNNEL, true, {}, [LOCATION_CHANGED, CLEAR_FUNNEL_RESPONSE])


const GET_FUNNEL_ACTIONS = 'GET_FUNNEL_ACTIONS'

export function getFunnelActions(id: number): Action {
	return {
		type: GET_FUNNEL_ACTIONS,
		call: URL + `/v2/customer/funnel-view/${id}/actions`,
		need_auth_token: true,
		payload: { id },
	}
}

export const GET_FUNNEL_ACTIONS_RESPONSE = 'GET_FUNNEL_ACTIONS_RESPONSE'
export const getFunnelActionsReducer = produce((draft, action) => {
	const { type, payload, response } = action
	switch (type) {
		case GET_FUNNEL_ACTIONS + SUCCESS:
			draft[payload.id] = response.data
			return
		default:
			return draft
	}
}, {})

const PERFORM_ACTION = 'PERFORM_ACTION'

export function performAction(id: number, action: string): Action {
	return {
		type: PERFORM_ACTION,
		callPOST: URL + `/v2/customer/audience-funnel/${id}/${action}`,
		need_auth_token: true,
	}
}

export const PERFORM_ACTION_RESPONSE = 'PERFORM_ACTION_RESPONSE'
export const performActionReducer = bypassReducer<CR<any>>(PERFORM_ACTION, true, {}, [LOCATION_CHANGED, CLEAR_FUNNEL_RESPONSE])


type SaveParams = {
	step: number
	message: string
	time: number
}
const SAVE_AUDIENCE_FUNNEL = 'SAVE_AUDIENCE_FUNNEL'

export function saveAudienceFunnel(id: ID, params_view: any, params: SaveParams[]): Action {
	return {
		type: SAVE_AUDIENCE_FUNNEL,
		callPOST: URL + `/v2/customer/audience-funnel/${id}/save`,
		payload: { id, params_view, params },
		queryData: { params_view, params },
		need_auth_token: true,
	}
}

const CLEAR_SAVE_FUNNEL_RESPONSE = 'CLEAR_SAVE_FUNNEL_RESPONSE'

export function clearSaveFunnelResponse() {
	return { type: CLEAR_SAVE_FUNNEL_RESPONSE }
}

export const SAVE_AUDIENCE_FUNNEL_RESPONSE = 'SAVE_AUDIENCE_FUNNEL_RESPONSE'
export const saveAudienceFunnelReducer = bypassReducer<CR<any>>(SAVE_AUDIENCE_FUNNEL, true, {}, [LOCATION_CHANGED, CLEAR_SAVE_FUNNEL_RESPONSE])


const ADD_FUNNEL_TAG = 'ADD_FUNNEL_TAG'

export function addFunnelTag(id: number, tag: string): Action {
	return {
		type: ADD_FUNNEL_TAG,
		callPOST: URL + `/v2/customer/audience-funnel/${id}/tag`,
		queryData: { tag },
		need_auth_token: true,
	}
}

export const ADD_FUNNEL_TAG_RESPONSE = 'ADD_FUNNEL_TAG_RESPONSE'
export const addFunnelTagReducer = bypassReducer<CR<any>>(ADD_FUNNEL_TAG, true, {}, [LOCATION_CHANGED, CLEAR_FUNNEL_RESPONSE])


const ADD_FUNNEL_STOP_TAG = 'ADD_FUNNEL_STOP_TAG'

export function addFunnelStopTag(id: number, stop_tag: string): Action {
	return {
		type: ADD_FUNNEL_STOP_TAG,
		callPOST: URL + `/v2/customer/audience-funnel/${id}/stop-tag`,
		queryData: { stop_tag },
		need_auth_token: true,
	}
}

export const ADD_FUNNEL_STOP_TAG_RESPONSE = 'ADD_FUNNEL_STOP_TAG_RESPONSE'
export const addFunnelStopTagReducer = bypassReducer<CR<any>>(ADD_FUNNEL_STOP_TAG, true, {}, [LOCATION_CHANGED, CLEAR_FUNNEL_RESPONSE])

const CHECK_IS_NEW = 'CHECK_IS_NEW'

export function checkIsNew(id: number, is_new: number): Action {
	return {
		type: CHECK_IS_NEW,
		callPOST: URL + `/v2/customer/audience-funnel/${id}/is-new`,
		queryData: { is_new },
		need_auth_token: true,
	}
}

export const CHECK_IS_NEW_RESPONSE = 'CHECK_IS_NEW_RESPONSE'
export const checkIsNewReducer = bypassReducer<CR<any>>(CHECK_IS_NEW, true, {}, [LOCATION_CHANGED, CLEAR_FUNNEL_RESPONSE])


const CHECK_IS_REPEAT = 'CHECK_IS_REPEAT'

export function checkIsRepeat(id: number, is_repeat: number): Action {
	return {
		type: CHECK_IS_REPEAT,
		callPOST: URL + `/v2/customer/audience-funnel/${id}/is-repeat`,
		queryData: { is_repeat },
		need_auth_token: true,
	}
}

export const CHECK_IS_REPEAT_RESPONSE = 'CHECK_IS_REPEAT_RESPONSE'
export const checkIsRepeatReducer = bypassReducer<CR<any>>(CHECK_IS_REPEAT, true, {}, [LOCATION_CHANGED, CLEAR_FUNNEL_RESPONSE])

const CHANGE_FUNNEL_NAME = 'CHANGE_FUNNEL_NAME'

export function changeFunnelName(id: number, name: string): Action {
	return {
		type: CHANGE_FUNNEL_NAME,
		callPOST: URL + `/v2/customer/audience-funnel/${id}/change-name`,
		queryData: { name },
		need_auth_token: true,
	}
}

export const CHANGE_FUNNEL_NAME_RESPONSE = 'CHANGE_FUNNEL_NAME_RESPONSE'
export const changeFunnelNameReducer = bypassReducer<CR<any>>(CHANGE_FUNNEL_NAME, true, {}, [LOCATION_CHANGED, CLEAR_FUNNEL_RESPONSE])

const START_FUNNEL = 'START_FUNNEL'

export function startFunnel(funnel_id): Action {
	return {
		type: START_FUNNEL,
		callPOST: URL + `/v2/customer/audience-funnel/${funnel_id}/active`,
		need_auth_token: true,
	}
}

export const START_FUNNEL_RESPONSE = 'START_FUNNEL_RESPONSE'
export const startFunnelReducer = bypassReducer<CR<any>>(START_FUNNEL, true, {}, [LOCATION_CHANGED, CLEAR_FUNNEL_RESPONSE])

export const copyFunnel = reduxToolkit('COPY_FUNNEL',
	(id: ID) => ({
		callPOST: `${URL}/v2/customer/audience-funnel/${id}/copy`,
	}))

export const deleteRequestByContract = reduxToolkit('COPY_FUNNEL',
	(id: ID, contact_id) => ({
		callPOST: `${URL}/v2/customer/audience-funnel-view/${id}/delete-send-contact`,
		queryData: { contact_id: Number(contact_id) }
	}))

export const getRegularType = reduxToolkit('GET_REGULAR_TYPE',
	(id: ID) => ({
		call: `${URL}/v2/customer/audience-funnel/${id}/get-regular-type`,
	}))

export const getRegularTypeDay = reduxToolkit('GET_REGULAR_TYPE_DAY',
	(id: ID) => ({
		call: `${URL}/v2/customer/audience-funnel/${id}/get-regular-type-day`,
	}))


export const setRegularType = reduxToolkit('SET_REGULAR_TYPE',
	(funnelId: ID, type: 0 | 1 | 2 | 3) => ({
		callPOST: `${URL}/v2/customer/audience-funnel/${funnelId}/regular-type`, queryData: { type }
	}))


export const setRegularTypeDay = reduxToolkit('SET_REGULAR_TYPE_DAY',
	(funnelId: ID, type: 0 | 1 | 2) => ({
		callPOST: `${URL}/v2/customer/audience-funnel/${funnelId}/regular-type-day`, queryData: { type }
	}))

export const setRegularDay = reduxToolkit('SET_REGULAR_DAY',
	(funnelId: ID, day: number) => ({
		callPOST: `${URL}/v2/customer/audience-funnel/${funnelId}/regular-day`, queryData: { day }
	}))
export const setRegularTime = reduxToolkit('SET_REGULAR_TIME',
	(funnelId: ID, time: number) => ({
		callPOST: `${URL}/v2/customer/audience-funnel/${funnelId}/regular-time`, queryData: { time }
	}))

// время когда надо отправить, тут присылаешь в секундах от 00:00:00 (тоестть если 9 утра то присылаешь 32400)