import { URL } from '../../../constants'
import { bypassReducer, reduxToolkit } from '../../../helpers/ReduxHelper'
import { Action, CR, ID } from '../../../types'
import { LOCATION_CHANGED } from '../common'


export const BLOCK_USER = 'BLOCK_USER'

export function blockUser(id: number | string): Action {
	return {
		type: BLOCK_USER,
		callPOST: URL + `/v2/customer/contact/${id}/block`,
		payload: { id },
		need_auth_token: true,
	}
}

export const BLOCK_USER_RESPONSE = 'BLOCK_USER_RESPONSE'
export const blockUserReducer = bypassReducer<CR<boolean>>(BLOCK_USER, true, {}, [LOCATION_CHANGED])


export const EDIT_CONTACT_NAME = 'EDIT_CONTACT_NAME'

export function editContactName(id: number | string, custom_name: any): Action {
	return {
		type: EDIT_CONTACT_NAME,
		callPOST: URL + `/v2/customer/contact/${id}/custom-name`,
		queryData: custom_name,
		payload: { id },
		need_auth_token: true,
	}
}

export const EDIT_CONTACT_NAME_RESPONSE = 'EDIT_CONTACT_NAME_RESPONSE'
export const editContactNameReducer = bypassReducer<CR<boolean>>(EDIT_CONTACT_NAME, true, {}, [LOCATION_CHANGED])


export const UNBLOCK_USER = 'UNBLOCK_USER'

export function unblockUser(id: number | string): Action {
	return {
		type: UNBLOCK_USER,
		callPOST: URL + `/v2/customer/contact/${id}/unblock`,
		payload: { id },
		need_auth_token: true,
	}
}

export const UNBLOCK_USER_RESPONSE = 'UNBLOCK_USER_RESPONSE'
export const unblockUserReducer = bypassReducer<CR<boolean>>(UNBLOCK_USER, true, {}, [LOCATION_CHANGED])


export const GET_CUSTOM_FIELDS = 'GET_CUSTOM_FIELDS'

export function getCustomFields(id: number | string): Action {
	return {
		type: GET_CUSTOM_FIELDS,
		call: URL + `/v2/customer/contact/${id}/custom-field`,
		payload: { id },
		need_auth_token: true,
	}
}

export const GET_CUSTOM_FIELDS_RESPONSE = 'GET_CUSTOM_FIELDS_RESPONSE'
export const getCustomFieldsReducer = bypassReducer<CR<any>>(GET_CUSTOM_FIELDS, true, {}, [LOCATION_CHANGED])


export const getContactToTest = reduxToolkit('GET_CONTACT_TO_TEST',
	(id_contact: ID) => ({
		call: `${URL}/v2/customer/audience/${id_contact}/test-contact`,
	}))

export const addContactToTest = reduxToolkit('ADD_CONTACT_TO_TEST',
	(id_contact: ID) => ({
		callPOST: `${URL}/v2/customer/contact/${id_contact}/test-contact`,
	}))
export const sendToMailingTestContact = reduxToolkit('SEND_TO_MAILING_TEST_CONTACT',
	(id_contact: ID, test_contact_id: ID) => ({
		callPOST: `${URL}/v2/customer/mailing/${id_contact}/test-send`,
		queryData: { test_contact_id },
	}))


export const sendToAudienceTestContact = reduxToolkit('SEND_TO_AUDIENCE_TEST_CONTACT',
	(funnel_id: ID, step: ID, test_contact_id: ID) => ({
		callPOST: `${URL}/v2/customer/audience-funnel-view/${funnel_id}/test-send`,
		queryData: { step, test_contact_id },
	}))

