import { URL } from '../../../constants'
import { reduxToolkit } from '../../../helpers'

export const moduleName = 'admin'
export type AnotherUserType = {
	access_token: string
	created_at: number
	expired_at: number
	is_active: number
	updated_at: number
	user_id: number
}
export const checkAnotherUser = reduxToolkit('CHECK_ANOTHER_USER',
	(id, code) => ({ callPOST: `${URL}/v2/common/auth/another-user`, queryData: { id }, payload: { id } }))
