export const LINK = window.location.host.includes('localhost') ? 'http://localhost:3000'
	: 'https://workhard.chat'
const { host } = window.location

export const URL = host.includes('localhost')
	? 'https://api.workhard.chat'
	// ? 'https://api.workhardchat.kosmoz.online'
	: host.includes('kosmoz')
		? 'https://api.workhardchat.kosmoz.online'
		: 'https://api.workhard.chat'

export const SOCKET_URL = host.includes('localhost')
	? 'https://workhard.chat'
	// ? 'https://workhardchat.kosmoz.online'
	: host.includes('kosmoz')
		? 'https://workhardchat.kosmoz.online'
		: 'https://workhard.chat'


export const GOOGLE_RECAPTCHA = window.location.origin.includes('localhost') || window.location.origin.includes('kosmoz')
	? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
	: '6Le-WdwUAAAAAPOJy9_dp8eT5Are2IzyWr77Gwz2'

export const DOMAIN = window.location.hostname === 'localhost' ? 'localhost' : '.' + window.location.hostname

export const VK_SITE_CODE = 7798896
export const VK_APP_CODE = 7795957
export const TYPE_TEXT = 0
export const TYPE_IMAGE = 1
export const TYPE_DOC = 2
export const TYPE_SOUND = 3
export const TYPE_AUDIO = 4


export const TINY_MCE_API_KEY = 'zl81a0da1poyuz6y9awiixpz2bxonsjzpqqkpghfzczmrtg3'

export const SET_THEME = 'SET_THEME'

export const START = '_START'
export const SUCCESS = '_SUCCESS'
export const FAIL = '_FAIL'

export const FILE_UPLOAD = '_FILE_UPLOAD'
export const FILE_SUCCESS = '_FILE_SUCCESS'
export const FILE_FAIL = '_FILE_FAIL'


export const MESSAGE_SUCCESS = 0
export const MESSAGE_VALIDATE_ERROR = 1
export const MESSAGE_SERVER_ERROR = 2
export const MESSAGE_LOG = 3

export const HOUR_SEC = 3600
export const DAY_SEC = HOUR_SEC * 24
export const WEEKEND_SEC = DAY_SEC * 7
export const MONTH_SEC = DAY_SEC * 30


export const CHANNEL_TYPE_TELEGRAM = 1
export const CHANNEL_TYPE_VK = 2


/** Тип файла - Аватар */
export const FILE_TYPE_AVATAR = 1
/** Тип файла - Настройки аудитории) */
export const FILE_TYPE_AUDIENCE_SETTINGS = 2
/** Тип файла - Настройки аудитории) */
export const FILE_TYPE_NEWSLETTER_FILE = 5
/** Тип файла - Настройки аудитории) */
export const FILE_TYPE_NEWSLETTER_IMAGE = 6
/** Тип файла - Лендинг ВК) */
export const FILE_TYPE_VK_LANDING = 9

export const YANDEX_METRIC_ID = 68444353

export const NEWSLETTER_STATUS_FINISH = 3
export const NEWSLETTER_STATUS_DRAFT = 1
export const NEWSLETTER_STATUS_PLANNED = 2

export const VK_AUTH_STATUS_CONNECTED = 'connected'
export const VK_AUTH_STATUS_UNKNOWN = 'unknown'
export const VK_AUTH_STATUS_NOT_AUTHORIZED = 'not_authorized'

export enum FunnelBuilderTabs {
	EditorTab = 0,
	SettingsTab = 1,
	ScheduledStartTab = 2
}

export enum ScheduledStart {
	Disabled = 0,
	Day = 1,
	Week = 2,
	Month = 3,
}
