import { useEffect, useRef, useState, useCallback } from 'react'

let msg = "Новое сообщение"
let oldTitle = 'Автоворонки и туннели продаж в мессенджерах - WorkHard.Chat'
const favicon = document.getElementById("favicon")
export default ({ chat, sound }) => {

	// @ts-ignore
	const [favHref] = useState(favicon.href)
	const toOld = useCallback(() => favicon.setAttribute("href", favHref), [favHref])
	const toNew = () => favicon.setAttribute("href", "/favicon/favicon-red-32x32.png")
	let timeoutId = useRef()

	const callbackRef = useRef(() => {
		if (document.title === msg) {
			document.title = oldTitle
			toOld()
		} else {
			toNew()
			document.title = msg
		}
	})

	useEffect(() => {
		return () => {
			// @ts-ignore
			if (timeoutId.current) {
				clearTimeout(timeoutId.current)
				timeoutId.current = null
			}
		}
	}, [])

	useEffect(() => {
		let soundTimeoutId
		if (chat.audience?.length) {
			chat.audience.forEach(({ count, is_sound, is_sound_time }) => {
				if (count && is_sound && is_sound_time) {
					if (!soundTimeoutId) {
						soundTimeoutId = setInterval(() => {
							sound.play()
						}, 30000)
					}
				}
			})
		}


		if (chat.unread) {
			if (!timeoutId.current) {
				// @ts-ignore
				timeoutId.current = setInterval(() => {
					callbackRef.current()
				}, 1000);
			}
		}
		if (!chat.unread) {
			if (timeoutId.current) {
				clearInterval(timeoutId.current)
				setTimeout(() => {
					toOld()
					document.title = oldTitle
				}, 1100)
				timeoutId.current = null
			}
			if (soundTimeoutId) {
				clearInterval(soundTimeoutId)
				soundTimeoutId = null
			}
		}
		return () => {
			if (soundTimeoutId) clearTimeout(soundTimeoutId)
		}

	}, [chat, sound, toOld])

}
