import { combineReducers } from 'redux'
import auth from '../ducks/auth/reducer'
import billing from '../ducks/billing/reducer'
import notify from '../ducks/notify/reducer'
import common from '../ducks/common/reducer'
import customer from '../ducks/customer/reducer'
import mailing from '../ducks/mailing/reducer'
import external from '../ducks/external/reducer'
import admin from '../ducks/admin/reducer'
import themeReducer from './themeReducers'

export const reducers = combineReducers({
	...auth,
	...admin,
	...billing,
	...notify,
	common,
	...customer,
	...mailing,
	...external,
	themeReducer
})
