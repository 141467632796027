export function getUrlParameters(search?: string): any {
	const query = search || window.location.search.substr(1)
	const result = {}
	const preQuery = query.replace('?', '&')
	preQuery.split('&')
		.forEach((part) => {
			const item = part.split('=')
			result[item[0]] = decodeURIComponent(item[1])
		})
	return result
}
