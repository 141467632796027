import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import MainLanding from '../components/MainLanding'
import TestRoute from '../components/TestRoute'
import { getCookie } from '../helpers/CookieHelper'

import DashboardLayout from "../layouts/DashboardLayout"
import Page404 from "../pages/auth/Page404"
import { mainRoutes, guestRoutes, authRoutes } from "./index";
import LandingLayout from "../layouts/Landing"


const renderRoutes = (routes) => {
	return (
		<Route
			render={(props) => (
				<Switch>
					{routes
						.map(({
							      // @ts-ignore
							      children, routeProps = {}, path, component: Component, exact = true,
						      }, index) => (
							<Route
								key={index}
								path={path}
								exact={exact}
								// @ts-ignore
								render={props => <Component {...routeProps} {...props} />}
							/>
						))}
				</Switch>
			)}
		/>)
}
// @ts-ignore
const renderGuestRoutes = () => guestRoutes.map(({ children, routeProps = {},layout: Layout, path, component: Component, }, index) => (
	<Route
		key={index}
		path={path}
		// @ts-ignore
		render={props => <Layout><Component {...routeProps} {...props} /></Layout>}
	/>
))

// @ts-ignore
const renderAuthRoutes = () => authRoutes.map(({ children, layout: Layout, routeProps = {}, path, component: Component, }, index) => (
	<Route
		key={index}
		path={path}
		// @ts-ignore
		render={props => <Layout><Component {...routeProps} {...props} /></Layout>}
	/>
))

const RouteSplitter = (props) => {
	return (
		<Switch>
			{renderGuestRoutes()}
			<Route
				render={(props) => (
					// @ts-ignore
					<DashboardLayout {...props}>
						{renderRoutes(mainRoutes)}
					</DashboardLayout>
				)}
			/>
		</Switch>
	)
}

const Routes = () => {
	const token = getCookie('auth_token')
	if (token) {
		return (
			<Router>
				<Switch>
					<Route component={RouteSplitter} />
					<Route render={() => <Page404 />} />
				</Switch>
			</Router>
		)
	}

	return (
		<Router>
			<Switch>
				<Route path="/" exact render={() => <LandingLayout><MainLanding /></LandingLayout>} />
				{renderAuthRoutes()}
				{renderGuestRoutes()}
				<Redirect from="*" to="/auth/sign-in" />
			</Switch>
		</Router>
	)
}
export default Routes
