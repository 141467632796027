import { Avatar, Collapse, Divider as MuiDivider, Grid } from "@material-ui/core";
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded'
import React, { useEffect, useState } from "react";
import * as Icon from 'react-feather'
import { CreditCard } from 'react-feather'
import { connect, ConnectedProps } from 'react-redux'
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import styled from 'styled-components'
import VKIcon from '../../components/common/Icons/VKIcon'


import NavLink from '../../components/common/NavLink'
import { DOMAIN } from '../../constants'
import { deleteCookie, getCookie, setCookie } from '../../helpers'
import {
	ADD_AUDIENCE_RESPONSE,
	addAudience,
	CHAT,
	getAudience,
	moduleName,
	setMenu,
	toggleMenu,
	updateAudienceOpen,
} from '../../redux/ducks/customer'
import { LEFT_MENU } from '../../redux/ducks/customer/audience'
import { CR } from '../../types'
import "../../vendor/perfect-scrollbar.css";
import { HeaderButton } from '../Header/styles'
import Create from './Create'
import HeaderLink from './HeaderLink'
import SidebarCategory from './SidebarCategory'
import SidebarLink from './SidebarLink'
import {
	Brand,
	Drawer,
	Items,
	LinkText,
	List,
	Scrollbar,
	SidebarFooter,
	SidebarFooterSubText,
	SidebarFooterText,
	SidebarSection,
	StyledBadge,
} from './styles'
import { RootState } from "../../redux/store";

const Divider = styled(MuiDivider)`
  background-color: #797b7f;
  margin-top: 10px;
`;

const LinkWithoutStyle = styled(Link)`
  text-decoration: none;
`

interface Props extends PropsFromRedux {

}

const Sidebar: React.FC<RouteComponentProps<any> & Props> = (props) => {
	const {
		staticContext,
		routes,
		location,
		userData,
		audienceList,
		addResponse,
		addAudience,
		updateAudienceOpen,
		toggleMenu,
		setMenu,
		getAudience,
		...rest
	} = props
	const { audience_count, audience } = audienceList
	const [open, setOpen] = useState(false)
	const [isMainUser, setMainUser] = useState(false)


	const handleAddAudience = () => setOpen(true)

	useEffect(() => {
		if (addResponse?.success) {
			props.getAudience()
			setOpen(false)
		}
	}, [addResponse])


	useEffect(() => {
		if (audience) {
			let list = []
			if (!audience.length) {
				list = [
					{
						header: {
							title: "Аудитории",
							button: (
								<HeaderButton
									onClick={handleAddAudience}
									color="secondary"
									aria-label="add an alarm"
								>
									<AddBoxRoundedIcon style={{ color: 'rgb(76, 175, 80)' }} />
								</HeaderButton>),
						},
						path: "/audience",
					}]
			} else {
				list = audience.map((route, index) => {
						const childList = [
							{
								path: "/audience/" + route.id + '/thread',
								name: "Сообщения",
							},
							{
								path: "/audience/" + route.id + '/funnels',
								name: "Автоворонки",
							},
							{
								path: "/audience/" + route.id + '/landings',
								name: "Лендинги",
							},
							{
								path: "/audience/" + route.id + '/landings-vk',
								name: "Лендинги ВК",
							},
							{
								path: "/audience/" + route.id + '/newsletters',
								name: "Рассылки",
							},
							{
								path: "/audience-settings/" + route.id,
								name: "Настройки",
							},
							{
								path: "/statistic/" + route.id,
								name: "Статистика",
							},
						]

						return {
							header: index
								? null
								: {
									title: "Аудитории",
									button: (
										<HeaderButton
											onClick={handleAddAudience}
											color="secondary"
											aria-label="add an alarm"
										>
											<AddBoxRoundedIcon style={{ color: 'rgb(76, 175, 80)' }} />
										</HeaderButton>),
								},
							id: route.id,
							name: route.name,
							badge: audience_count.byKey[route.id],
							path: "/audience",
							icon: <CreditCard />,
							open: route.open,
							containsHome: true,
							children: childList,
						}
					},
				)
			}
			// setRoutes((draft) => draft = list)
			props.setMenu(list)
		}
	}, [audience, audience_count])

	useEffect(() => {
		const main_auth_token = getCookie('main_auth_token')
		if (main_auth_token) {
			setMainUser(true)
		}
	}, [])
	const toggle = (index, id) => {
		props.toggleMenu(index)
		props.updateAudienceOpen(id, !routes.menu[index].open, index)
	}

	const backToMain = (ev) => {
		ev.preventDefault()
		const main_auth_token = getCookie('main_auth_token')
		const main_auth_token_expires = localStorage.getItem('auth_token')
		setCookie('auth_token', main_auth_token, { domain: DOMAIN, path: '/', expires: main_auth_token_expires })
		localStorage.removeItem('auth_token')
		deleteCookie('main_auth_token')
		window.location.href = window.location.origin + '/admin/users'
	}

	return (
		<Drawer variant="permanent"  {...rest}>
			<Create open={open} setOpen={setOpen} addAudience={props.addAudience} />
			{/*
			// @ts-ignore*/}
			<Brand>
				<Link to="/"><img
					src="https://fswho.fra1.cdn.digitaloceanspaces.com/workhard_chat/logo_head.png"
					alt="logo"
					style={{ height: '75px' }}
				/></Link>
			</Brand>
			<Scrollbar>
				<List disablePadding>
					<Items>
						{isMainUser && (
							<>
								<HeaderLink
									name="В основного"
									to="/admin/users"
									icon={<Icon.ArrowDownCircle />}
									handleClick={backToMain}
								/>
								<Divider variant="middle" light={true} />
							</>
						)}
						{routes.menu.map((category, index) => (
							<React.Fragment key={index}>
								{category?.header?.title
									? (<SidebarSection>
										<LinkText>{category.header.title}</LinkText> {category.header.button}
									</SidebarSection>)
									: null}
								{category.children ? (
									<React.Fragment key={index}>
										<SidebarCategory
											isOpen={category.open}
											isCollapsable={true}
											name={category.name}
											icon={category.icon}
											badge={category.badge}
											button={true}
											onClick={() => toggle(index, category.id)}
										/>
										<Collapse
											in={category.open}
											timeout="auto"
											unmountOnExit
										>
											{category.children.map((route, index) => (
												<SidebarLink
													key={index}
													name={route.name}
													to={route.path}
													// @ts-ignore
													icon={route.icon}
													// @ts-ignore
													badge={route.badge}
												/>
											))}
										</Collapse>
									</React.Fragment>
								) : (
									<SidebarCategory
										isCollapsable={false}
										name={category.name}
										to={category.path}
										activeClassName="active"
										component={NavLink}
										icon={category.icon}
										exact
										// @ts-ignore
										badge={category.badge}
									/>
								)}
							</React.Fragment>
						))}
						<HeaderLink icon={<Icon.HelpCircle />} to="/faq" name="Справка" />
						<HeaderLink
							icon={<Icon.Book />}
							absolute
							target
							to="https://workhard.chat/rus/blog"
							name="Блог и инструкции"
						/>
						<HeaderLink
							icon={<Icon.DollarSign />}
							to="/partner"
							name="Партнерская программа"
						/>
						<HeaderLink
							icon={<Icon.Send />}
							absolute
							target
							to="https://t.me/workhardchat"
							name="Наш Телеграм-чат"
						/>
						<HeaderLink
							icon={<Icon.Instagram />}
							absolute
							target
							to="https://www.instagram.com/workhardchat"
							name="Мы в Instagram"
						/>
						<HeaderLink
							icon={<Icon.Facebook />}
							absolute
							target
							to="https://www.facebook.com/workhardchat"
							name="Наша группа в Facebook"
						/>
						<HeaderLink
							icon={<VKIcon />}
							absolute
							target
							to="https://vk.com/workhardchat"
							name="Наша группа в VK"
						/>
						{userData?.data?.back &&
						<HeaderLink icon={<Icon.User />} to="/admin/users" name="Пользователи" />}

					</Items>
				</List>
			</Scrollbar>
			<SidebarFooter>
				<LinkWithoutStyle to="/profile">
					<Grid container spacing={2}>
						<Grid item>
							<StyledBadge
								overlap="circle"
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right',
								}}
								variant="dot"
							>
								<Avatar alt="avatar" src={userData?.data?.avatar} />
							</StyledBadge>
						</Grid>
						<Grid item>
							<SidebarFooterText variant="body2">
								{userData?.data?.username}
							</SidebarFooterText>
							<SidebarFooterSubText variant="caption">
								{userData?.data?.email}
							</SidebarFooterSubText>
						</Grid>
					</Grid>
				</LinkWithoutStyle>
			</SidebarFooter>
		</Drawer>
	)
}

interface cState {
	addResponse: CR<any>
	audienceList: any
	routes: any
	userData: CR<any>
}

interface cProps {
	setMenu: typeof setMenu
	addAudience?: typeof addAudience
	getAudience: typeof getAudience
	toggleMenu: typeof toggleMenu
	updateAudienceOpen: typeof updateAudienceOpen
}

const connector = connect((state: RootState) => ({
	routes: state[moduleName + LEFT_MENU],
	audienceList: state[moduleName + CHAT],
	addResponse: state[moduleName + ADD_AUDIENCE_RESPONSE],
	userData: state.common.userData,
}), { addAudience, getAudience, setMenu, updateAudienceOpen, toggleMenu })

type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(withRouter(Sidebar))