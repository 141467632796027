import { URL } from '../../../constants'
import { bypassReducer, reduxToolkit } from '../../../helpers/ReduxHelper'
import { ID, Action, CR } from '../../../types'
import { LandingType } from './types'


const GET_LANDINGS = 'GET_LANDINGS'
export function getLandings(id: ID): Action {
	return {
		type: GET_LANDINGS,
		call: URL + `/v2/customer/audience/${id}/landings`,
		need_auth_token: true,
	}
}
export const GET_LANDINGS_RESPONSE = 'GET_LANDINGS_RESPONSE'
export const getLandingsReducer = bypassReducer<CR<LandingType>>(GET_LANDINGS, true, {})

const CLEAR_LANDING_DATA = 'CLEAR_LANDING_DATA'
export function clearLandingData() {
	return {
		type: CLEAR_LANDING_DATA,
	}
}
const GET_LANDING = 'GET_LANDING'
export function getLanding(id: ID): Action {
	return {
		type: GET_LANDING,
		call: URL + `/v2/customer/mini-landing/${id}/view`,
		need_auth_token: true,
	}
}
export const GET_LANDING_RESPONSE = 'GET_LANDING_RESPONSE'
export const getLandingReducer = bypassReducer<CR<LandingType>>(GET_LANDING, true, {}, [CLEAR_LANDING_DATA])


const CLEAR_CHANGE_RESPONSE = 'CLEAR_CHANGE_RESPONSE'
export function clearChangeResponse() {
	return { type: CLEAR_CHANGE_RESPONSE }
}

const ADD_LANDING = 'ADD_LANDING'
export function addLanding(id: ID): Action {
	return {
		type: ADD_LANDING,
		callPOST: URL + `/v2/customer/mini-landing/${id}/create`,
		payload: { id },
		// queryData: { id },
		need_auth_token: true,
	}
}
export const ADD_LANDING_RESPONSE = 'ADD_LANDING_RESPONSE'
export const addLandingReducer = bypassReducer<CR<any>>(ADD_LANDING, true, {})


const ADD_LANDING_YANDEX_TARGET = 'ADD_LANDING_YANDEX_TARGET'
export function addLandingYMTarget(id: ID, yandex_target): Action {
	return {
		type: ADD_LANDING_YANDEX_TARGET,
		callPOST: URL + `/v2/customer/mini-landing/${id}/yandex-target`,
		payload: { id, yandex_target },
		queryData: { yandex_target },
		need_auth_token: true,
	}
}
export const ADD_LANDING_YANDEX_TARGET_RESPONSE = 'ADD_LANDING_YANDEX_TARGET_RESPONSE'
export const addLandingYMTargetReducer = bypassReducer<CR<any>>(ADD_LANDING_YANDEX_TARGET, true, {})


const CHANGE_FIELD = 'CHANGE_FIELD'
export function changeField(id: ID, name: string, param: any): Action {
	return {
		type: CHANGE_FIELD,
		callPOST: URL + `/v2/customer/mini-landing/${id}/${name}`,
		payload: { name, param },
		queryData: { [name]: param },
		need_auth_token: true,
	}
}
export const CHANGE_FIELD_RESPONSE = 'CHANGE_FIELD_RESPONSE'
export const changeFieldReducer = bypassReducer<CR<any>>(CHANGE_FIELD, true, {})


const GET_TAGS_FOR_LANDINGS = 'GET_TAGS_FOR_LANDINGS'
export function getTagsForLanding(id: ID): Action {
	return {
		type: GET_TAGS_FOR_LANDINGS,
		call: URL + `/v2/customer/mini-landing/${id}/tags`,
		payload: { id },
		need_auth_token: true,
	}
}
export const GET_TAGS_FOR_LANDINGS_RESPONSE = 'GET_TAGS_FOR_LANDINGS_RESPONSE'
export const getTagsForLandingReducer = bypassReducer<CR<any>>(GET_TAGS_FOR_LANDINGS, true, {})


const GET_CHANNELS_FOR_LANDINGS = 'GET_CHANNELS_FOR_LANDINGS'
export function getChannelsForLanding(id: ID): Action {
	return {
		type: GET_CHANNELS_FOR_LANDINGS,
		call: URL + `/v2/customer/mini-landing/${id}/view-channel`,
		payload: { id },
		need_auth_token: true,
	}
}
export const GET_CHANNELS_FOR_LANDINGS_RESPONSE = 'GET_CHANNELS_FOR_LANDINGS_RESPONSE'
export const getChannelsForLandingReducer = bypassReducer<CR<any>>(GET_CHANNELS_FOR_LANDINGS, true, {})


const ADD_TAGS_FOR_LANDINGS = 'ADD_TAGS_FOR_LANDINGS'
export function addTagsForLanding(id: ID, tags: string): Action {
	return {
		type: ADD_TAGS_FOR_LANDINGS,
		callPOST: URL + `/v2/customer/mini-landing/${id}/tags`,
		payload: { tags },
		queryData: { tags },
		need_auth_token: true,
	}
}
export const ADD_TAGS_FOR_LANDINGS_RESPONSE = 'ADD_TAGS_FOR_LANDINGS_RESPONSE'
export const addTagsForLandingReducer = bypassReducer<CR<any>>(ADD_TAGS_FOR_LANDINGS, true, {})


const CHANGE_CHANNEL_NAME = 'CHANGE_CHANNEL_NAME'
export function changeChannelName(id: ID, params: { name: string, channel_id: ID }): Action {
	return {
		type: CHANGE_CHANNEL_NAME,
		callPOST: URL + `/v2/customer/mini-landing/${id}/channel-name`,
		payload: params,
		queryData: params,
		need_auth_token: true,
	}
}
export const CHANGE_CHANNEL_NAME_RESPONSE = 'CHANGE_CHANNEL_NAME_RESPONSE'
export const changeChannelNameReducer = bypassReducer<CR<any>>(CHANGE_CHANNEL_NAME, true, {})

export type AddLandingOtherType = CR<{
	html: string
	id: number
	local: string
	url: string
}>
export const getLandingOtherPage = reduxToolkit('GET_LANDING_OTHER_PAGE',
	(url: string, local: string) => ({
		call: `${URL}/v2/common/landing-other/${url}?local=${local}`,
	}))
export const copyMiniLanding = reduxToolkit('COPY_MINILANDING',
	(id: ID) => ({
		callPOST: `${URL}/v2/customer/mini-landing/${id}/copy`,
	}))
export const copyVKMiniLanding = reduxToolkit('COPY_VK_MINILANDING',
	(id: ID) => ({
		callPOST: `${URL}/v2/customer/mini-landing-vk/${id}/copy`,
	}))

