import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { saveUtmParams } from '../../redux/ducks/customer'

export default (data?: number) => {
	const dispatch = useDispatch()
	const { search } = window.location
	useEffect(() => {
		if (data && search) dispatch(saveUtmParams.action(search.substr(1)))
	}, [data, dispatch, search])
}
