import { Grid, Hidden, Toolbar } from "@material-ui/core"

import { Menu as MenuIcon } from "@material-ui/icons";
import React from "react";
import { connect } from "react-redux";
import styled, { withTheme } from "styled-components";
import { AppBar, IconButton } from './styles'
import UserMenu from './UserMenu'
const Wrapper = styled.div`
  border-bottom: 1px solid ${props => props.theme.palette.grey[300]};
`
interface Props {
	onDrawerToggle: () => void
	theme: any
}

const Header: React.FC<Props> = ({ onDrawerToggle }) => {
	return (
		<Wrapper>
			<AppBar position="sticky" elevation={0}>
				<Toolbar>
					<Grid container alignItems="center">
						<Hidden mdUp>
							<Grid item>
								<IconButton
									color="inherit"
									aria-label="Open drawer"
									onClick={onDrawerToggle}
								>
									<MenuIcon />
								</IconButton>
							</Grid>
						</Hidden>
						<Grid item>
							{/*<Search>*/}
							{/*	<SearchIconWrapper>*/}
							{/*		<SearchIcon />*/}
							{/*	</SearchIconWrapper>*/}
							{/*	<Input placeholder="Search topics" />*/}
							{/*</Search>*/}

						</Grid>
						<Grid item xs />
						<Grid item>
							{/*<IconButton color="inherit">*/}
							{/*	<Indicator badgeContent={3}>*/}
							{/*		<MessageSquare />*/}
							{/*	</Indicator>*/}
							{/*</IconButton>*/}
							{/*<IconButton color="inherit">*/}
							{/*	<Indicator badgeContent={7}>*/}
							{/*		<Bell />*/}
							{/*	</Indicator>*/}
							{/*</IconButton>*/}
							{/*<LanguageMenu />*/}
							<UserMenu />
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
		</Wrapper>
	)
}

export default connect()(withTheme(Header))
