import { URL } from '../../../constants'
import { bypassReducer } from '../../../helpers/ReduxHelper'
import { urlBuild } from '../../../helpers/stringHelpers'
import { ID, Action, CR } from '../../../types'
import { LOCATION_CHANGED } from '../common'


const GET_AUDIENCE_STATISTIC = 'GET_AUDIENCE_STATISTIC'
export function getAudienceStatistic(id: ID, params?: any): Action {
	const query = urlBuild(params)
	return {
		type: GET_AUDIENCE_STATISTIC,
		call: URL + `/v2/customer/statistic/${id}/audience${query}`,
		need_auth_token: true,
		payload: { id, params },
	}
}
const CLEAR_AUDIENCE_STATISTIC = 'CLEAR_AUDIENCE_STATISTIC'
export function clearAudienceStatistic() {
	return { type: CLEAR_AUDIENCE_STATISTIC }
}
export const GET_AUDIENCE_STATISTIC_RESPONSE = 'GET_AUDIENCE_STATISTIC_RESPONSE'
export const getAudienceStatisticReducer = bypassReducer<CR<any>>(GET_AUDIENCE_STATISTIC, true, {}, [LOCATION_CHANGED, CLEAR_AUDIENCE_STATISTIC])



const GET_AUDIENCE_STATISTIC_FOR_MINILANDINGS = 'GET_AUDIENCE_STATISTIC_FOR_MINILANDINGS'
export function getAudienceStatisticForMiniLandings(id: ID, params?: any): Action {
	const query = urlBuild(params)
	return {
		type: GET_AUDIENCE_STATISTIC_FOR_MINILANDINGS,
		call: URL + `/v2/customer/statistic/${id}/mini-landing${query}`,
		need_auth_token: true,
		payload: { id, params },
	}
}
const CLEAR_AUDIENCE_STATISTIC_FOR_MINILANDINGS = 'CLEAR_AUDIENCE_STATISTIC_FOR_MINILANDINGS'
export function clearAudienceStatisticForMiniLandings() {
	return { type: CLEAR_AUDIENCE_STATISTIC }
}
export const GET_AUDIENCE_STATISTIC_FOR_MINILANDINGS_RESPONSE = 'GET_AUDIENCE_STATISTIC_FOR_MINILANDINGS_RESPONSE'
export const getAudienceStatisticForMiniLandingsReducer = bypassReducer<CR<any>>(GET_AUDIENCE_STATISTIC_FOR_MINILANDINGS, true, {}, [LOCATION_CHANGED, CLEAR_AUDIENCE_STATISTIC])

const GET_AUDIENCE_STATISTIC_FOR_MINILANDINGS_CHANNEL_FILTER = 'GET_AUDIENCE_STATISTIC_FOR_MINILANDINGS_CHANNEL_FILTER'
export function getAudienceStatisticForMiniLandingsChannelFilter(id: ID): Action {
	return {
		type: GET_AUDIENCE_STATISTIC_FOR_MINILANDINGS_CHANNEL_FILTER,
		call: URL + `/v2/customer/statistic/${id}/get-channels`,
		need_auth_token: true,
		payload: { id },
	}
}
export const GET_AUDIENCE_STATISTIC_FOR_MINILANDINGS_CHANNEL_FILTER_RESPONSE = 'GET_AUDIENCE_STATISTIC_FOR_MINILANDINGS_CHANNEL_FILTER_RESPONSE'
export const getAudienceStatisticForMiniLandingsChannelFilterReducer = bypassReducer<CR<any>>(GET_AUDIENCE_STATISTIC_FOR_MINILANDINGS_CHANNEL_FILTER, true, {}, [LOCATION_CHANGED, CLEAR_AUDIENCE_STATISTIC])

const GET_AUDIENCE_STATISTIC_FOR_MINILANDINGS_LANDING_FILTER = 'GET_AUDIENCE_STATISTIC_FOR_MINILANDINGS_LANDING_FILTER'
export function getAudienceStatisticForMiniLandingsLandingFilter(id: ID): Action {
	return {
		type: GET_AUDIENCE_STATISTIC_FOR_MINILANDINGS_LANDING_FILTER,
		call: URL + `/v2/customer/statistic/${id}/get-landings`,
		need_auth_token: true,
		payload: { id },
	}
}
export const GET_AUDIENCE_STATISTIC_FOR_MINILANDINGS_LANDING_FILTER_RESPONSE = 'GET_AUDIENCE_STATISTIC_FOR_MINILANDINGS_LANDING_FILTER_RESPONSE'
export const getAudienceStatisticForMiniLandingsLandingFilterReducer = bypassReducer<CR<any>>(GET_AUDIENCE_STATISTIC_FOR_MINILANDINGS_LANDING_FILTER, true, {}, [LOCATION_CHANGED, CLEAR_AUDIENCE_STATISTIC])


