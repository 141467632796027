import { CssBaseline, Dialog, Hidden, withWidth } from "@material-ui/core"
import { isWidthUp } from "@material-ui/core/withWidth"
import { Howl } from 'howler';
import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { v4 } from 'uuid'
import Loader from '../../components/Loader'
import { deleteCookie, getCookie, setCookie } from '../../helpers'
import useJivoSite from './useJivoSite'
import useMessageNotification from './useMessageNotification'
import useSocketConnect from './useSocketConnect'

import useUtm from './useUtm'
import { socket } from '../../index'
import MailPage from '../../pages/auth/MailPage'
import {
	CHECK_TOKEN_RESPONSE,
	checkToken,
	clearCheckTokenResponse,
	clearLoginUser,
	clearLogoutUser,
	LOGOUT_USER_RESPONSE,
	moduleName as authModule,
} from '../../redux/ducks/auth'
import { getUserData, sendErrorMessageToBot } from '../../redux/ducks/common'
import {
	CHAT,
	getAudience,
	getAudienceContactInfo,
	moduleName as customerModule,
	recountChat,
	setRoom,
} from '../../redux/ducks/customer'
import { getAudienceMessagesCount } from '../../redux/ducks/customer/audience'
import Header from "../Header/index"
import { matchPath } from 'react-router'

import Sidebar from "../Sidebar"
import { AppContent, Drawer, drawerWidth, GlobalStyle, MainContent, Root } from '../styles'
import { RootState } from "../../redux/store";
import { DOMAIN } from "../../constants";

const sound = new Howl({
	volume: 0.5,
	src: ['https://fswho.fra1.cdn.digitaloceanspaces.com/workhard_chat/new_message.ogg'],
})

interface Props extends PropsFromRedux {
	routes: any
	width: any
	audienceList?: any
}

const Dashboard: React.FC<RouteComponentProps<{ id: string, threadKey: string }> & Props> = (props) => {
	const {
		jivoActive,
		jivoDisable,
		children,
		routes,
		history,
		width,
		checkTokenResponse,
		user,
		chat,
		errorTelegram,
		logoutResponse,
	} = props
	const favicon = document.getElementById("favicon")
	const [mobileOpen, setMobileOpen] = useState(false)
	const [loading, setLoading] = useState(false)
	const [open, setOpen] = useState(false)
	const [isUserData, setGetUserData] = useState(false)
	const [token, setToken] = useState(getCookie('auth_token'))

	// @ts-ignore
	const match = matchPath(history.location.pathname, {
		path: "/audience/:id?/thread/:threadKey?",
		exact: true,
		strict: false,
	})

	// @ts-ignore
	const id = match?.params?.id
	// @ts-ignore
	const threadKey = match?.params?.threadKey
	const threadRef = React.useRef(threadKey)
	const chatRef = React.useRef(chat)

	useUtm(user?.data?.id)
	useJivoSite({ user, jivoActive, jivoDisable })
	useMessageNotification({ chat, sound })
	useSocketConnect({ id, threadKey })
	useEffect(() => {
		threadRef.current = threadKey
		chatRef.current = chat
	})

	useEffect(() => {
		// @ts-ignore
		window.ym(68444353, 'reachGoal', 'was_in_interface')
	}, [])


	useEffect(() => {
		if (!user?.data && token) {
			props.checkToken(token)
			setLoading(true)
		}
		socket.off('private_message:new-message')
		socket.on('private_message:new-message', ({ audience_message, id_contact, contact_id }) => {
			const audience = chatRef.current?.audience?.find((el) => el?.id == audience_message[0]?.id)
			if (audience && threadRef.current != id_contact && audience?.is_sound) sound.play()

			if (document.hidden) {
				sound.play()
			}
			if (!chatRef.current.threads.byKey[id_contact] && chatRef.current.contactsIds[id]?.includes(id_contact)) props.getAudienceContactInfo(id, id_contact)

			// props.getAudienceMessagesCount(audience_message)
		})
	}, [threadKey, id])

	useEffect(() => {
		window.addEventListener('visibilitychange', onFocus);
		// Specify how to clean up after this effect:
		return () => {
			window.removeEventListener('visibilitychange', onFocus);
		};
	}, [])
	const onFocus = () => {
		if (!document.hidden) props.recountChat()
	}

	useEffect(() => {
		if (!chat?.audience?.length && user?.data?.status_id === 1) {
			props.getAudience()
		}
		if (user?.data?.status_id === 2) {
			setOpen(true)
		}
		if (user?.data?.id) {
			localStorage.setItem('userData', JSON.stringify({ id: user.data.id, name: user.data.username }))
			const room = v4()
			props.setRoom(room)
			socket.emit('join', room)
			socket.emit('join', user.data.id)
		}
	}, [user])

	// useEffect(() => {
	// 	if (user?.data?.id) {
	// 		if (user?.data?.jivo_site) {
	// 			injectJs("//code.jivosite.com/widget/cGExPfAZJt", 'jivoSite')
	// 		}
	// 	}
	// }, [user])
	// useEffect(() => {
	//
	// 	if (jivoDisable.success) {
	// 		window.location = window.location
	// 	}
	// 	if (jivoActive.success) {
	// 		injectJs("//code.jivosite.com/widget/cGExPfAZJt", 'jivoSite')
	// 	}
	// }, [jivoActive, jivoDisable])

	useEffect(() => {
		if (checkTokenResponse?.data?.is_active === 0) {
			props.sendErrorMessageToBot({
				info: "delete cookie - checkTokenResponse?.data?.is_active === 0",
				user: localStorage.getItem('userData')
			})
			setLoading(false)
		}
		if (checkTokenResponse?.data === null) {
			props.sendErrorMessageToBot({
				info: "delete cookie - checkTokenResponse?.data === null",
				user: localStorage.getItem('userData')
			})
			setLoading(false)
		}
		if (!isUserData && !user?.data && checkTokenResponse?.data?.is_active) {
			// todo: добавить проверку если остается до 2 недель
			const expires = new Date(checkTokenResponse.data.expired_at * 1000)
			deleteCookie('auth_token')
			setCookie('auth_token', checkTokenResponse.data.access_token, {
				domain: DOMAIN,
				path: '/',
				expires,
			})
			localStorage.setItem('auth_token', String(expires))
			setGetUserData(true)
			props.getUserData()
			setLoading(false)
		}

	}, [checkTokenResponse, user, isUserData])

	useEffect(() => {
		if (logoutResponse.success) {
			props.sendErrorMessageToBot({
				info: "delete cookie - logout response",
				user: localStorage.getItem('userData')
			})
			props.clearLoginUser()
			props.clearLogoutUser()
		}
	}, [logoutResponse])
	useEffect(() => { // @ts-ignore
		if (errorTelegram.ok) {
			deleteCookie('auth_token')
			setToken(null)
		}
	}, [errorTelegram])
	const handleDrawerToggle = () => setMobileOpen(!mobileOpen)
	const handleClose = () => setOpen(false)
	useEffect(() => {
		if (!token) {
			window.location.href = window.location.origin + '/auth/sign-in'
		}
	}, [token])


	return (
		<Root>
			<CssBaseline />
			<GlobalStyle />
			<Drawer>
				<Hidden mdUp implementation="js">
					<Sidebar
						// @ts-ignore
						routes={routes}
						PaperProps={{ style: { width: drawerWidth } }}
						variant="temporary"
						open={mobileOpen}
						onClose={handleDrawerToggle}
					/>
				</Hidden>
				<Hidden smDown implementation="css">
					<Sidebar
						// @ts-ignore
						routes={routes}
						audience={props.audienceList}
						PaperProps={{ style: { width: drawerWidth } }}
					/>
				</Hidden>
			</Drawer>
			<AppContent>
				<Header onDrawerToggle={handleDrawerToggle} />
				<MainContent p={isWidthUp("lg", width) ? 10 : 5} className="MainContent">
					<Dialog
						open={open}
						onClose={handleClose}
						aria-labelledby="simple-modal-title"
						aria-describedby="simple-modal-description"
					>
						<MailPage handleClose={handleClose} getUserData={props.getUserData} />
					</Dialog>
					{loading ? <Loader /> : children}
				</MainContent>
				{/*<Footer />*/}
			</AppContent>
			{/*<Settings />*/}
		</Root>
	)
}

const connector = connect((state: RootState) => ({
	user: state.common.userData,
	jivoActive: state.common.jivoActive,
	jivoDisable: state.common.jivoDisable,
	errorTelegram: state.common.sendErrorMessageToBotResponse,
	checkTokenResponse: state[authModule + CHECK_TOKEN_RESPONSE],
	logoutResponse: state[authModule + LOGOUT_USER_RESPONSE],
	chat: state[customerModule + CHAT],
}), {
	getUserData: getUserData.action,
	getAudience,
	clearLoginUser,
	checkToken,
	setRoom,
	getAudienceContactInfo,
	clearLogoutUser,
	recountChat,
	clearCheckTokenResponse,
	getAudienceMessagesCount,
	sendErrorMessageToBot: sendErrorMessageToBot.action,

})

type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(withWidth()((Dashboard)))

