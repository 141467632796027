export function clearEmpty<T>(object: T, required?: string[]): T {
	const htRequired = {}
	if (required?.length) {
		required.forEach((el) => {
			htRequired[el] = el
		})
	}
	return Object.entries(object).reduce((a, [k, v]) => (v == null || (required && !htRequired[k]) ? a : {
		...a,
		[k]: v,
	}), {}) as T
}

export const removeEmpty = (obj) => {
	const notEmptyProperties = Object.keys(obj).filter((key) => obj[key])
	return notEmptyProperties.reduce((acc, curr) => {
		acc[curr] = obj[curr]
		return acc
	}, {})
}

export function setInteger(params) {
	return Object.keys(params).filter((key) => parseInt(params[key], 10))
		.reduce((res, key) => ({ ...res, [key]: parseInt(params[key], 10) }), {})
}

export const objFromArray = (arr, key = 'id') => arr.reduce((accumulator, current) => {
	accumulator[current[key]] = current;
	return accumulator;
}, {})


export const distinctArrayOfObject = (array: [], key = 'id') => {
	const result = []
	const map = new Map()
	for (const item of array) {
		if (!map.has(item[key])) {
			map.set(item[key], true);    // set any value to Map
			result.push(item)
		}
	}
	return result
}
