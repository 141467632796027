import { GET_ARCHIVE_CHANNELS_RESPONSE } from './audience'
import { IMPORT_CONTACTS_RESPONSE, addCallbackUrl } from './channel'
import { GET_AUDIENCE_FUNNELS_TAGS_RESPONSE, START_FUNNEL_RESPONSE, CHECK_IS_REPEAT_RESPONSE } from './funnel'
import { GET_AUDIENCE_STATISTIC_FOR_MINILANDINGS_RESPONSE } from './statistic'

export const moduleName = 'customer'

export {
	getLanding, GET_LANDING_RESPONSE,
	changeField, CHANGE_FIELD_RESPONSE,
	getLandings, GET_LANDINGS_RESPONSE,
	addLanding, ADD_LANDING_RESPONSE,
	clearChangeResponse, clearLandingData,
	addTagsForLanding, ADD_TAGS_FOR_LANDINGS_RESPONSE,
	getTagsForLanding, GET_TAGS_FOR_LANDINGS_RESPONSE,
	changeChannelName, CHANGE_CHANNEL_NAME_RESPONSE,
	getChannelsForLanding, GET_CHANNELS_FOR_LANDINGS_RESPONSE,
	getLandingOtherPage,
	copyMiniLanding,
	copyVKMiniLanding,
} from './landing'
export {
	addLandingVK,
	addLandingVKYandexTarget,
	addTagsForVKLandings,
	changeChannelNameForVKLanding,
	changeVKLandingField,
	getChannelsForVKLandings,
	getLandingsVK,
	getLandingVK,
	getTagsForVKLandings,
	getVKLandingOtherPage,
	saveVKAuth,
	setDefaultVKLanding,
} from './landing-vk'

export {
	clearFunnelResponse,
	clearSaveFunnelResponse,
	getAudienceFunnel, GET_AUDIENCE_FUNNEL_RESPONSE,
	getAudienceFunnels, GET_AUDIENCE_FUNNELS_RESPONSE,
	createAudienceFunnel, CREATE_AUDIENCE_FUNNEL_RESPONSE,
	updateAudienceFunnel, UPDATE_AUDIENCE_FUNNEL_RESPONSE,
	getFunnelActions, GET_FUNNEL_ACTIONS_RESPONSE,
	saveAudienceFunnel, SAVE_AUDIENCE_FUNNEL_RESPONSE,
	performAction, PERFORM_ACTION_RESPONSE,
	getAudienceFunnelsTags, GET_AUDIENCE_FUNNELS_TAGS_RESPONSE,
	addFunnelTag, ADD_FUNNEL_TAG_RESPONSE,
	addFunnelStopTag, ADD_FUNNEL_STOP_TAG_RESPONSE,
	checkIsNew, CHECK_IS_NEW_RESPONSE,
	changeFunnelName, CHANGE_FUNNEL_NAME_RESPONSE,
	startFunnel, START_FUNNEL_RESPONSE,
	getAudienceFunnelAdditional, GET_AUDIENCE_FUNNEL_ADDITIONAL_RESPONSE,
	checkIsRepeat, CHECK_IS_REPEAT_RESPONSE,
	copyFunnel,
	getRegularType,
	getRegularTypeDay,
	setRegularType,
	setRegularTypeDay,
	setRegularDay,
	setRegularTime,
} from './funnel'


export {
	getAudience, CHAT,
	getAudienceInitialContacts,
	updateAudienceOpen,
	setMenu,
	toggleMenu,
	setRoom,
	toggleSideBar,
	scrollToBottomOfChat,
	toggleTagSideBar,
	audienceSet,
	clearAudienceContactsResponse,
	getAudienceContactInfo,
	initializeChat,
	setCurrentThread,
	unmountChat,
	recountChat,
	getAudienceFilteredContacts,
	getAudienceAllContactsId,
	updateAudienceName, UPDATE_AUDIENCE_RESPONSE,
	addAudience, ADD_AUDIENCE_RESPONSE,
	deleteAudience, DELETE_AUDIENCE_RESPONSE,
	getAudienceChannels, GET_AUDIENCE_CHANNELS_RESPONSE,
	createChannelForAudience, CREATE_CHANNEL_FOR_AUDIENCE_RESPONSE,
	sendMessageToContactFromBot,
	sendMessageToContact,
	getArchiveChannels, GET_ARCHIVE_CHANNELS_RESPONSE,
	searchAudienceContacts, GET_AUDIENCE_CONTACTS_SEARCH_RESPONSE,
	changeAudienceSoundNotification,
	changeAudienceSoundTimeNotification,
	changeAudienceFaviconNotification,
	setAudienceViewAllMessages,
	setMessageAsRead,
	getAudienceTags,
	getAudienceStartWord,
	rebuildStartWords,
} from './audience'

export {
	deleteChannel, DELETE_CHANNEL_RESPONSE,
	updateChannelName, UPDATE_CHANNEL_RESPONSE,
	addChannelKey, ADD_CHANNEL_KEY_RESPONSE,
	importContacts, IMPORT_CONTACTS_RESPONSE,
	clearImportContacts,
	UPLOAD_OLD_VK_CONTACTS_RESPONSE, uploadOldVKContacts,
	addCallbackUrl,
} from './channel'

export {
	getTagList, GET_TAG_LIST_RESPONSE,
	createTagForAudience, CREATE_TAG_FOR_AUDIENCE_RESPONSE,
	updateTag, UPDATE_TAG_RESPONSE,
	deleteTag, DELETE_TAG_RESPONSE,
	getContactTagsList, GET_CONTACT_TAGS_LIST_RESPONSE,
	getPossibleContactTags, GET_POSSIBLE_CONTACT_TAGS_LIST_RESPONSE,
	addTagForContact, ADD_TAG_FOR_CONTACT_RESPONSE,
	deleteTagForContact, DELETE_TAG_FOR_CONTACT_RESPONSE,
} from './tag'

export {
	getAudienceKeywords, GET_AUDIENCE_KEYWORDS_RESPONSE,
	addAudienceKeywords, ADD_AUDIENCE_KEYWORDS_RESPONSE,
	updateAudienceKeywords, UPDATE_AUDIENCE_KEYWORDS_RESPONSE,
	deleteAudienceKeywords, DELETE_AUDIENCE_KEYWORDS_RESPONSE,
} from './keywords'

export {
	blockUser, BLOCK_USER_RESPONSE,
	unblockUser, UNBLOCK_USER_RESPONSE,
	editContactName, EDIT_CONTACT_NAME_RESPONSE,
	addContactToTest,
	getContactToTest,
	sendToMailingTestContact,
	sendToAudienceTestContact,
} from './contacts'


export {
	getMainStat, GET_MAIN_STAT_RESPONSE,
} from './dashboard'
export {
	getAudienceStatistic,
	clearAudienceStatistic,
	GET_AUDIENCE_STATISTIC_RESPONSE,
	getAudienceStatisticForMiniLandings,
	clearAudienceStatisticForMiniLandings,
	GET_AUDIENCE_STATISTIC_FOR_MINILANDINGS_RESPONSE,
	getAudienceStatisticForMiniLandingsChannelFilter,
	GET_AUDIENCE_STATISTIC_FOR_MINILANDINGS_CHANNEL_FILTER_RESPONSE,
	getAudienceStatisticForMiniLandingsLandingFilter,
	GET_AUDIENCE_STATISTIC_FOR_MINILANDINGS_LANDING_FILTER_RESPONSE,
} from './statistic'

export {
	getContactNotes, GET_CONTACT_NOTES_RESPONSE,
	addContactNotes, ADD_CONTACT_NOTES_RESPONSE,
	deleteContactNotes, DELETE_CONTACT_NOTES_RESPONSE,
	updateContactNotes, UPDATE_CONTACT_NOTES_RESPONSE,
} from './note'

export {
	getAudienceColor,
	changeAudienceParam,
	getActiveAudienceColor,
	changeAudienceActiveColor,
} from './colors'

export {
	saveUtmParams,
} from './utm'
