import React from 'react'
import { Link, LinkBadge, LinkText } from './styles'
import NavLink from '../../components/common/NavLink'

interface Props {
	name: string
	to: string
	icon?: any
	badge?: any
}

const SidebarLink: React.FC<Props> = (props) => {
	const { name, to, badge } = props

	return (
		<Link
			button
			dense
			component={NavLink}
			exact
			to={to}
			activeClassName="active"
		>
			<LinkText>{name}</LinkText>
			{badge ? <LinkBadge label={badge} /> : ""}
		</Link>
	)
}

export default SidebarLink
