/* eslint-disable */

export function setCookie(name: string, value: string | number, options?: any) {
	options = options || {};

	let { expires = '' } = options

	if (typeof expires == 'number' && expires) {
		const d = new Date();
		d.setTime(d.getTime() + expires * 1000);
		expires = d;
	}
	if (expires && expires.toUTCString) {
		options.expires = expires.toUTCString();
	}

	let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

	for (const propName in options) {
		updatedCookie += '; ' + propName;
		const propValue = options[propName];
		if (propValue !== true) {
			updatedCookie += '=' + propValue;
		}
	}

	document.cookie = updatedCookie;
}

export function getCookie(name: string) {
	const matches = document.cookie.match(new RegExp(
		// eslint-disable-next-line no-useless-escape
		'(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
	))

	return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function deleteCookie(name) {
	const domain = location.hostname === 'localhost' ? 'localhost' : '.' + location.hostname;
	// eslint-disable-next-line no-multi-assign
	document.cookie = document.cookie = `${name}=; Max-Age=0; path=/; domain=${domain}`;
}

