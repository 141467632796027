import {
	moduleName,
	GET_ALL_MAILING_RESPONSE, getAllMailingReducer,
	GET_MAILING_RESPONSE, getMailingReducer,
	GET_MAILING_ACTIONS_RESPONSE, getMailingActionsReducer,
	GET_MAILING_LOGS_RESPONSE, getMailingLogsReducer,
	GET_MAILING_AUDIENCE_RESPONSE, getMailingAudienceReducer,
	UPDATE_MAILING_RESPONSE, updateMailingReducer,
	DELETE_MAILING_RESPONSE, deleteMailingReducer,
	FINISH_MAILING_RESPONSE, finishMailingReducer,
	SENDS_MAILING_RESPONSE, sendsMailingReducer,
	GET_AUDIENCE_TAGS_RESPONSE, audienceTagsReducer,
	GET_MAILING_COUNT_RESPONSE, getMailingCountReducer,
	createMailing,
} from './index'


export default {
	[moduleName + GET_ALL_MAILING_RESPONSE]: getAllMailingReducer,
	[moduleName + GET_MAILING_RESPONSE]: getMailingReducer,
	[moduleName + GET_MAILING_ACTIONS_RESPONSE]: getMailingActionsReducer,
	[moduleName + GET_MAILING_LOGS_RESPONSE]: getMailingLogsReducer,
	[moduleName + createMailing.response_type]: createMailing.reducer,
	[moduleName + GET_MAILING_AUDIENCE_RESPONSE]: getMailingAudienceReducer,
	[moduleName + UPDATE_MAILING_RESPONSE]: updateMailingReducer,
	[moduleName + DELETE_MAILING_RESPONSE]: deleteMailingReducer,
	[moduleName + FINISH_MAILING_RESPONSE]: finishMailingReducer,
	[moduleName + SENDS_MAILING_RESPONSE]: sendsMailingReducer,
	[moduleName + GET_AUDIENCE_TAGS_RESPONSE]: audienceTagsReducer,
	[moduleName + GET_MAILING_COUNT_RESPONSE]: getMailingCountReducer,
}
