import React from 'react'
import { FormControl, FormHelperText, Input, InputLabel } from '@material-ui/core'


interface Props {
	handleBlur: (event: React.FocusEvent) => void
	error: {
		email: boolean
		code: boolean
	}
}

const EnterMail: React.FC<Props> = ({ error, handleBlur }) => (
	<form>
		<FormControl error={error.code} margin="normal" required fullWidth>
			<InputLabel htmlFor="code">Код</InputLabel>
			<Input onBlur={handleBlur} id="code" name="code" autoComplete="code" autoFocus />
			{error.code ?
				<FormHelperText id="my-helper-text">Вы ввели неверный код</FormHelperText>
				: null}
		</FormControl>
	</form>
)

export default EnterMail
