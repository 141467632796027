import { URL } from '../../../constants'
import { bypassReducer, reduxToolkit } from '../../../helpers/ReduxHelper'
import { urlBuild } from '../../../helpers/stringHelpers'
import { Action, CR, ID } from '../../../types'
import { LOCATION_CHANGED } from '../common'

export const getOperationsFilter = reduxToolkit('GET_OPERATION_FILTER',
	() => ({ call: `${URL}/v2/common/billing/operations/filter` }))

export const getBonusOperationsFilter = reduxToolkit('GET_BONUS_OPERATION_FILTER',
	() => ({ call: `${URL}/v2/common/billing/operations/filter-bonus` }))
export const addBillingAffiliateOperations = reduxToolkit('ADD_BILLING_AFFILIATE_OPERATIONS',
	(sum) => ({ callPOST: `${URL}/v2/common/billing/affiliate/transfer`, queryData: {sum} }))

const GET_PAY_IN_LIST = 'GET_PAY_IN_LIST'
export function getPayInList(params): Action {
	const query = urlBuild(params)
	return {
		type: GET_PAY_IN_LIST,
		call: URL + `/v2/admin/billing/pay-in${query}`,
		need_auth_token: true,
	}
}
export const GET_PAY_IN_LIST_RESPONSE = 'GET_PAY_IN_LIST_RESPONSE'
export const getPayInListReducer = bypassReducer<CR<any>>(GET_PAY_IN_LIST, true, {})


const DELETE_BILLING_OPERATION = 'DELETE_BILLING_OPERATION'
export function deleteBillingOperation(id: ID): Action {
	return {
		type: DELETE_BILLING_OPERATION,
		callPOST: URL + `/v2/admin/billing/${id}/delete`,
		payload: { id },
		queryData: { id },
		need_auth_token: true,
	}
}
export const DELETE_BILLING_OPERATION_RESPONSE = 'DELETE_BILLING_OPERATION_RESPONSE'
export const deleteBillingOperationReducer = bypassReducer<CR<any>>(DELETE_BILLING_OPERATION, true, {}, [LOCATION_CHANGED])


const GET_ADMIN_PRICE = 'GET_ADMIN_PRICE'
// GET /v2/admin/price - список внутрь приходит id, min_subscriber, max_subscriber, sum
export function getAdminPrice(params): Action {
	const query = urlBuild(params)
	return {
		type: GET_ADMIN_PRICE,
		call: URL + `/v2/admin/price${query}`,
		need_auth_token: true,
	}
}
export const GET_ADMIN_PRICE_RESPONSE = 'GET_ADMIN_PRICE_RESPONSE'
export const getAdminPriceReducer = bypassReducer<CR<any>>(GET_ADMIN_PRICE, true, {})


const ADD_ADMIN_PRICE = 'ADD_ADMIN_PRICE'
// POST /v2/admin/price -  добавить, отправлять надо формой PriceForm туда min_subscriber, max_subscriber, sum (должен быть флоат)
export function addAdminPrice(params): Action {
	return {
		type: ADD_ADMIN_PRICE,
		callPOST: URL + `/v2/admin/price`,
		queryData: {
			PriceForm: params,
		},
		payload: {},
		need_auth_token: true,
	}
}
export const ADD_ADMIN_PRICE_RESPONSE = 'ADD_ADMIN_PRICE_RESPONSE'
export const addAdminPriceReducer = bypassReducer<CR<any>>(ADD_ADMIN_PRICE, true, {})


const UPDATE_ADMIN_PRICE = 'UPDATE_ADMIN_PRICE'
// POST /v2/admin/price -  добавить, отправлять надо формой PriceForm туда min_subscriber, max_subscriber, sum (должен быть флоат)
export function updateAdminPrice(id: ID, params): Action {

	return {
		type: UPDATE_ADMIN_PRICE,
		callPOST: URL + `/v2/admin/price/${id}/update`,
		queryData: {
			PriceForm: params,
		},
		payload: {
			id, params,
		},
		need_auth_token: true,
	}
}
export const UPDATE_ADMIN_PRICE_RESPONSE = 'UPDATE_ADMIN_PRICE_RESPONSE'
export const updateAdminPriceReducer = bypassReducer<CR<any>>(UPDATE_ADMIN_PRICE, true, {})


const DELETE_ADMIN_PRICE = 'DELETE_ADMIN_PRICE'
export function deleteAdminPrice(id: ID, params): Action {
	return {
		type: DELETE_ADMIN_PRICE,
		callPOST: URL + `/v2/admin/price/${id}/delete`,
		payload: { id },
		need_auth_token: true,
	}
}
export const DELETE_ADMIN_PRICE_RESPONSE = 'DELETE_ADMIN_PRICE_RESPONSE'
export const deleteAdminPriceReducer = bypassReducer<CR<any>>(DELETE_ADMIN_PRICE, true, {})
