import { nanoid } from 'nanoid'

export const moduleName = 'notify'

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const enqueueSnackbar = (notification) => {

	return {
		type: ENQUEUE_SNACKBAR,
		payload: { ...notification, key: nanoid() }
	};
};

export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const closeSnackbar = key => ({
	type: CLOSE_SNACKBAR,
	dismissAll: !key, // dismiss all if no key has been defined
	key,
});

export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
export const removeSnackbar = (key) => ({
	type: REMOVE_SNACKBAR,
	key,
});

type Notification = {
	options: any
	key: string

}
const notifications: Array<Notification> = []
export const NOTIFY = 'NOTIFY'
export const notifyReducer = (state = { notifications }, action) => {
	switch (action.type) {
		case ENQUEUE_SNACKBAR:
			return {
				...state,
				notifications: [
					...state.notifications,
					action.payload
				]
			};

		// case CLOSE_SNACKBAR:
		// 	return {
		// 		...state,
		// 		notifications: state.notifications.map(notification => (
		// 			(action.dismissAll || notification.key === action.key)
		// 				? { ...notification, dismissed: true }
		// 				: { ...notification }
		// 		)),
		// 	};

		case REMOVE_SNACKBAR:
			return {
				...state,
				notifications: state.notifications.filter(notification => notification.key !== action.key)
			};

		default:
			return state;
	}
};

