import React from 'react'
import { NavLink as RouterNavLink } from "react-router-dom";
import { LinkProps } from '@material-ui/core'


// @ts-ignore
const NavLink = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => <RouterNavLink innerRef={ref as any} {...props} />)


export default NavLink
