import { getUrlParameters } from "helpers/UrlParametersParser";
import { useEffect } from 'react'
import { MONTH_SEC, DOMAIN } from '../constants'
import { setCookie } from '../helpers'

export default () => {
	useEffect(() => {
		const { p } = getUrlParameters()
		if (p) {
			setCookie('affiliate_id', p, { domain: DOMAIN, path: '/', 'max-age': MONTH_SEC })
		}
	}, [])
}
