import { Dialog, FormControl, Input, InputLabel, Paper } from '@material-ui/core'
import React, { useState, memo } from 'react'
import styled from 'styled-components'
import { Button } from 'styles'

interface Props {
	open: boolean
	setOpen: (isOpen: boolean) => void
	addAudience?: (value: string) => void
}

const Wrapper = styled(Paper)`
	padding: ${props => props.theme.spacing(6)}px;
	${props => props.theme.breakpoints.up("md")} {
	  padding: ${props => props.theme.spacing(10)}px;
	}
	form {
		text-align: right;
	}
`


const Create: React.FC<Props> = (props) => {
	const { open, setOpen } = props
	const [value, setValue] = useState('')

	const handleClose = () => setOpen(false)
	const handleChange = (ev) => setValue(ev.target.value)
	const handleAddAudience = (ev) => {
		ev.preventDefault()
		if (value) props.addAudience(value)
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
		>
			<Wrapper>
				<form onClick={handleAddAudience}>
					<FormControl margin="normal" required fullWidth>
						<InputLabel htmlFor="email">Введите название аудитории</InputLabel>
						<Input onChange={handleChange} name="audience" autoFocus />
					</FormControl>
					<Button type="submit" >
						Добавить
					</Button>
				</form>
			</Wrapper>

		</Dialog>
	)
}


export default memo(Create)
