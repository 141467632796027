import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { moduleName, NOTIFY, removeSnackbar } from '../redux/ducks/notify';


interface Props {
}

let displayed: any = [];

const Notify: React.FC<Props> = (props) => {
	const dispatch = useDispatch();
	const { notifications = [] } = useSelector(state => state[moduleName + NOTIFY]);
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const storeDisplayed = (id) => {
		displayed = [...displayed, id];
	};
	const removeDisplayed = (id) => {
		displayed = [...displayed.filter(key => id !== key)];
	};

	React.useEffect(() => {
		notifications.forEach(({ key, message, options = {}, dismissed = false }) => {

			if (displayed.includes(key)) return;
			enqueueSnackbar(message, {
				key,
				...options,
				// onClose: (event, reason, myKey) => {
				// 	if (options.onClose) {
				// 		options.onClose(event, reason, myKey);
				// 	}
				// },
				onExited: (event, myKey) => {

					// remove this snackbar from redux store
					dispatch(removeSnackbar(myKey));
					removeDisplayed(myKey);
				},
			});
		storeDisplayed(key);
		})
	}, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

	return null;
}

export default Notify
