export { injectCss, injectJs, mobileCheck } from './dom'
export { deleteCookie, getCookie, setCookie } from './CookieHelper'
export { log } from './log'
export { clearEmpty, distinctArrayOfObject, objFromArray, removeEmpty, setInteger } from './objectHelper'
export { priceSet, createTextLinks, declOfNum, urlBuild } from './stringHelpers'
export { bypassReducer, reduxToolkit } from './ReduxHelper'
export { getUrlParameters } from './UrlParametersParser'
export { renderTest } from './testing'

export function debounce(cb, delay) {
	let timeout
	return (...args) => {
		if (timeout) clearTimeout(timeout)
		timeout = setTimeout(() => {
			cb(...args)
		}, delay)
	}
}

export const convertSecondsToTimeFormat = (sec) => {
	const date = new Date(0);
	date.setSeconds(sec); // specify value for SECONDS here
	const time = date.toISOString().substring(11, 16);
	console.log(time)
	return time
}