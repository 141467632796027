import { bypassReducer, reduxToolkit } from "helpers/ReduxHelper"
import { current, original, produce } from 'immer'
import { Action, CR, ID } from "types"
import { START, SUCCESS, URL } from '../../../constants'
import { urlBuild } from '../../../helpers/stringHelpers'
import { LOCATION_CHANGED } from '../common'
import { BLOCK_USER, UNBLOCK_USER } from './contacts'
import { AudienceChannel } from './types'


export const setMessageAsRead = reduxToolkit('SET_MESSAGE_AS_READ',
	(id_contact: ID, message_id: ID) => ({
		callPOST: `${URL}/v2/customer/contact/${id_contact}/message-view`,
		queryData: { message_id },
	}))

export const changeAudienceSoundNotification = reduxToolkit('CHANGE_AUDIENCE_SOUND_NOTIFICATION',
	(audience_id: ID, is_sound: boolean) => ({
		callPOST: `${URL}/v2/customer/audience/${audience_id}/sound`,
		queryData: { is_sound },
	}))
export const changeAudienceFaviconNotification = reduxToolkit('CHANGE_AUDIENCE_FAVICON_NOTIFICATION',
	(audience_id: ID, favicon: boolean) => ({
		callPOST: `${URL}/v2/customer/audience/${audience_id}/favicon`,
		queryData: { favicon },
	}))

export const changeAudienceSoundTimeNotification = reduxToolkit('CHANGE_AUDIENCE_SOUND_TIME_NOTIFICATION',
	(audience_id: ID, is_sound_time: boolean) => ({
		callPOST: `${URL}/v2/customer/audience/${audience_id}/sound-time`,
		queryData: { is_sound_time },
	}))
export const setAudienceViewAllMessages = reduxToolkit('SET_AUDIENCE_VIEW_ALL_MESSAGES',
	(audience_id: ID) => ({
		callPOST: `${URL}/v2/customer/audience/${audience_id}/view-message`,
	}))
export const setUpdateContacts = reduxToolkit('SET_UPDATE_CONTACTS',
	(audience_id: ID) => ({
		callPOST: `${URL}/v2/customer/audience/${audience_id}/update-contacts`,
	}))

const GET_AUDIENCE = 'GET_AUDIENCE'

export function getAudience(): Action {
	return {
		type: GET_AUDIENCE,
		call: URL + `/v2/customer/audience`,
		need_auth_token: true,
	}
}

const UPDATE_AUDIENCE_OPEN = 'UPDATE_AUDIENCE_OPEN'

export function updateAudienceOpen(id, open, index): Action {
	return {
		type: UPDATE_AUDIENCE_OPEN,
		callPOST: URL + `/v2/customer/audience/${id}/open`,
		need_auth_token: true,
		queryData: { open: Number(open) },
		payload: { id, open, index },
	}
}

const GET_AUDIENCE_MESSAGES_COUNT = 'GET_AUDIENCE_MESSAGES_COUNT'

export function getAudienceMessagesCount(data) {
	return {
		type: GET_AUDIENCE_MESSAGES_COUNT,
		payload: { data },
	}
}

const SET_COUNTS = 'SET_COUNTS'

export function setCounts(data) {
	return {
		type: SET_COUNTS,
		payload: { ...data },
	}
}


const ADD_AUDIENCE = 'ADD_AUDIENCE'

export function addAudience(name: string): Action {
	return {
		type: ADD_AUDIENCE,
		callPOST: URL + `/v2/customer/audience`,
		payload: { name },
		queryData: { name },
		need_auth_token: true,
	}
}

export const ADD_AUDIENCE_RESPONSE = 'ADD_AUDIENCE_RESPONSE'
export const addAudienceReducer = bypassReducer<CR<boolean>>(ADD_AUDIENCE, true, {}, [LOCATION_CHANGED])


const DELETE_AUDIENCE = 'DELETE_AUDIENCE'

/**
 * Delete audience
 *
 * @param id - audience id.
 */
export function deleteAudience(id: number | string): Action {
	return {
		type: DELETE_AUDIENCE,
		callDELETE: URL + `/v2/customer/audience/${id}`,
		need_auth_token: true,
	}
}

export const DELETE_AUDIENCE_RESPONSE = 'DELETE_AUDIENCE_RESPONSE'
export const deleteAudienceReducer = bypassReducer<CR<boolean>>(DELETE_AUDIENCE, true, {}, [LOCATION_CHANGED])


const UPDATE_AUDIENCE = 'UPDATE_AUDIENCE'

/**
 * Update audience name
 *
 * @param id - audience id.
 * @param name - audience name.
 */
export function updateAudienceName(id: number | string, name: string): Action {
	return {
		type: UPDATE_AUDIENCE,
		callPOST: URL + `/v2/customer/audience/${id}/name`,
		payload: { name },
		queryData: { name },
		need_auth_token: true,
	}
}

export const UPDATE_AUDIENCE_RESPONSE = 'UPDATE_AUDIENCE_RESPONSE'
export const updateAudienceReducer = bypassReducer<CR<boolean>>(UPDATE_AUDIENCE, true, {}, [LOCATION_CHANGED])


const GET_AUDIENCE_CHANNELS = 'GET_AUDIENCE_CHANNELS'

/**
 * Get audience channels
 */
export function getAudienceChannels(id: ID): Action {
	return {
		type: GET_AUDIENCE_CHANNELS,
		call: URL + `/v2/customer/audience/${id}/channels`,
		need_auth_token: true,
	}
}

export const GET_AUDIENCE_CHANNELS_RESPONSE = 'GET_AUDIENCE_CHANNELS_RESPONSE'
export const getAudienceChannelsReducer = bypassReducer<CR<AudienceChannel[]>>(GET_AUDIENCE_CHANNELS, true, {}, [
	ADD_AUDIENCE + SUCCESS,
	DELETE_AUDIENCE + SUCCESS,
])


const GET_ARCHIVE_CHANNELS = 'GET_ARCHIVE_CHANNELS'

export function getArchiveChannels(id: ID): Action {
	return {
		type: GET_ARCHIVE_CHANNELS,
		call: URL + `/v2/customer/audience/${id}/channels-archive`,
		payload: { id },
		need_auth_token: true,
	}
}

export const GET_ARCHIVE_CHANNELS_RESPONSE = 'GET_ARCHIVE_CHANNELS_RESPONSE'
export const getArchiveChannelsReducer = bypassReducer<CR<boolean>>(GET_ARCHIVE_CHANNELS, true, {}, [LOCATION_CHANGED])


const GET_AUDIENCE_CONTACTS_THREADS = 'GET_AUDIENCE_CONTACTS_THREADS'

export function getAudienceContacts(id: ID, params?: { channel_id?: ID, limit?: number, page?: number }, thread_id?: ID): Action {
	const query = urlBuild(params)
	return {
		type: GET_AUDIENCE_CONTACTS_THREADS,
		call: URL + `/v2/customer/audience/${id}/contacts${query}`,
		need_auth_token: true,
		payload: { thread_id },
	}
}

export const getAudienceTags = reduxToolkit('GET_AUDIENCE_TAGS',
	(id) => ({ call: `${URL}/v2/customer/audience/${id}/tags` }));

export const getAudienceStartWord = reduxToolkit('GET_AUDIENCE_START_WORD',
	(id) => ({ call: `${URL}/v2/customer/audience/${id}/start-word` }));

export const rebuildStartWords = reduxToolkit('START_WORD_REBUILD',
	(id) => ({ callPOST: `${URL}/v2/customer/audience/${id}/rebuild-word` }));


const GET_AUDIENCE_INITIAL_CONTACTS = 'GET_AUDIENCE_INITIAL_CONTACTS'

export function getAudienceInitialContacts(id: ID, params?: { is_view?: number, offset?: number, channel_id?: ID, limit?: number, page?: number, color_id?: number }, thread_id?: ID): Action {
	const query = urlBuild(params)
	return {
		type: GET_AUDIENCE_INITIAL_CONTACTS,
		call: URL + `/v2/customer/audience/${id}/contacts-new${query}`,
		need_auth_token: true,
		payload: { id, thread_id, params },
	}
}

export const AUDIENCE_INITIAL_CONTACTS_RESPONSE = 'AUDIENCE_INITIAL_CONTACTS_RESPONSE'
export const audienceContactsReducer = bypassReducer<CR<any>>(GET_AUDIENCE_INITIAL_CONTACTS, true, {}, [LOCATION_CHANGED])


const GET_AUDIENCE_FILTERED_CONTACTS = 'GET_AUDIENCE_FILTERED_CONTACTS'

export function getAudienceFilteredContacts(id: ID, params?: { offset?: number, is_view?: number, channel_id?: ID, limit?: number, page?: number, color_id?: number }, thread_id?: ID): Action {
	const query = urlBuild(params)
	return {
		type: GET_AUDIENCE_FILTERED_CONTACTS,
		call: URL + `/v2/customer/audience/${id}/contacts-new${query}`,
		need_auth_token: true,
		payload: { id, thread_id, params },
	}
}

const GET_AUDIENCE_COLOR_CONTACTS = 'GET_AUDIENCE_COLOR_CONTACTS'

export function getAudienceColoredContacts(id: ID, params?: { offset?: number, channel_id?: ID, limit?: number, page?: number, color_id?: number }, thread_id?: ID): Action {
	const query = urlBuild(params)
	return {
		type: GET_AUDIENCE_COLOR_CONTACTS,
		call: URL + `/v2/customer/audience/${id}/contacts-new${query}`,
		need_auth_token: true,
		payload: { id, thread_id, params },
	}
}

// const SET_GET_CONTACTS_PARAMS = 'SET_GET_CONTACTS_PARAMS'
// export function setGetContactsParams(params) {
// 	return {
// 		type: SET_GET_CONTACTS_PARAMS,
// 		payload: { params },
// 	}
// }

const GET_AUDIENCE_ALL_CONTACTS_ID = 'GET_AUDIENCE_ALL_CONTACTS_ID'

export function getAudienceAllContactsId(id: ID): Action {
	return {
		type: GET_AUDIENCE_ALL_CONTACTS_ID,
		call: URL + `/v2/customer/audience/${id}/contact-ids`,
		need_auth_token: true,
		payload: { id },
	}
}

const GET_AUDIENCE_CONTACT_INFO = 'GET_AUDIENCE_CONTACT_INFO'

export function getAudienceContactInfo(id: ID, id_contact): Action {
	return {
		type: GET_AUDIENCE_CONTACT_INFO,
		call: URL + `/v2/customer/audience/${id}/contact-info?id_contact=${id_contact}`,
		need_auth_token: true,
		payload: { id, id_contact },
	}
}

const GET_AUDIENCE_CONTACTS_SEARCH = 'GET_AUDIENCE_CONTACTS_SEARCH'

export function searchAudienceContacts(id: ID, params?: { search: string, channel_id?: ID, limit?: number, page?: number }, thread_id?: ID): Action {
	const query = urlBuild(params)
	return {
		type: GET_AUDIENCE_CONTACTS_SEARCH,
		call: URL + `/v2/customer/audience/${id}/contact-search${query}`,
		need_auth_token: true,
		payload: { thread_id },
	}
}

const CLEAR_AUDIENCE_CONTACTS_SEARCH = 'CLEAR_AUDIENCE_CONTACTS_SEARCH'

export function clearAudienceContactsResponse() {
	return { type: CLEAR_AUDIENCE_CONTACTS_SEARCH }
}

export const GET_AUDIENCE_CONTACTS_SEARCH_RESPONSE = 'GET_AUDIENCE_CONTACTS_SEARCH_RESPONSE'
export const searchAudienceContactsReducer = bypassReducer<CR<any>>(GET_AUDIENCE_CONTACTS_SEARCH, true, {}, [LOCATION_CHANGED, CLEAR_AUDIENCE_CONTACTS_SEARCH])


const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'

export function toggleSideBar(open?: boolean) {
	return {
		type: TOGGLE_SIDEBAR,
		payload: { open },
	}
}

const TOGGLE_TAG_SIDEBAR = 'TOGGLE_TAG_SIDEBAR'

export function toggleTagSideBar() {
	return {
		type: TOGGLE_TAG_SIDEBAR,
	}
}


const GET_MESSAGES_FROM_CONTACT = 'GET_MESSAGES_FROM_CONTACT'

export function getMessagesFromContact(audience_id, channel_id: number | string, thread_id: number, data: any) {
	return {
		type: GET_MESSAGES_FROM_CONTACT,
		payload: { audience_id, channel_id, thread_id, data },
	}
}
const SET_THREAD_DATA = 'SET_THREAD_DATA'

export function setThreadData(thread) {
	return {
		type: SET_THREAD_DATA,
		payload: { thread },
	}
}

const CLEAR_CONTACT_MESSAGES = 'CLEAR_CONTACT_MESSAGES'

export function clearContactMessages(contact_id) {
	return {
		type: CLEAR_CONTACT_MESSAGES,
		payload: { contact_id },
	}
}

const SET_MESSAGE_COUNT = 'SET_MESSAGE_COUNT'

export function setMessageCount(contact_id, count) {
	return {
		type: SET_MESSAGE_COUNT,
		payload: { contact_id, count },
	}
}

const SEND_MESSAGE_TO_CONTACT = 'SEND_MESSAGE_TO_CONTACT'

export function sendMessageToContact(message, id_contact: number) {
	return {
		type: SEND_MESSAGE_TO_CONTACT,
		payload: { id_contact, message },
	}
}

const SEND_MESSAGE_TO_CONTACT_FROM_BOT = 'SEND_MESSAGE_TO_CONTACT_FROM_BOT'

export function sendMessageToContactFromBot({ message, id_contact, same_thread, audience_id }, hidden) {
	return {
		type: SEND_MESSAGE_TO_CONTACT_FROM_BOT,
		payload: { id_contact, message, same_thread, audience_id, hidden },
	}
}

const SET_MENU = 'SET_MENU'

export function setMenu(menu) {
	return {
		type: SET_MENU,
		payload: { menu },
	}
}

const TOGGLE_MENU = 'TOGGLE_MENU'

export function toggleMenu(index) {
	return {
		type: TOGGLE_MENU,
		payload: { index },
	}
}

const AUDIENCE_SET = 'AUDIENCE_SET'

export function audienceSet() {
	return {
		type: AUDIENCE_SET,
	}
}

const INITIALIZE_CHAT = 'INITIALIZE_CHAT'

export function initializeChat() {
	return {
		type: INITIALIZE_CHAT,
	}
}
const TEST_CHAT = 'TEST_CHAT'

export function testChat() {
	return {
		type: TEST_CHAT,
	}
}

const SCROLL_TO_BOTTOM = 'SCROLL_TO_BOTTOM'

export function scrollToBottomOfChat(id, value) {
	return {
		type: SCROLL_TO_BOTTOM,
		payload: { id, value },
	}
}

const UMNOUNT_CHAT = 'UMNOUNT_CHAT'

export function unmountChat() {
	return {
		type: UMNOUNT_CHAT,
	}
}

const SET_CURRENT_THREAD = 'SET_CURRENT_THREAD'
export function setCurrentThread(id, thread_id) {
	return {
		type: SET_CURRENT_THREAD,
		payload: { thread_id, id },
	}
}

const SET_ROOM = 'SET_ROOM'

export function setRoom(id) {
	return {
		type: SET_ROOM,
		payload: { id },
	}
}

const RECOUNT_CHAT = 'RECOUNT_CHAT'

export function recountChat() {
	return {
		type: RECOUNT_CHAT,
	}
}

const SET_FILTERED = 'SET_FILTERED'

export function setFiltered(isSet: boolean) {
	return {
		type: SET_FILTERED,
		payload: {
			isSet
		}
	}
}

const RESET_PARAMS = 'RESET_PARAMS'
export const resetParams = () => ({ type: RESET_PARAMS })
const SET_PARAMS = 'SET_PARAMS'
export const setParams = (params) => ({ type: SET_PARAMS, payload: params })
const CHANGE_CONTACT_FILTER = 'CHANGE_CONTACT_FILTER'
export const changeFilter = (name) => ({ type: CHANGE_CONTACT_FILTER, payload: { name } })


export const LEFT_MENU = 'LEFT_MENU'
export const leftMenuReducer = produce((draft = { menu: [] }, action) => {
	const { type, payload } = action
	switch (type) {
		case SET_MENU:
			draft.menu = payload.menu
			return
		case TOGGLE_MENU:
			draft.menu[payload.index].open = !draft.menu[payload.index].open
			return
		default:
			return draft
	}
})


export const CHAT = 'CHAT'
const model = {
	room: null,
	audience: null,
	menu: false,
	unread: false,
	common: {
		loading: false,
		allKeys: [],
		byKey: {},
	},
	contacts: {
		byId: {},
		allIds: [],
	},
	threads: {
		loading: false,
		byKey: {},
		allKeys: [],
	},
	colored: {
		loading: false,
		byKey: {},
		allKeys: [],
	},
	filtered: {
		loading: false,
		byKey: {},
		allKeys: [],
	},
	messages: {
		byKey: {},
		allKeys: [],
	},
	messages_count: {
		byKey: {},
	},
	sidebarOpen: false,
	tagSidebarOpen: true,
	count: 0,
	current_thread: null,
	thread: null,
	current_contact_info: null,
	contactsIds: [],
	loaded: false,
	filterName: null,
	scroll: false,
	requestParams: {
		current: 'all',
		all: { offset: 0 },
		is_view: { is_view: 1, offset: 0 },
		color_id: { color_id: null, offset: 0 },
	},
	initCount: {
		audience: false,
		threads: false,
	},
	audience_count: {
		byKey: {},
	},
	threads_count: {
		byKey: {},
	},
	filtered_count: {
		byKey: {},
	},
	colored_count: {
		byKey: {},
	},
}

const objFromArray = (arr, init = {}, field?) => arr.reduce((accumulator, current) => {
	accumulator[current.id] = field ? current[field] : current;
	return accumulator;
}, init)

export const chatReducer = produce((draft = model, action) => {
	const { payload, response } = action

	switch (action.type) {
		case UMNOUNT_CHAT:
			draft.contacts = {
				byId: {},
				allIds: [],
			}
			draft.threads = {
				loading: false,
				byKey: {},
				allKeys: [],
			}
			draft.common = {
				allKeys: [],
				byKey: {},
			}
			return
		case TEST_CHAT:
			draft.contacts = {
				byId: {},
				allIds: [],
			}
			draft.threads = {
				loading: false,
				byKey: {},
				allKeys: [],
			}
			draft.filtered = {
				loading: false,
				byKey: {},
				allKeys: [],
			}
			draft.colored = {
				loading: false,
				byKey: {},
				allKeys: [],
			}
			return
			
		case INITIALIZE_CHAT:
			draft.contacts = {
				byId: {},
				allIds: [],
			}
			draft.threads = {
				loading: false,
				byKey: {},
				allKeys: [],
			}
			draft.filtered = {
				loading: false,
				byKey: {},
				allKeys: [],
			}
			draft.colored = {
				loading: false,
				byKey: {},
				allKeys: [],
			}
			return
		case RESET_PARAMS :
			draft.requestParams = {
				current: 'all',
				all: { offset: 0 },
				is_view: { is_view: 1, offset: 0 },
				color_id: { color_id: null, offset: 0 },
			}
			draft.initCount.threads = false
			draft.threads_count = {
				byKey: {},
			}
			draft.filtered_count = {
				byKey: {},
			}
			draft.colored_count = {
				byKey: {},
			}
			return
		case SET_MENU:
			draft.menu = true
			return
		case SCROLL_TO_BOTTOM:
			draft.threads.byKey[payload.id].scroll = payload.value
			return
		case SET_ROOM:
			draft.room = payload.id
			return
		case UPDATE_AUDIENCE_OPEN + SUCCESS:
			draft.audience[payload.index].open = payload.open
			return
		case GET_AUDIENCE + SUCCESS:
			draft.audience = response.data
			draft.unread = response.data.some(({ count, favicon }) => favicon ? count : false)
			if (!draft.initCount.audience) {
				response.data.forEach((audience) => {
					draft.audience_count.byKey[audience.id] = audience.count
				})
				draft.initCount.audience = true
			}
			return
		case UNBLOCK_USER + SUCCESS:
			draft.threads.byKey[payload.id].block = 0
			return
		case BLOCK_USER + SUCCESS:
			draft.threads.byKey[payload.id].block = 1
			return
		case SET_COUNTS:
			if (draft.initCount.audience) {
				const insideThread = payload.id_contact === parseInt(draft.current_thread)
				const { id, count } = payload.audience_message
				if (!insideThread) {
					draft.audience_count.byKey[id] = parseInt(count)
					draft.threads_count.byKey[payload.id_contact] = payload.count
				}
				draft.unread = Object.values(original(draft.audience_count.byKey)).some((count) => count)
			}
			return
		case SET_CURRENT_THREAD:
			const { thread_id } = payload
			draft.current_thread = payload.thread_id
			const state = original(draft)
			const thread = state.threads.byKey[thread_id] ||state.colored.byKey[thread_id] ||state.filtered.byKey[thread_id]
			draft.thread = thread
			console.log(thread)
			return
		case GET_AUDIENCE_MESSAGES_COUNT:
			const { data } = payload
			data.forEach(({ id, count }) => {
				draft.audience_count.byKey[id] = count
			})
			return
		case GET_MESSAGES_FROM_CONTACT:
			draft.messages.byKey[payload.thread_id] = payload.data
			draft.messages_count.byKey[payload.thread_id] = 0
			if (payload.thread_id == draft.current_thread) {
				draft.audience_count.byKey[payload.audience_id] -= draft.threads_count.byKey[payload.thread_id]
			}
			draft.threads_count.byKey[payload.thread_id] = 0
			return
		case CLEAR_CONTACT_MESSAGES:
			draft.messages.byKey[payload.contact_id] = null
			draft.unread = false
			return
		case SEND_MESSAGE_TO_CONTACT:
			if (!draft.messages.byKey[payload.id_contact]) draft.messages.byKey[payload.id_contact] = []
			draft.messages.byKey[payload.id_contact].push(payload.message)
			if (draft.threads.byKey[payload.id_contact] && payload?.message?.text) {
				draft.threads.byKey[payload.id_contact].last_message = payload.message.text
				draft.threads.allKeys = [payload.id_contact, ...current(draft.threads).allKeys.filter(id => id !== payload.id_contact)]
			}
			return
		case SEND_MESSAGE_TO_CONTACT_FROM_BOT:
			if (!draft.messages.byKey[payload.id_contact]) draft.messages.byKey[payload.id_contact] = []
			draft.messages.byKey[payload.id_contact].push(payload.message)
			if (draft.threads.byKey[payload.id_contact] && payload?.message?.text) {
				draft.threads.byKey[payload.id_contact].last_message = payload.message.text
				draft.threads.allKeys = [payload.id_contact, ...current(draft.threads).allKeys.filter(id => id !== payload.id_contact)]
			}
			if (!payload.same_thread) {
				// draft.threads_count.byKey[payload.id_contact]++
				draft.unread = true
			}
			if (payload.hidden) draft.unread = true

			if (!draft.filtered.byKey[payload.id_contact]) {

				draft.filtered.byKey[payload.id_contact] = draft.threads.byKey[payload.id_contact]
			}
			return
		case RECOUNT_CHAT:
			const currentCount = current(draft.audience_count.byKey)
			draft.unread = Object.values(currentCount).some((v) => Boolean(v))
			return
		case GET_AUDIENCE_ALL_CONTACTS_ID + SUCCESS:
			draft.contactsIds[payload.id] = response.data.contacts.map(({ id }) => Number(id))
			return
		case GET_AUDIENCE_CONTACT_INFO + START:
			draft.threads.loading = true
			return
		case GET_AUDIENCE_CONTACT_INFO + SUCCESS:
			draft.threads.loading = false
			draft.contacts.byId[payload.id_contact] = response.data.contacts
			draft.threads.byKey[payload.id_contact] = response.data.contacts
			draft.common.byKey[payload.id_contact] = response.data.contacts
			draft.threads.allKeys = [payload.id_contact, ...draft.threads.allKeys]
			draft.common.allKeys = [payload.id_contact, ...draft.common.allKeys]
			draft.contacts.allIds = [payload.id_contact, ...draft.threads.allKeys]
			draft.count = 10
			return
		case "CHANGE_AUDIENCE_ACTIVE_COLOR_SUCCESS":
			draft.threads.byKey[response.data.id] = response.data
			draft.common.byKey[response.data.id] = response.data
			return
		case SET_PARAMS :
			draft.requestParams.current = payload.current
			draft.requestParams[payload.current] = { ...draft.requestParams[payload.current], ...payload[payload.current] }
			return
		case GET_AUDIENCE_FILTERED_CONTACTS + START:
		case GET_AUDIENCE_COLOR_CONTACTS + START:
		case GET_AUDIENCE_INITIAL_CONTACTS + START:
			draft.threads.loading = true
			return
		case GET_AUDIENCE_COLOR_CONTACTS + SUCCESS:
			draft.threads.loading = false
			draft.contacts.byId = { ...draft.contacts.byId, ...objFromArray(response.data.contacts) }
			draft.colored.byKey = { ...draft.colored.byKey, ...objFromArray(response.data.contacts) }
			draft.common.byKey = { ...draft.common.byKey, ...objFromArray(response.data.contacts) }
			draft.colored_count.byKey = { ...draft.colored_count.byKey, ...objFromArray(response.data.contacts, {}, 'count_no_view') }
			// @ts-ignore
			draft.colored.allKeys = Object.values(draft.colored.byKey).sort((a, b) => (a.updated_at < b.updated_at) ? 1 : -1)
				.map(({ id }) => id)		// @ts-ignore
			draft.common.allKeys = Object.values(draft.common.byKey).sort((a, b) => (a.updated_at < b.updated_at) ? 1 : -1)
				.map(({ id }) => id)
			return
		case GET_AUDIENCE_FILTERED_CONTACTS + SUCCESS:
			draft.threads.loading = false
			draft.contacts.byId = { ...draft.contacts.byId, ...objFromArray(response.data.contacts) }
			draft.filtered.byKey = { ...draft.filtered.byKey, ...objFromArray(response.data.contacts) }
			draft.common.byKey = { ...draft.common.byKey, ...objFromArray(response.data.contacts) }

			draft.filtered_count.byKey = { ...draft.filtered_count.byKey, ...objFromArray(response.data.contacts, {}, 'count_no_view') }
			// @ts-ignore
			draft.filtered.allKeys = Object.values(draft.filtered.byKey).sort((a, b) => (a.updated_at < b.updated_at) ? 1 : -1)
				.map(({ id }) => id)
			// @ts-ignore
			draft.common.allKeys = Object.values(draft.common.byKey).sort((a, b) => (a.updated_at < b.updated_at) ? 1 : -1)
				.map(({ id }) => id)
			return
		case GET_AUDIENCE_INITIAL_CONTACTS + SUCCESS:
			draft.threads.loading = false
			const contactsIds = original(draft.contacts.byId)
			draft.contacts.byId = { ...contactsIds, ...objFromArray(response.data.contacts) }
			draft.threads.byKey = { ...contactsIds, ...objFromArray(response.data.contacts) }
			if (!draft.initCount.threads) {
				draft.initCount.threads = true
				draft.threads_count.byKey = { ...contactsIds, ...objFromArray(response.data.contacts, {}, 'count_no_view') }
			}
			draft.contacts.allIds = Object.keys(draft.contacts.byId)
			const threads = current(draft.threads)
			const common = current(draft.threads)
			draft.common.byKey = { ...contactsIds, ...draft.common.byKey, ...objFromArray(response.data.contacts) }
// @ts-ignore
			draft.common.allKeys = Object.values(common.byKey).sort((a, b) => (a.updated_at < b.updated_at) ? 1 : -1)
				.map(({ id }) => id)
			// @ts-ignore
			draft.threads.allKeys = Object.values(threads.byKey).sort((a, b) => (a.updated_at < b.updated_at) ? 1 : -1)
				.map(({ id }) => id)
			draft.loaded = true
			draft.count = response.data.count
			return

		case TOGGLE_SIDEBAR:
			draft.sidebarOpen = payload.open ?? !draft.sidebarOpen
			return
		case TOGGLE_TAG_SIDEBAR:
			draft.tagSidebarOpen = !draft.tagSidebarOpen
			return
		default:
			return draft
	}
})


const CREATE_CHANNEL_FOR_AUDIENCE = 'CREATE_CHANNEL_FOR_AUDIENCE'

export function createChannelForAudience(id, { name, key, type_id, old_dialog, is_other_service }): Action {
	return {
		type: CREATE_CHANNEL_FOR_AUDIENCE,
		callPOST: URL + `/v2/customer/audience/${id}/channel`,
		payload: { name, key, old_dialog },
		queryData: {
			ChannelForm: {
				name, key, type_id, old_dialog, is_other_service
			},
		},
		need_auth_token: true,
	}
}

export const CREATE_CHANNEL_FOR_AUDIENCE_RESPONSE = 'CREATE_CHANNEL_FOR_AUDIENCE_RESPONSE'
export const createChannelForAudienceReducer = bypassReducer<CR<boolean>>(CREATE_CHANNEL_FOR_AUDIENCE, true, {}, [LOCATION_CHANGED])
