import { bypassReducer } from "helpers/ReduxHelper"
import { Action, CR, ID } from "types"
import { URL } from '../../../constants'

const GET_AUDIENCE_KEYWORDS = 'GET_AUDIENCE_KEYWORDS'
export function getAudienceKeywords(id: ID): Action {
	return {
		type: GET_AUDIENCE_KEYWORDS,
		call: URL + `/v2/customer/audience/${id}`,
		need_auth_token: true,
	}
}
export const GET_AUDIENCE_KEYWORDS_RESPONSE = 'GET_AUDIENCE_KEYWORDS_RESPONSE'
export const getAudienceKeywordsReducer = bypassReducer<CR<any>>(GET_AUDIENCE_KEYWORDS, true, {})


type AddParams = {
	keywords: string
	message?: string
	tag_id: ID
}
const ADD_AUDIENCE_KEYWORDS = 'ADD_AUDIENCE_KEYWORDS'
export function addAudienceKeywords(id: ID, params: AddParams): Action {
	return {
		type: ADD_AUDIENCE_KEYWORDS,
		callPOST: URL + `/v2/customer/audience/${id}/keywords`,
		payload: { params },
		queryData: {
			AudienceKeywordsForm: params,
		},
		need_auth_token: true,
	}
}
export const ADD_AUDIENCE_KEYWORDS_RESPONSE = 'ADD_AUDIENCE_KEYWORDS_RESPONSE'
export const addAudienceKeywordsReducer = bypassReducer<CR<any>>(ADD_AUDIENCE_KEYWORDS, true, {})

const UPDATE_AUDIENCE_KEYWORDS = 'UPDATE_AUDIENCE_KEYWORDS'
export function updateAudienceKeywords(id: ID, params: AddParams): Action {
	return {
		type: UPDATE_AUDIENCE_KEYWORDS,
		callPOST: URL + `/v2/customer/audience-keywords/${id}/keywords-update`,
		payload: { params },
		queryData: {
			AudienceKeywordsForm: params,
		},
		need_auth_token: true,
	}
}
export const UPDATE_AUDIENCE_KEYWORDS_RESPONSE = 'UPDATE_AUDIENCE_KEYWORDS_RESPONSE'
export const updateAudienceKeywordsReducer = bypassReducer<CR<any>>(UPDATE_AUDIENCE_KEYWORDS, true, {})


const DELETE_AUDIENCE_KEYWORDS = 'DELETE_AUDIENCE_KEYWORDS'
export function deleteAudienceKeywords(id: ID): Action {
	return {
		type: DELETE_AUDIENCE_KEYWORDS,
		callPOST: URL + `/v2/customer/audience-keywords/${id}/delete`,
		payload: { id },
		need_auth_token: true,
	}
}
export const DELETE_AUDIENCE_KEYWORDS_RESPONSE = 'DELETE_AUDIENCE_KEYWORDS_RESPONSE'
export const deleteAudienceKeywordsReducer = bypassReducer<CR<any>>(DELETE_AUDIENCE_KEYWORDS, true, {})

