import { moduleName } from './index'
import {
	GET_BILL_RESPONSE, getBillReducer,
	GET_BALANCE_RESPONSE, getBalanceReducer,
	GET_FILTER_OPERATIONS_RESPONSE, getFilterOperationsReducer,
	GET_UP_REFILL_FORM_RESPONSE, getUpRefillFormReducer,
	GET_BILLING_OPERATION_RESPONSE, getBillingOperationReducer,
	getPaymentsOperations,
	getPaymentsOperationsFilter,
	getBonusOperations,
} from './common'

import {
	GET_PAY_IN_LIST_RESPONSE, getPayInListReducer,
	DELETE_BILLING_OPERATION_RESPONSE, deleteBillingOperationReducer,
	GET_ADMIN_PRICE_RESPONSE, getAdminPriceReducer,
	ADD_ADMIN_PRICE_RESPONSE, addAdminPriceReducer,
	UPDATE_ADMIN_PRICE_RESPONSE, updateAdminPriceReducer,
	DELETE_ADMIN_PRICE_RESPONSE, deleteAdminPriceReducer,
	getBonusOperationsFilter,
	getOperationsFilter,
	addBillingAffiliateOperations,
} from './admin'


export default {
	[moduleName + GET_BILL_RESPONSE]: getBillReducer,
	[moduleName + GET_BALANCE_RESPONSE]: getBalanceReducer,
	[moduleName + GET_FILTER_OPERATIONS_RESPONSE]: getFilterOperationsReducer,
	[moduleName + getBonusOperations.response_type]: getBonusOperations.reducer,
	[moduleName + getPaymentsOperations.response_type]: getPaymentsOperations.reducer,
	[moduleName + getPaymentsOperationsFilter.response_type]: getPaymentsOperationsFilter.reducer,
	[moduleName + getBonusOperationsFilter.response_type]: getBonusOperationsFilter.reducer,
	[moduleName + getOperationsFilter.response_type]: getOperationsFilter.reducer,
	[moduleName + addBillingAffiliateOperations.response_type]: addBillingAffiliateOperations.reducer,
	[moduleName + GET_UP_REFILL_FORM_RESPONSE]: getUpRefillFormReducer,
	[moduleName + GET_BILLING_OPERATION_RESPONSE]: getBillingOperationReducer,
	[moduleName + GET_PAY_IN_LIST_RESPONSE]: getPayInListReducer,
	[moduleName + DELETE_BILLING_OPERATION_RESPONSE]: deleteBillingOperationReducer,
	[moduleName + GET_ADMIN_PRICE_RESPONSE]: getAdminPriceReducer,
	[moduleName + ADD_ADMIN_PRICE_RESPONSE]: addAdminPriceReducer,
	[moduleName + UPDATE_ADMIN_PRICE_RESPONSE]: updateAdminPriceReducer,
	[moduleName + DELETE_ADMIN_PRICE_RESPONSE]: deleteAdminPriceReducer,
}
