// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import bootstrap from '!!raw-loader!./bootstrap.css'
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import reset from '!!raw-loader!./reset.css'
import 'ion-rangeslider/css/ion.rangeSlider.min.css'
import 'ion-rangeslider/js/ion.rangeSlider.min'
import jQuery from 'jquery'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { connect, ConnectedProps } from 'react-redux'
import { YANDEX_METRIC_ID } from '../../constants'
import { injectCss, injectJs } from '../../helpers'
import useSetPartnerCookie from '../../hooks/useSetPartnerCookie'
import useUtmGuest from '../../hooks/useUtmGuest'
import { getJivoForLanding, getMainLanding } from '../../redux/ducks/common'
import { CR } from '../../types'
import './index.scss'
import { RootState } from "../../redux/store";

interface Props extends PropsFromRedux {
}

const MainLanding: React.FC<Props> = (props) => {
	const { landingInfo, jivoSite } = props
	const [listeners, setListeners] = useState(null)
	const loadedRef = useRef()
	useUtmGuest()
	useSetPartnerCookie()

	useEffect(() => {
		const resetCss = injectCss(reset, 'reset')
		const bootstrapCss = injectCss(bootstrap, 'bootstrap')
		return () => {
			document.head.removeChild(resetCss)
			document.head.removeChild(bootstrapCss)
		}
	}, [])
	useEffect(() => {
		// @ts-ignore
		props.getMainLanding()
		props.getJivoForLanding()
		// @ts-ignore
		window.ym(YANDEX_METRIC_ID, 'reachGoal', 'rus_landing')

	}, [])
	useEffect(() => {
		let jivosite = null
		// @ts-ignore
		if (jivoSite?.data) jivosite = injectJs("//code.jivosite.com/widget/81KxDQNtbU", 'jivoSite')
		return () => {
			if (jivosite) document.body.removeChild(jivosite)
		}
	}, [jivoSite])
	useEffect(() => {
		if (landingInfo?.data?.html) {
			const regUpperButton = document.getElementById('reg-upper-button')
			const regSecondButton = document.getElementById('reg-second-button')
			const regThirdButton = document.getElementById('reg-third-button')
			const regFourthButton = document.getElementById('reg-fourth-button')
			const regFromFooter = document.getElementById('reg-from-footer')
			const testFirstButton = document.getElementById('test-first-button')
			const howItWorksBotExample = document.getElementById('how-it-works-bot-example')
			const tgLinkFromFooter = document.getElementById('tg-link-from-footer')

			regUpperButton && regUpperButton.addEventListener('click', () => {
				window.ym(YANDEX_METRIC_ID, 'reachGoal', 'header_register_button')
				window.gtag('event', 'header_register_button')
				window.ym(YANDEX_METRIC_ID, 'reachGoal', 'all_register_buttons')
				window.gtag('event', 'all_register_buttons')
				window.VK.Goal('initiate_checkout')
				window.fbq('track', 'InitiateCheckout')
			})
			regSecondButton && regSecondButton.addEventListener('click', () => {
				window.ym(YANDEX_METRIC_ID, 'reachGoal', 'main_register_button')
				window.gtag('event', 'main_register_button')
				window.ym(YANDEX_METRIC_ID, 'reachGoal', 'all_register_buttons')
				window.gtag('event', 'all_register_buttons')
				window.VK.Goal('initiate_checkout')
				window.fbq('track', 'InitiateCheckout')
			})
			regThirdButton && regThirdButton.addEventListener('click', () => {
				window.ym(YANDEX_METRIC_ID, 'reachGoal', 'what_we_have_register_button')
				window.gtag('event', 'what_we_have_register_button')
				window.ym(YANDEX_METRIC_ID, 'reachGoal', 'all_register_buttons')
				window.gtag('event', 'all_register_buttons')
				window.VK.Goal('initiate_checkout')
				window.fbq('track', 'InitiateCheckout')
			})
			regFourthButton && regFourthButton.addEventListener('click', () => {
				window.ym(YANDEX_METRIC_ID, 'reachGoal', 'footer_numbers_register_button')
				window.gtag('event', 'footer_numbers_register_button')
				window.ym(YANDEX_METRIC_ID, 'reachGoal', 'all_register_buttons')
				window.gtag('event', 'all_register_buttons')
				window.VK.Goal('initiate_checkout')
				window.fbq('track', 'InitiateCheckout')
			})

			regFromFooter && regFromFooter.addEventListener('click', () => {
				window.ym(YANDEX_METRIC_ID, 'reachGoal', 'footer_register_link')
				window.gtag('event', 'footer_register_link')
				window.ym(YANDEX_METRIC_ID, 'reachGoal', 'all_register_buttons')
				window.gtag('event', 'all_register_buttons')
				window.VK.Goal('initiate_checkout')
				window.fbq('track', 'InitiateCheckout')
			})
			testFirstButton && testFirstButton.addEventListener('click', () => {
				window.ym(YANDEX_METRIC_ID, 'reachGoal', 'best_looks_test_first_button')
				window.gtag('event', 'best_looks_test_first_button')
				window.ym(YANDEX_METRIC_ID, 'reachGoal', 'test_bot_from_landing')
				window.gtag('event', 'test_bot_from_landing')
				window.VK.Goal('contact')
				window.fbq('track', 'Contact')
			})
			howItWorksBotExample && howItWorksBotExample.addEventListener('click', () => {
				window.ym(YANDEX_METRIC_ID, 'reachGoal', 'how_it_works_test_link_landing')
				window.gtag('event', 'how_it_works_test_link_landing')
				window.ym(YANDEX_METRIC_ID, 'reachGoal', 'test_bot_from_landing')
				window.gtag('event', 'test_bot_from_landing')
				window.VK.Goal('contact')
				window.fbq('track', 'Contact')
			})
			tgLinkFromFooter && tgLinkFromFooter.addEventListener('click', () => {
				window.ym(YANDEX_METRIC_ID, 'reachGoal', 'footer_chat_link_landing')
				window.gtag('event', 'footer_chat_link_landing')
				window.ym(YANDEX_METRIC_ID, 'reachGoal', 'all_chat_invites_from_landing')
				window.gtag('event', 'all_chat_invites_from_landing')
				window.VK.Goal('contact')
				window.fbq('track', 'Contact')
			})
			setListeners({ regUpperButton })
		}
		return () => {
			setListeners(null)
		}
	}, [landingInfo])


	useEffect(() => {
		if (jQuery && landingInfo?.data?.html) {

			jQuery(document).ready(function () {
				jQuery('.what_do_haves_link a').click(function (event) {
					event.preventDefault();
					jQuery('.what_do_haves_link.active').removeClass('active');
					jQuery(this).parent().addClass('active');
					var id = jQuery(this).attr('href');
					jQuery('.what_do_haves_tab.active').hide().removeClass('active');
					jQuery(id).fadeIn().addClass('active');
				});
				jQuery('.answer_link').click(function (event) {
					event.preventDefault();
					jQuery(this).toggleClass('active');
				});
			});
		}
	}, [window, landingInfo?.data?.html])
	console.log()
	useEffect(() => {
		console.log(jQuery("#range")?.length)
		if (loadedRef?.current && jQuery("#range")?.length) {
			jQuery("#range").ionRangeSlider({
				skin: "round",
				grid: true,
				grid_num: 0,
				from: 2,
				values: ["500", "1000", "3000", "5000", "10000"],
				onChange: function (data) {
					const price_desktop = document.getElementById("price_desktop")
					const price_mobile = document.getElementById("price_mobile")
					const desc_first_desktop = document.getElementById("desc_first_desktop")
					const desc_first_mobile = document.getElementById("desc_first_mobile")
					console.log(data)

					switch (data.from_value) {
						case 500:
							price_desktop.innerText = '450'
							price_mobile.innerText = '450'
							desc_first_desktop.innerText = "3 коп за каждого"
							desc_first_mobile.innerText = "3 коп за каждого"
							return
						case 1000:
							price_desktop.innerText = '1050'
							price_mobile.innerText = '1050'
							desc_first_desktop.innerText = "3,5 коп за каждого"
							desc_first_mobile.innerText = "3,5 коп за каждого"
							return
						case 3000:
							price_desktop.innerText = '3600'
							price_mobile.innerText = '3600'
							desc_first_desktop.innerText = "4 коп за каждого"
							desc_first_mobile.innerText = "4 коп за каждого"
							return
						case 5000:
							price_desktop.innerText = '7500'
							price_mobile.innerText = '7500'
							desc_first_desktop.innerText = "5 коп за каждого"
							desc_first_mobile.innerText = "5 коп за каждого"
							return
						case 10000:
							price_desktop.innerText = '9000'
							price_mobile.innerText = '9000'
							desc_first_desktop.innerText = "3 коп за каждого"
							desc_first_mobile.innerText = "3 коп за каждого"
							return

					}
				},
			})
		}
	}, [loadedRef, jQuery("#range")])

	return (
		<div className="wrap">
			<Helmet>
				<title>Автоворонки и туннели продаж в мессенджерах - WorkHard.Chat</title>
				<meta name="description" content="Автоворонки и туннели продаж в мессенджерах - WorkHard.Chat" />
				<meta property="og:site_name" content="WorkHard.Chat" />
				<meta property="og:title" content="Автоворонки и туннели продаж в мессенджерах - WorkHard.Chat" />
				<meta
					name="og:image"
					content="https://fswho.fra1.cdn.digitaloceanspaces.com/workhard_chat/preview_logo.png"
				/>
				<meta
					name="og:image:url"
					content="https://fswho.fra1.cdn.digitaloceanspaces.com/workhard_chat/preview_logo.png"
				/>
				<meta property="og:description" content="Автоворонки и туннели продаж в мессенджерах - WorkHard.Chat" />
				<link rel="preconnect" href="https://fonts.gstatic.com" />

				<meta name="viewport" content="width=device-width, initial-scale=1.0" />

			</Helmet>
			{/*<div className="landing-wrapper">*/}
			{/*	<div className="plans">*/}
			{/*		<div className="container">*/}
			{/*			<div className="row">*/}
			{/*				<div className="col">*/}
			{/*					<h2>Тарифные планы</h2>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="row">*/}
			{/*				<div className="col">*/}
			{/*					<div className="description">*/}
			{/*						<div className="main">*/}
			{/*							<h3>Сколько у вас подписчиков?</h3>*/}
			{/*							<div className="range-slider">*/}
			{/*								<input type="text" id="range" />*/}
			{/*							</div>*/}
			{/*							<div className="month mobile">*/}
			{/*								<div className="cloud">*/}
			{/*									<div className="monthly">*/}
			{/*										<div className="price inline" id="price_mobile">3600</div> ₽ / мес*/}
			{/*									</div>*/}
			{/*									<div className="desc">*/}
			{/*										<div className="inline" id="desc_first_mobile">4 коп за каждого</div>*/}
			{/*										<div className="inline" id="desc_second">подписчика в сутки</div>*/}
			{/*									</div>*/}
			{/*								</div>*/}
			{/*							</div>*/}
			{/*							<ul>*/}
			{/*								<li><span>500 подписчиков бесплатно</span></li>*/}
			{/*								<li><span>Ежедневное списание за реальное число подписчиков в базе</span>*/}
			{/*								</li>*/}
			{/*								<li><span>Бонус 30% при разовом пополнении баланса от 50 тысяч рублей</span>*/}
			{/*								</li>*/}
			{/*							</ul>*/}
			{/*						</div>*/}
			{/*						<div className="month desktop">*/}
			{/*							<div className="cloud">*/}
			{/*								<div className="monthly">*/}
			{/*									<div className="price inline" id="price_desktop">3600</div> ₽ / мес*/}
			{/*								</div>*/}
			{/*								<div className="desc">*/}
			{/*									<div className="inline" id="desc_first_desktop">4 коп за каждого</div>*/}
			{/*									<div className="inline" id="desc_second">подписчика в сутки</div>*/}
			{/*								</div>*/}
			{/*							</div>*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</div>*/}
			<div
				ref={loadedRef}
				className="landing-wrapper"
				dangerouslySetInnerHTML={{ __html: landingInfo?.data?.html }}
			/>
		</div>
	)
}

const connector = connect((state: RootState) => ({
	landingInfo: state.common.mainLandingResponse,
	jivoSite: state.common.jivoForLanding,
}), { getMainLanding, getJivoForLanding: getJivoForLanding.action })

type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(MainLanding)