import { Switch, FormControlLabel } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

const Wrapper = styled(FormControlLabel)`
  .MuiSwitch-colorSecondary {
    color: #d2d2d2
  }

  .MuiSwitch-colorSecondary.Mui-checked {
    color: #2196f3;
  }
`

interface Props {
	value: boolean
	label: string
	name?: string
	handleChange: (value: any) => void
}

const LabeledSwitch: React.FC<Props> = (props) => {
	const { value, label, name = 'switch' } = props

	return (
		<Wrapper
			control={
				<Switch
					checked={value}
					onChange={props.handleChange}
					name={name}
				/>
			}
			label={label}
		/>
	)
}

export default LabeledSwitch
