import React from 'react'
import { FormControl, FormHelperText, Input, InputLabel } from '@material-ui/core'


interface Props {
	handleBlur: (event: React.FocusEvent) => void
	error: {
		email: boolean
		code: boolean
	}
}

const EnterMail: React.FC<Props> = ({ error, handleBlur }) => (
	<form>
		<FormControl error={error.email} margin="normal" required fullWidth>
			<InputLabel htmlFor="email">Email</InputLabel>
			<Input onBlur={handleBlur} id="email" name="email" autoComplete="email" autoFocus />
			{error.email ?
				<FormHelperText id="my-helper-text">Данный email уже используется</FormHelperText>
				: null}
		</FormControl>
	</form>
)

export default EnterMail
