import { useEffect } from 'react'
import { injectJs } from '../../helpers'

export default ({user, jivoActive, jivoDisable}) => {
	useEffect(() => {
		if (user?.data?.id) {
			if (user?.data?.jivo_site) {
				injectJs("//code.jivosite.com/widget/cGExPfAZJt", 'jivoSite')
			}
		}
	}, [user])
	useEffect(() => {
		if (jivoDisable.success) {
			window.location = window.location
		}
		if (jivoActive.success) {
			injectJs("//code.jivosite.com/widget/cGExPfAZJt", 'jivoSite')
		}
	}, [jivoActive, jivoDisable])

}
