import { Box as MuiBox, Divider as MuiDivider, Menu, MenuItem } from '@material-ui/core'
import * as Icon from '@material-ui/icons'
import { spacing } from '@material-ui/system'
import React, { useEffect, useState } from 'react'
import { Power } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import LabeledSwitch from '../../components/common/LabeledSwitch'
import { priceSet } from '../../helpers'
import { logoutUser } from '../../redux/ducks/auth'
import { getJivoForDashboard, setJivoActive, setJivoDisable, } from '../../redux/ducks/common'
import { IconButton } from './styles'
import { RootState } from "../../redux/store";

const Divider = styled(MuiDivider)(spacing);
const MenuLink = styled(MenuItem)`
  a {
    text-decoration: none;
    color: #202223;
  }
`

const Box = styled(MuiBox)`


  small {
    font-size: 14px;
    color: #202223;

    &.bonus {
      color: #47a14d;
    }

    &.partner {
      color: #1976d2;
    }

    &.sum {
      font-weight: bold;
    }
  }

  .enable-chat {
    font-size: 10px;
  }

  .bill-info {
    padding: 12px;
  }


  .bill-divider {
    display: flex;
    align-items: center;

    .MuiDivider-vertical {
      height: 50%;
    }
  }

  ${props => props.theme.breakpoints.down("xs")} {
    small {
      font-size: 12px;
    }

    .billing {
      padding: 8px
    }

    .bill-info {
      padding: 8px;
    }

    svg {
      height: 16px;
      width: 16px;
    }

    .MuiIconButton-root {
      padding: 8px;
    }

  }

  @media (max-width: 320px) {
    small {
      font-size: 9px;
    }
  }
`

const Link = styled(NavLink)`
  text-decoration: none;
`


export default function UserMenu(props) {
	const dispatch = useDispatch()
	const user = useSelector((state: RootState) => state.common.userData)
	const jivoSite = useSelector((state: RootState) => state.common.jivoForDashboard)

	const [anchorMenu, setAnchorMenu] = useState(null)
	const [billingMenu, setBillingMenu] = useState(null)
	const [faqMenu, setFaqMenu] = useState(null)
	const [token] = useState(localStorage.getItem('auth_token') && new Date(localStorage.getItem('auth_token')).toLocaleDateString())
	const [isChat, setChat] = useState(false)

	useEffect(() => {
		dispatch(getJivoForDashboard.action())

	}, [dispatch])
	useEffect(() => {
		if (jivoSite?.data) {
			setChat(Boolean(jivoSite.data))
		}
	}, [jivoSite])

	const toggleMenu = event => setAnchorMenu(event.currentTarget)
	const toggleBillingMenu = event => setBillingMenu(event.currentTarget)
	const toggleFaqMenu = event => setFaqMenu(event.currentTarget)


	const closeBillingMenu = () => setBillingMenu(null)
	const closeFaqMenu = () => setFaqMenu(null)
	const closeMenu = () => {
		setAnchorMenu(null);
	}
	const logOut = () => {
		// dispatch(sendErrorMessageToBot.action({ info: "delete cookie - logout response", user: user.data }))
		dispatch(logoutUser())
	}
	const handleChat = () => {
		setChat((prev) => {
			if (prev) dispatch(setJivoDisable.action())
			else dispatch(setJivoActive.action())
			return !prev
		})
	}
	return (
		<Box display="flex" alignItems="center">
			<IconButton // @ts-ignore
				color="inherit" component={NavLink} to="/billing" className="billing"
			>
				<Icon.AttachMoney />
				<Box component="span" display="flex">
					<Box display="flex" flexDirection="column" className="bill-info">
						<Box component="small" display="inline-block">
							Основной счет
						</Box>
						<Box component="small" className="sum" display="inline-block">
							{priceSet(user?.data?.bill?.sum)} ₽
						</Box>
					</Box>
					{Boolean(user?.data?.bill_bonus?.sum) && (
						<>
							<div className="bill-divider">
								<Divider orientation="vertical" />
							</div>
							<Box display="flex" flexDirection="column" className="bill-info">
								<Box component="small" className="bonus" display="inline-block">
									Бонусный счет
								</Box>
								<Box component="small" className="sum bonus" display="inline-block">
									{priceSet(user.data.bill_bonus.sum)} ₽
								</Box>
							</Box>
						</>
					)}
					{Boolean(user?.data?.bill_affiliate?.sum) && (
						<>
							<div className="bill-divider">
								<Divider orientation="vertical" />
							</div>
							<Box display="flex" flexDirection="column" className="bill-info">
								<Box component="small" className="partner" display="inline-block">
									Партнерский счет
								</Box>
								<Box component="small" className="sum partner" display="inline-block">
									{priceSet(user.data.bill_affiliate.sum)} ₽
								</Box>
							</Box>
						</>
					)}
				</Box>
			</IconButton>
			<IconButton
				aria-owns={Boolean(faqMenu) ? "menu-appbar" : undefined}
				aria-haspopup="true"
				onClick={toggleFaqMenu}
				color="inherit"
			>
				<Icon.HelpOutline />
			</IconButton>
			<Menu
				id="menu-appbar"
				anchorEl={faqMenu}
				open={Boolean(faqMenu)}
				onClose={closeFaqMenu}
			>
				<MenuItem>
					<Box display="flex" flexDirection="column">
						<LabeledSwitch
							value={isChat}
							label={"Включить чат"}
							handleChange={handleChat}
						/>
						<Box display="flex" flexDirection="column">
							<small className="enable-chat">Включение онлайн-чата, находящегося справа-снизу.</small>
							<small className="enable-chat">Мы онлайн с 10 утра до 21 вечера по Московскому
								времени.</small>
							<small className="enable-chat">Если никого нет - чатик не покажется, зайдите в рабочее время
								=( </small>
						</Box>
					</Box>
				</MenuItem>
				<Divider variant="middle" />
				<MenuLink>
					<a
						href="tg://resolve?start=support&domain=workhardchat_bot"
						rel="noopener noreferrer"
						target="_blank"
					>Поддержка в Телеграм</a>
				</MenuLink>
				<Divider variant="middle" />
				<MenuLink>
					<a
						href="tg://resolve?domain=workhardchat"
						rel="noopener noreferrer"
						target="_blank"
					>
						Чат пользователей сервиса в Телеграм
					</a>
				</MenuLink>
				<Divider variant="middle" />
				<MenuLink>
					<a
						href="https://workhard.chat/rus/blog/"
						rel="noopener noreferrer"
						target="_blank"
					>
						Блог и инструкции
					</a>
				</MenuLink>
				<Divider variant="middle" />
				<MenuLink><Link to="/faq">Справка</Link></MenuLink>
			</Menu>
			<IconButton
				aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
				aria-haspopup="true"
				onClick={toggleMenu}
				color="inherit"
			>
				<Power />
			</IconButton>
			<Menu
				id="menu-appbar"
				anchorEl={billingMenu}
				open={Boolean(billingMenu)}
				onClose={closeBillingMenu}
			>
				<MenuItem>
					<Link to="/billing">
						Доступно {user?.data?.bill?.sum}₽
					</Link>
				</MenuItem>
			</Menu>
			<Menu
				id="menu-appbar"
				anchorEl={anchorMenu}
				open={Boolean(anchorMenu)}
				onClose={closeMenu}
			>
				{/*<MenuItem onClick={closeMenu}>*/}
				{/*	Profile*/}
				{/*</MenuItem>*/}
				<MenuLink >Сессия активна до:&nbsp;<strong>{token}</strong></MenuLink>
				<MenuItem onClick={logOut}>
					Выйти
				</MenuItem>
			</Menu>
		</Box>
	)
}
