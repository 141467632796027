import { Action } from '../types'

const FAIL = '_FAIL'
const SUCCESS = '_SUCCESS'
const START = '_START'
const RESPONSE = '_RESPONSE'
const CLEAR = 'CLEAR_'

const reducerFunc = (params) => {
	if (typeof params === 'string') return bypassReducer(params, false, {}, CLEAR + params)
	return bypassReducer(params.type, params.savePrevData, params.initialState = {}, CLEAR + params.type)
}

export function reduxToolkit(params, action: (...args: any[]) => Action, reducer?, response_type?) {
	const type = typeof params === 'string' ? params : params.type
	return {
		type: type,
		action: (...args) => ({
			type,
			...action(...args),
			need_auth_token: true,
		}),
		clearAction: () => ({
			type: CLEAR + type,
		}),
		reducer: reducer ?? reducerFunc(params),
		response_type: response_type ?? typeof params === 'string' ? params + RESPONSE : params.type + RESPONSE,

	}
}

export const bypassReducer = <T>(baseType: string, savePrevData: boolean = true, initialState = {}, clearType?: string[] | string) => (
	data = initialState, action: any): T => {
	const { type, response, error } = action;
	const prevData = savePrevData && data;

	switch (type) {
		case baseType + START:
			// @ts-ignore
			return { ...prevData, loading: true };
		case baseType + SUCCESS:
			return response;
		case baseType + FAIL:
			// @ts-ignore
			return {
				...prevData,
				loading: false,
				error,
			};
		case Array.isArray(clearType) ? clearType.find((e) => e === type) : clearType:
			return initialState as T
		default:
			return data as T
	}
}
type BaseType = {
	base: string
	START: `${BaseType['base']}_START'`
	FAIL: `${BaseType['base']}_FAIL'`
	SUCCESS: `${BaseType['base']}_SUCCESS'`
}
type Params<T> = {
	baseType: string
	// responseType: string
	initialState?: T
	savePrevData?: boolean
	clearType?: string[] | string
}

export const passReducer = <T>(params: Params<T>): ((state: T, action: any) => T) => {
	let newState = params.initialState ? { ...params.initialState } : {} as T
	return (state = newState, action: any): T => {
		const { type, response, error } = action;
		const prevData = params.savePrevData && state;

		switch (type) {
			case params.baseType + START:
				return { ...prevData, loading: true }
			case params.baseType + SUCCESS:
				return response;
			case params.baseType + FAIL:
				return {
					...prevData,
					loading: false,
					error,
				}
			case Array.isArray(params.clearType) ? params.clearType.find((e) => e === type) : params.clearType:
				return { ...params.initialState }
			default:
				return state
		}
	}
}

export function toolkitRedux<T>(params: Params<T>, action: (...args: any[]) => Action) {
	return {
		type: params.baseType,
		action: (...args) => ({
			type: params.baseType,
			...action(...args),
			need_auth_token: true,
		}),
		clearAction: () => ({
			type: CLEAR + params.baseType,
		}),
		reducer: passReducer<T>(params),
		// response_type: params.responseType,
	}
}

