import React from 'react'
import styled from 'styled-components'

const Pre = styled.pre`
  margin: 10px;
  color: #49a85e;
  padding: 10px;
  background: #2e312e;
  font-size: 14px;
  overflow-x: scroll;
`

interface Props {
	value: any
}

const Debug: React.FC<Props> = (props) => {
	const { value } = props
	if (!value) return null
	return (
		<Pre>
			🔥🔥🔥
		    {JSON.stringify(value, null, 2)}
		</Pre>
	)
}

export default Debug
