import { applyMiddleware, compose, createStore } from 'redux'

import notify from '../middlewares/notify'
import api from '../middlewares/api'
import apiDELETE from '../middlewares/apiDELETE'
import apiPOST from '../middlewares/apiPOST'
import authToken from '../middlewares/authToken'
import { reducers } from '../reducers'


// const sagaMiddleWareLast = createSagaMiddleware()

const composeEnhancers = typeof window === 'object'
&& (window.location.href.includes('localhost')
	|| window.location.href.includes('kosmoz')) // @ts-ignore
&& window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ // @ts-ignore
	? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
		trace: true, traceLimit: 25
	}) : compose

const middlwares = applyMiddleware(
	authToken,
	api,
	apiPOST,
	apiDELETE,
	notify,
	// sagaMiddleWareLast
)

const enhancer = composeEnhancers(middlwares)

const store = createStore(reducers, {}, enhancer);
// export type RootState = ReturnType<typeof reducers>
export type RootState = ReturnType<typeof reducers>

// sagaMiddleWareFirst.run(rootFirstSaga)
// sagaMiddleWareLast.run(rootLastSaga)
export default store