export default store => next => action => {
	const { need_auth_token } = action

	if (!need_auth_token) return next(action)


	const auth_token = getCookie('auth_token')

	//console.log(document.cookie)

	next({
		...action, token: auth_token
	})

}

// возвращает cookie с именем name, если есть, если нет, то undefined
export function getCookie(name) {
	var matches = document.cookie.match(new RegExp(
		'(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
	));
	return matches ? decodeURIComponent(matches[1]) : undefined;
}
