import { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { socket } from '../../index'
import { getAudienceInitialContacts } from '../../redux/ducks/customer'
import { setCounts, setMessageCount } from '../../redux/ducks/customer/audience'


export default (props) => {
	const { id, threadKey } = props

	const dispatch = useDispatch()


	useEffect(() => { // src/components/ChatView/Sidebar/index.tsx
		socket.on('private_message:contact-new-message', (res) => {
			console.log(res)
			dispatch(setCounts(res))
		})
	}, [])

	useEffect(() => {
		if (socket.hasListeners('private_message:new-external-message')) { // src/components/ChatView/index.tsx
			socket.off('private_message:new-external-message')
		}
		socket.on('private_message:new-external-message', ({ audience_id, user_id }) => {
			if (id == audience_id) {
				dispatch(getAudienceInitialContacts(audience_id))
			}
		})
	}, [id])

}
