import {
	getAudienceStartWord,
	getAudienceTags,
	getRegularTypeDay,
	moduleName, rebuildStartWords,
	setRegularDay,
	setRegularTime,
	setRegularType,
	setRegularTypeDay
} from './index'
import {
	ADD_AUDIENCE_RESPONSE,
	addAudienceReducer,
	CREATE_CHANNEL_FOR_AUDIENCE_RESPONSE,
	createChannelForAudienceReducer,
	DELETE_AUDIENCE_RESPONSE,
	deleteAudienceReducer,
	GET_AUDIENCE_CHANNELS_RESPONSE,
	getAudienceChannelsReducer,
	CHAT,
	chatReducer,
	UPDATE_AUDIENCE_RESPONSE,
	updateAudienceReducer,
	LEFT_MENU, leftMenuReducer,
	GET_ARCHIVE_CHANNELS_RESPONSE, getArchiveChannelsReducer,
	GET_AUDIENCE_CONTACTS_SEARCH_RESPONSE, searchAudienceContactsReducer,
	changeAudienceSoundNotification,
	changeAudienceSoundTimeNotification,
	changeAudienceFaviconNotification,
	setAudienceViewAllMessages,
	setUpdateContacts,
	setMessageAsRead,
	audienceContactsReducer, AUDIENCE_INITIAL_CONTACTS_RESPONSE
} from './audience'
import {
	DELETE_CHANNEL_RESPONSE, deleteChannelReducer,
	UPDATE_CHANNEL_RESPONSE, updateChannelReducer,
	ADD_CHANNEL_KEY_RESPONSE, addChannelKeyReducer,
	IMPORT_CONTACTS_RESPONSE, importContactsReducer,
	UPLOAD_OLD_VK_CONTACTS_RESPONSE, uploadOldVKContactsReducer,
	addCallbackUrl,
} from './channel'
import {
	GET_AUDIENCE_FUNNEL_RESPONSE, getAudienceFunnelReducer,
	GET_AUDIENCE_FUNNELS_RESPONSE, getAudienceFunnelsReducer,
	CREATE_AUDIENCE_FUNNEL_RESPONSE, createAudienceFunnelReducer,
	UPDATE_AUDIENCE_FUNNEL_RESPONSE, updateAudienceFunnelReducer,
	SAVE_AUDIENCE_FUNNEL_RESPONSE, saveAudienceFunnelReducer,
	GET_FUNNEL_ACTIONS_RESPONSE, getFunnelActionsReducer,
	PERFORM_ACTION_RESPONSE, performActionReducer,
	GET_AUDIENCE_FUNNELS_TAGS_RESPONSE, getAudienceFunnelsTagsReducer,
	ADD_FUNNEL_STOP_TAG_RESPONSE, addFunnelStopTagReducer,
	ADD_FUNNEL_TAG_RESPONSE, addFunnelTagReducer,
	CHECK_IS_NEW_RESPONSE, checkIsNewReducer,
	CHANGE_FUNNEL_NAME_RESPONSE, changeFunnelNameReducer,
	START_FUNNEL_RESPONSE, startFunnelReducer,
	GET_AUDIENCE_FUNNEL_ADDITIONAL_RESPONSE, getAudienceFunnelAdditionalReducer,
	CHECK_IS_REPEAT_RESPONSE, checkIsRepeatReducer,
	copyFunnel, deleteRequestByContract, getRegularType
} from './funnel'
import {
	UNBLOCK_USER_RESPONSE, unblockUserReducer,
	BLOCK_USER_RESPONSE, blockUserReducer,
	GET_CUSTOM_FIELDS_RESPONSE, getCustomFieldsReducer,
	EDIT_CONTACT_NAME_RESPONSE, editContactNameReducer,
	addContactToTest,
	getContactToTest,
	sendToMailingTestContact,
	sendToAudienceTestContact,
} from './contacts'

import {
	GET_AUDIENCE_KEYWORDS_RESPONSE,
	getAudienceKeywordsReducer,
	ADD_AUDIENCE_KEYWORDS_RESPONSE,
	addAudienceKeywordsReducer,
	UPDATE_AUDIENCE_KEYWORDS_RESPONSE,
	updateAudienceKeywordsReducer,
	DELETE_AUDIENCE_KEYWORDS_RESPONSE,
	deleteAudienceKeywordsReducer,
} from './keywords'

import {
	GET_TAG_LIST_RESPONSE,
	getTagListReducer,
	CREATE_TAG_FOR_AUDIENCE_RESPONSE,
	createTagForAudienceReducer,
	DELETE_TAG_RESPONSE,
	deleteTagReducer,
	UPDATE_TAG_RESPONSE,
	updateTagReducer,
	GET_CONTACT_TAGS_LIST_RESPONSE,
	getContactTagsReducer,
	GET_POSSIBLE_CONTACT_TAGS_LIST_RESPONSE,
	getPossibleContactTagsReducer,
	ADD_TAG_FOR_CONTACT_RESPONSE,
	addTagForContactReducer,
	DELETE_TAG_FOR_CONTACT_RESPONSE,
	deleteTagForContactReducer,
} from './tag'


import {
	GET_LANDING_RESPONSE, getLandingReducer,
	CHANGE_FIELD_RESPONSE, changeFieldReducer,
	GET_LANDINGS_RESPONSE, getLandingsReducer,
	ADD_LANDING_RESPONSE, addLandingReducer,
	ADD_LANDING_YANDEX_TARGET_RESPONSE, addLandingYMTargetReducer,
	GET_TAGS_FOR_LANDINGS_RESPONSE, getTagsForLandingReducer,
	ADD_TAGS_FOR_LANDINGS_RESPONSE, addTagsForLandingReducer,
	CHANGE_CHANNEL_NAME_RESPONSE, changeChannelNameReducer,
	GET_CHANNELS_FOR_LANDINGS_RESPONSE, getChannelsForLandingReducer,
	getLandingOtherPage,
	copyMiniLanding,
	copyVKMiniLanding,
} from './landing'
import {
	addLandingVK,
	addLandingVKYandexTarget,
	addTagsForVKLandings,
	changeChannelNameForVKLanding,
	changeVKLandingField,
	getChannelsForVKLandings,
	getLandingsVK,
	getLandingVK,
	getTagsForVKLandings,
	getVKLandingOtherPage,
	saveVKAuth,
	setDefaultVKLanding,
	setRedirectToMessage,
} from './landing-vk'

import {
	GET_CONTACT_NOTES_RESPONSE, getContactNotesReducer,
	ADD_CONTACT_NOTES_RESPONSE, addContactNotesReducer,
	UPDATE_CONTACT_NOTES_RESPONSE, updateContactNotesReducer,
	DELETE_CONTACT_NOTES_RESPONSE, deleteContactNotesReducer,
} from './note'
import { GET_MAIN_STAT_RESPONSE, mainStatReducer } from './dashboard'
import {
	GET_AUDIENCE_STATISTIC_RESPONSE,
	getAudienceStatisticReducer,
	GET_AUDIENCE_STATISTIC_FOR_MINILANDINGS_RESPONSE,
	getAudienceStatisticForMiniLandingsReducer,
	GET_AUDIENCE_STATISTIC_FOR_MINILANDINGS_CHANNEL_FILTER_RESPONSE,
	getAudienceStatisticForMiniLandingsChannelFilterReducer,
	GET_AUDIENCE_STATISTIC_FOR_MINILANDINGS_LANDING_FILTER_RESPONSE,
	getAudienceStatisticForMiniLandingsLandingFilterReducer,
} from './statistic'

import {
	getAudienceColor,
	changeAudienceParam,
	getActiveAudienceColor,
	changeAudienceActiveColor,
} from './colors'
import {
	saveUtmParams,
} from './utm'

export default {
	[moduleName + ADD_AUDIENCE_RESPONSE]: addAudienceReducer,
	[moduleName + DELETE_AUDIENCE_RESPONSE]: deleteAudienceReducer,
	[moduleName + UPDATE_AUDIENCE_RESPONSE]: updateAudienceReducer,
	[moduleName + CREATE_CHANNEL_FOR_AUDIENCE_RESPONSE]: createChannelForAudienceReducer,
	[moduleName + GET_AUDIENCE_CHANNELS_RESPONSE]: getAudienceChannelsReducer,
	[moduleName + DELETE_CHANNEL_RESPONSE]: deleteChannelReducer,
	[moduleName + UPDATE_CHANNEL_RESPONSE]: updateChannelReducer,
	[moduleName + ADD_CHANNEL_KEY_RESPONSE]: addChannelKeyReducer,
	[moduleName + CHAT]: chatReducer,
	[moduleName + GET_TAG_LIST_RESPONSE]: getTagListReducer,
	[moduleName + CREATE_TAG_FOR_AUDIENCE_RESPONSE]: createTagForAudienceReducer,
	[moduleName + DELETE_TAG_RESPONSE]: deleteTagReducer,
	[moduleName + UPDATE_TAG_RESPONSE]: updateTagReducer,
	[moduleName + GET_CONTACT_TAGS_LIST_RESPONSE]: getContactTagsReducer,
	[moduleName + GET_POSSIBLE_CONTACT_TAGS_LIST_RESPONSE]: getPossibleContactTagsReducer,
	[moduleName + ADD_TAG_FOR_CONTACT_RESPONSE]: addTagForContactReducer,
	[moduleName + DELETE_TAG_FOR_CONTACT_RESPONSE]: deleteTagForContactReducer,
	[moduleName + GET_AUDIENCE_KEYWORDS_RESPONSE]: getAudienceKeywordsReducer,
	[moduleName + ADD_AUDIENCE_KEYWORDS_RESPONSE]: addAudienceKeywordsReducer,
	[moduleName + UPDATE_AUDIENCE_KEYWORDS_RESPONSE]: updateAudienceKeywordsReducer,
	[moduleName + DELETE_AUDIENCE_KEYWORDS_RESPONSE]: deleteAudienceKeywordsReducer,
	[moduleName + GET_LANDING_RESPONSE]: getLandingReducer,
	[moduleName + CHANGE_FIELD_RESPONSE]: changeFieldReducer,
	[moduleName + GET_AUDIENCE_FUNNEL_RESPONSE]: getAudienceFunnelReducer,
	[moduleName + GET_AUDIENCE_FUNNELS_RESPONSE]: getAudienceFunnelsReducer,
	[moduleName + CREATE_AUDIENCE_FUNNEL_RESPONSE]: createAudienceFunnelReducer,
	[moduleName + UPDATE_AUDIENCE_FUNNEL_RESPONSE]: updateAudienceFunnelReducer,
	[moduleName + SAVE_AUDIENCE_FUNNEL_RESPONSE]: saveAudienceFunnelReducer,
	[moduleName + GET_FUNNEL_ACTIONS_RESPONSE]: getFunnelActionsReducer,
	[moduleName + GET_LANDINGS_RESPONSE]: getLandingsReducer,
	[moduleName + ADD_LANDING_RESPONSE]: addLandingReducer,
	[moduleName + PERFORM_ACTION_RESPONSE]: performActionReducer,
	[moduleName + LEFT_MENU]: leftMenuReducer,
	[moduleName + ADD_LANDING_YANDEX_TARGET_RESPONSE]: addLandingYMTargetReducer,
	[moduleName + UNBLOCK_USER_RESPONSE]: unblockUserReducer,
	[moduleName + BLOCK_USER_RESPONSE]: blockUserReducer,
	[moduleName + GET_CUSTOM_FIELDS_RESPONSE]: getCustomFieldsReducer,
	[moduleName + GET_TAGS_FOR_LANDINGS_RESPONSE]: getTagsForLandingReducer,
	[moduleName + ADD_TAGS_FOR_LANDINGS_RESPONSE]: addTagsForLandingReducer,
	[moduleName + GET_ARCHIVE_CHANNELS_RESPONSE]: getArchiveChannelsReducer,
	[moduleName + IMPORT_CONTACTS_RESPONSE]: importContactsReducer,
	[moduleName + GET_MAIN_STAT_RESPONSE]: mainStatReducer,
	[moduleName + GET_AUDIENCE_FUNNELS_TAGS_RESPONSE]: getAudienceFunnelsTagsReducer,
	[moduleName + ADD_FUNNEL_STOP_TAG_RESPONSE]: addFunnelStopTagReducer,
	[moduleName + ADD_FUNNEL_TAG_RESPONSE]: addFunnelTagReducer,
	[moduleName + CHECK_IS_NEW_RESPONSE]: checkIsNewReducer,
	[moduleName + CHANGE_FUNNEL_NAME_RESPONSE]: changeFunnelNameReducer,
	[moduleName + START_FUNNEL_RESPONSE]: startFunnelReducer,
	[moduleName + GET_AUDIENCE_FUNNEL_ADDITIONAL_RESPONSE]: getAudienceFunnelAdditionalReducer,
	[moduleName + CHECK_IS_REPEAT_RESPONSE]: checkIsRepeatReducer,
	[moduleName + CHANGE_CHANNEL_NAME_RESPONSE]: changeChannelNameReducer,
	[moduleName + GET_CHANNELS_FOR_LANDINGS_RESPONSE]: getChannelsForLandingReducer,
	[moduleName + EDIT_CONTACT_NAME_RESPONSE]: editContactNameReducer,
	[moduleName + GET_CONTACT_NOTES_RESPONSE]: getContactNotesReducer,
	[moduleName + ADD_CONTACT_NOTES_RESPONSE]: addContactNotesReducer,
	[moduleName + UPDATE_CONTACT_NOTES_RESPONSE]: updateContactNotesReducer,
	[moduleName + DELETE_CONTACT_NOTES_RESPONSE]: deleteContactNotesReducer,
	[moduleName + GET_AUDIENCE_CONTACTS_SEARCH_RESPONSE]: searchAudienceContactsReducer,
	[moduleName + GET_AUDIENCE_STATISTIC_RESPONSE]: getAudienceStatisticReducer,
	[moduleName + UPLOAD_OLD_VK_CONTACTS_RESPONSE]: uploadOldVKContactsReducer,
	[moduleName + GET_AUDIENCE_STATISTIC_FOR_MINILANDINGS_RESPONSE]: getAudienceStatisticForMiniLandingsReducer,
	[moduleName + GET_AUDIENCE_STATISTIC_FOR_MINILANDINGS_CHANNEL_FILTER_RESPONSE]: getAudienceStatisticForMiniLandingsChannelFilterReducer,
	[moduleName + GET_AUDIENCE_STATISTIC_FOR_MINILANDINGS_LANDING_FILTER_RESPONSE]: getAudienceStatisticForMiniLandingsLandingFilterReducer,
	[moduleName + AUDIENCE_INITIAL_CONTACTS_RESPONSE]: audienceContactsReducer,
	[moduleName + getAudienceColor.response_type]: getAudienceColor.reducer,
	[moduleName + changeAudienceParam.response_type]: changeAudienceParam.reducer,
	[moduleName + getActiveAudienceColor.response_type]: getActiveAudienceColor.reducer,
	[moduleName + changeAudienceActiveColor.response_type]: changeAudienceActiveColor.reducer,
	[moduleName + changeAudienceSoundNotification.response_type]: changeAudienceSoundNotification.reducer,
	[moduleName + changeAudienceSoundTimeNotification.response_type]: changeAudienceSoundTimeNotification.reducer,
	[moduleName + setAudienceViewAllMessages.response_type]: setAudienceViewAllMessages.reducer,
	[moduleName + changeAudienceFaviconNotification.response_type]: changeAudienceFaviconNotification.reducer,
	[moduleName + getLandingOtherPage.response_type]: getLandingOtherPage.reducer,
	[moduleName + saveUtmParams.response_type]: saveUtmParams.reducer,
	[moduleName + addLandingVK.response_type]: addLandingVK.reducer,
	[moduleName + addLandingVKYandexTarget.response_type]: addLandingVKYandexTarget.reducer,
	[moduleName + addTagsForVKLandings.response_type]: addTagsForVKLandings.reducer,
	[moduleName + changeChannelNameForVKLanding.response_type]: changeChannelNameForVKLanding.reducer,
	[moduleName + changeVKLandingField.response_type]: changeVKLandingField.reducer,
	[moduleName + getChannelsForVKLandings.response_type]: getChannelsForVKLandings.reducer,
	[moduleName + getLandingsVK.response_type]: getLandingsVK.reducer,
	[moduleName + getLandingVK.response_type]: getLandingVK.reducer,
	[moduleName + getTagsForVKLandings.response_type]: getTagsForVKLandings.reducer,
	[moduleName + getVKLandingOtherPage.response_type]: getVKLandingOtherPage.reducer,
	[moduleName + saveVKAuth.response_type]: saveVKAuth.reducer,
	[moduleName + setDefaultVKLanding.response_type]: setDefaultVKLanding.reducer,
	[moduleName + setRedirectToMessage.response_type]: setRedirectToMessage.reducer,
	[moduleName + addCallbackUrl.response_type]: addCallbackUrl.reducer,
	[moduleName + setMessageAsRead.response_type]: setMessageAsRead.reducer,
	[moduleName + addContactToTest.response_type]: addContactToTest.reducer,
	[moduleName + getContactToTest.response_type]: getContactToTest.reducer,
	[moduleName + sendToMailingTestContact.response_type]: sendToMailingTestContact.reducer,
	[moduleName + sendToAudienceTestContact.response_type]: sendToAudienceTestContact.reducer,
	[moduleName + copyMiniLanding.response_type]: copyMiniLanding.reducer,
	[moduleName + copyVKMiniLanding.response_type]: copyVKMiniLanding.reducer,
	[moduleName + copyFunnel.response_type]: copyFunnel.reducer,
	[moduleName + deleteRequestByContract.response_type]: deleteRequestByContract.reducer,
	[moduleName + setUpdateContacts.response_type]: setUpdateContacts.reducer,
	[moduleName + getRegularType.response_type]: getRegularType.reducer,
	[moduleName + setRegularType.response_type]: setRegularType.reducer,
	[moduleName + getRegularTypeDay.response_type]: getRegularTypeDay.reducer,
	[moduleName + setRegularTypeDay.response_type]: setRegularTypeDay.reducer,
	[moduleName + setRegularDay.response_type]: setRegularDay.reducer,
	[moduleName + setRegularTime.response_type]: setRegularTime.reducer,
	[moduleName + getAudienceTags.response_type]: getAudienceTags.reducer,
	[moduleName + getAudienceStartWord.response_type]: getAudienceStartWord.reducer,
	[moduleName + rebuildStartWords.response_type]: rebuildStartWords.reducer,
}
