import { URL } from '../../../constants'
import { urlBuild } from '../../../helpers'
import { reduxToolkit } from '../../../helpers/ReduxHelper'
import { ID, CR } from '../../../types'


export const getLandingsVK = reduxToolkit('GET_VK_LANDINGS',
	(id: ID) => ({
		call: `${URL}/v2/customer/audience-vk/${id}/landings`,
	}))


export const getLandingVK = reduxToolkit('GET_VK_LANDING',
	(id: ID) => ({
		call: URL + `/v2/customer/mini-landing-vk/${id}/view`,
	}))

export const addLandingVK = reduxToolkit('ADD_VK_LANDING',
	(id: ID) => ({
		callPOST: URL + `/v2/customer/mini-landing-vk/${id}/create`,
		payload: { id },
	}))

export const setDefaultVKLanding = reduxToolkit('SET_DEFAULT_VK_LANDING',
	(id: ID, is_default: number) => ({
		callPOST: URL + `/v2/customer/mini-landing-vk/${id}/vk-default`,
		payload: { id, is_default },
		queryData: { is_default },
	}))

export const setRedirectToMessage = reduxToolkit('SET_DEFAULT_VK_LANDING',
	(id: ID, redirect_to_message: number) => ({
		callPOST: URL + `/v2/customer/mini-landing-vk/${id}/redirect-to-message`,
		payload: { id, redirect_to_message },
		queryData: { redirect_to_message },
	}))

export const saveVKAuth = reduxToolkit('SAVE_VK_AUTH',
	(params) => ({
		callPOST: URL + `/v2/customer/vk/key`,
		payload: params,
		queryData: params,
	}))


export const addLandingVKYandexTarget = reduxToolkit({
		type: 'ADD_LANDING_VK_YANDEX_TARGET',

	},
	(id: ID, yandex_target) => ({
		callPOST: URL + `/v2/customer/mini-landing/${id}/yandex-target`,
		payload: { id, yandex_target },
		queryData: { yandex_target },
	}))


export const changeVKLandingField = reduxToolkit('CHANGE_VK_LANDING_FIELD',
	(id: ID, name: string, param: any, queryName?: string) => ({
		callPOST: URL + `/v2/customer/mini-landing-vk/${id}/${name}`,
		payload: { name, param },
		queryData: { [queryName ?? name]: param },
	}))

export const getTagsForVKLandings = reduxToolkit('GET_TAGS_FOR_VK_LANDINGS',
	(id: ID) => ({
		call: URL + `/v2/customer/mini-landing-vk/${id}/tags`,
		payload: { id },
	}))


export const getChannelsForVKLandings = reduxToolkit('GET_CHANNELS_FOR_VK_LANDINGS',
	(id: ID) => ({
		call: URL + `/v2/customer/mini-landing-vk/${id}/view-channel`,
		payload: { id },
	}))


export const addTagsForVKLandings = reduxToolkit('ADD_TAGS_FOR_VK_LANDINGS',
	(id: ID, tags: string) => ({
		callPOST: URL + `/v2/customer/mini-landing-vk/${id}/tags`,
		payload: { tags },
		queryData: { tags },
	}))


export const changeChannelNameForVKLanding = reduxToolkit('CHANGE_CHANNEL_NAME_FOR_VK_LANDING',
	(id: ID, params: { name: string, channel_id: ID }) => ({
		callPOST: URL + `/v2/customer/mini-landing-vk/${id}/channel-name`,
		payload: params,
		queryData: params,
	}))


export type AddLandingOtherType = CR<{
	html: string
	id: number
	local: string
	url: string
}>

export const getVKLandingOtherPage = reduxToolkit('GET_VK_LANDING_OTHER_PAGE',
	(url: string, local: string) => ({
		call: `${URL}/v2/common/landing-other/${url}?local=${local}`,
	}))

