import { URL } from '../../../constants'
import { bypassReducer, reduxToolkit } from '../../../helpers/ReduxHelper'
import { urlBuild } from '../../../helpers/stringHelpers'
import { Action, CR } from '../../../types'
import { Bill, OperationsType, PaymentsType } from './types'

const GET_BILL = 'GET_BILL'
export function getBill(): Action {
	return {
		type: GET_BILL,
		call: URL + `/v2/common/billing/bill`,
		need_auth_token: true,
	}
}
export const GET_BILL_RESPONSE = 'GET_BILL_RESPONSE'
export const getBillReducer = bypassReducer<CR<Bill>>(GET_BILL, true, {})


const GET_BILLING_OPERATION = 'GET_BILLING_OPERATION'
/**
 * @apiParam {Array} [type] Тип операции
 * @apiParam {Integer} [limit]  Лимит на кол-во задач, по дефолту 20
 * @apiParam {Integer} [offset] Страница пагинации, первая страница не отправляется, начиная со второй отправляем offset=2 и тд
 * @apiParam {String} [order_by="created_at"] По какому полю сортировать
 * @apiParam {String} [order="SORT_DESC"] Направление сортировки (SORT_ASC, SORT_DESC)
 * @returns {Action}
 */
export function getBillingOperation(params?): Action {
	const query = urlBuild(params)
	return {
		type: GET_BILLING_OPERATION,
		call: URL + `/v2/common/billing/operations${query}`,
		need_auth_token: true,
	}
}
export const GET_BILLING_OPERATION_RESPONSE = 'GET_BILLING_OPERATION_RESPONSE'
export const getBillingOperationReducer = bypassReducer<CR<OperationsType>>(GET_BILLING_OPERATION, true, {})


const GET_BALANCE = 'GET_BALANCE'
export function getBalance(): Action {
	return {
		type: GET_BALANCE,
		call: URL + `/v2/common/billing/balance`,
		need_auth_token: true,
	}
}
export const GET_BALANCE_RESPONSE = 'GET_BALANCE_RESPONSE'
export const getBalanceReducer = bypassReducer<CR<any>>(GET_BALANCE, true, {})


const GET_FILTER_OPERATIONS = 'GET_FILTER_OPERATIONS'
export function getFilterOperations(params?): Action {
	const query = urlBuild(params)
	return {
		type: GET_FILTER_OPERATIONS,
		call: URL + `/v2/common/billing/filter/operations${query}`,
		need_auth_token: true,
	}
}
export const GET_FILTER_OPERATIONS_RESPONSE = 'GET_FILTER_OPERATIONS_RESPONSE'
export const getFilterOperationsReducer = bypassReducer<CR<any>>(GET_FILTER_OPERATIONS, true, {})



const GET_UP_REFILL_FORM = 'GET_UP_REFILL_FORM'
export function getUpRefillForm(): Action {
	return {
		type: GET_UP_REFILL_FORM,
		call: URL + `/v2/common/billing/up-refill-form`,
		need_auth_token: true,
	}
}
export const GET_UP_REFILL_FORM_RESPONSE = 'GET_UP_REFILL_FORM_RESPONSE'
export const getUpRefillFormReducer = bypassReducer<CR<any>>(GET_UP_REFILL_FORM, true, {})


export const getPaymentsOperations = reduxToolkit('GET_PAYMENTS',
	(id: number) => ({ call: `${URL}/v2/common/billing/payments` }))


export const getPaymentsOperationsFilter = reduxToolkit('GET_PAYMENTS_FILTER',
	(id: number) => ({ call: `${URL}/v2/common/billing/payments/filter` }))


export const getBonusOperations = reduxToolkit('GET_BONUS_OPERATIONS',
	(id: number) => ({ call: `${URL}/v2/common/billing/operations-bonus` }))
