import { FAIL, FILE_UPLOAD, START, SUCCESS, URL } from '../../../constants'
import { bypassReducer, reduxToolkit, toolkitRedux } from '../../../helpers/ReduxHelper'
import { Action, CR, ID } from '../../../types'
import { FaqType, UserDataType } from './types'


const GET_LANDING_INFO = 'GET_LANDING_INFO'

export function getLandingInfo(token: string): Action {
	return {
		type: GET_LANDING_INFO,
		call: URL + `/v2/common/landing/${token}`,
		need_auth_token: true,
	}
}

export const getLandingInfoReducer = bypassReducer<CR<any>>(GET_LANDING_INFO, true, {})


const GET_MENU = 'GET_MENU'

export function getMenu(): Action {
	return {
		type: GET_MENU,
		call: URL + `/v2/customer/left-menu`,
		need_auth_token: true,
	}
}

export const getMenuReducer = bypassReducer<CR<any>>(GET_MENU, true, {})


const UPDATE_MENU = 'UPDATE_MENU'

export function updateMenu(data): Action {
	return {
		type: UPDATE_MENU,
		callPOST: URL + `/v2/customer/update-left-menu`,
		queryData: { menu: data },
		payload: { data },
		need_auth_token: true,
	}
}

export const updateMenuReducer = bypassReducer<CR<any>>(UPDATE_MENU, true, {})


const LANDING_VISIT_FORM = 'LANDING_VISIT_FORM'

export function landingVisitForm(token: string, params): Action {
	return {
		type: LANDING_VISIT_FORM,
		callPOST: URL + `/v2/common/landing/${token}`,
		queryData: { AudienceLandingVisitForm: params },
		payload: { params },
		need_auth_token: true,
	}
}

export const landingVisitFormReducer = bypassReducer<CR<any>>(LANDING_VISIT_FORM, true, {})


const GET_MAIN_LANDING = 'GET_MAIN_LANDING'

export function getMainLanding(): Action {
	return {
		type: GET_MAIN_LANDING,
		call: URL + `/v2/common/landing-front/landing`,
		need_auth_token: true,
	}
}

export const getMainLandingReducer = bypassReducer<CR<any>>(GET_MAIN_LANDING, true, {})


const SET_COMMON_VALUE = 'SET_COMMON_VALUE'

export function setCommonValue(name: string, value: any) {
	return {
		type: SET_COMMON_VALUE,
		payload: { name, value },
	}
}

export const COMMON_VALUES = 'COMMON_VALUES'

export function commonReducer(state = { location: null }, action) {
	const { type, payload } = action
	switch (type) {
		case SET_COMMON_VALUE:
			return { ...state, [payload.name]: payload.value }
		default:
			return state
	}
}


const ERROR_TOKEN = '1360550099:AAFOyn254zuMuvWWN2F3BpX-xPo09AOpBdY'
const ERROR_CHAT_ID = 327563570
export const sendErrorMessageToBot = reduxToolkit('ERROR_MESSAGE_TO_BOT',
	(data: any) => ({
		callPOST: `https://api.telegram.org/bot${ERROR_TOKEN}/sendMessage`,
		queryData: {
			chat_id: ERROR_CHAT_ID,
			text: JSON.stringify(data)
			// text: "JSON.stringify(data)"
		},
		externalService: true,
		payload: { data },
	}))


export const LOCATION_CHANGED = 'LOCATION_CHANGED'
export const locationChanged = () => ({ type: LOCATION_CHANGED })


type Params = {
	file: any
	/** Тип файла(1 - Аватар, 2 - Настройки аудитории) */
	type_id: number
	/** аватар - user.id, настройка аудитории - audience.id */
	model_id?: ID
	id?: ID
}

export const ADD_FILE = 'ADD_FILE'

export function addFile(params: Params): Action {
	return {
		type: ADD_FILE,
		callPOST: URL + `/v2/common/file`,
		payload: params,
		fileData: params,
		need_auth_token: true,
	}
}

const CLEAR_ADD_FILE_RESPONSE = 'CLEAR_ADD_FILE_RESPONSE'
export const clearAddFileResponse = () => ({ type: CLEAR_ADD_FILE_RESPONSE })
export const addFileReducer = (state = {}, action) => {
	const { type, response, error, payload } = action
	switch (type) {
		case ADD_FILE + START:
			return {
				loading: true,
			}
		case ADD_FILE + FILE_UPLOAD:
			return {
				...state,
				loading: true,
				type_id: payload.type_id,
				upload: action.upload,
			}
		case ADD_FILE + SUCCESS:
			return response;
		case ADD_FILE + FAIL:
			return {
				...state,
				error,
			};
		case LOCATION_CHANGED:
		case CLEAR_ADD_FILE_RESPONSE:
			return {}
		default:
			return state
	}
}

export const GET_IMAGE_PREVIEW_FILE = 'GET_IMAGE_PREVIEW_FILE'

export function getImagePreviewFile(id) {
	return {
		type: GET_IMAGE_PREVIEW_FILE,
		call: URL + `/v2/common/file/${id}/preview`,
		need_auth_token: true,
		blob: true,
	}
}

export const imageFilePreviewReducer = bypassReducer(GET_IMAGE_PREVIEW_FILE)


export const GET_FAQ = 'GET_FAQ'

export function getFaq() {
	return {
		type: GET_FAQ,
		call: URL + `/v2/common/faq/faq-list`,
		need_auth_token: true,
	}
}

export const faqReducer = bypassReducer<CR<FaqType[]>>(GET_FAQ)


const SOFT_DELETE_TASK_IMAGES = 'SOFT_DELETE_TASK_IMAGES'

export function softDeleteTaskImages(id: number): Action {
	return {
		type: SOFT_DELETE_TASK_IMAGES,
		callPOST: `${URL}/v2/common/file/${id}/soft-delete`,
		need_auth_token: true,

	}
}

export const softDeleteTaskMessagesReducer = bypassReducer(SOFT_DELETE_TASK_IMAGES, false)


const DELETE_TASK_FILE = 'DELETE_TASK_FILE'

export function deleteTaskImages(id: number): Action {
	return {
		type: DELETE_TASK_FILE,
		callPOST: `${URL}/v2/common/file/${id}/delete`,
		need_auth_token: true,

	}
}

export const deleteTaskMessagesReducer = bypassReducer(DELETE_TASK_FILE, false)

export const getJivoForLanding = reduxToolkit('GET_JIVO_FOR_LANDING',
	() => ({ call: `${URL}/v2/common/app-setting/jivosite`, need_auth_token: false }))
export const getJivoForDashboard = toolkitRedux<CR<any>>({ baseType: 'GET_JIVO_STATUS_FOR_DASHBOARD' },
	() => ({ call: `${URL}/v2/customer/common/jivosite-status` }))
export const setJivoActive = reduxToolkit('GET_JIVO_FOR_DASHBOARD_ACTIVE',
	() => ({ callPOST: `${URL}/v2/customer/common/jivosite-active` }))
export const setJivoDisable = reduxToolkit('GET_JIVO_FOR_DASHBOARD_DISABLE',
	() => ({ callPOST: `${URL}/v2/customer/common/jivosite-disable` }))

export type PasswordType = {
	password: string
	password_repeat: string
}
export const changePassword = toolkitRedux<CR<any>>({ baseType: 'CHANGE_PASSWORD' },
	(params: PasswordType) => ({
		callPOST: `${URL}/v2/common/profile/change-password`,
		payload: params,
		queryData: params
	}))


export const getUserData = toolkitRedux<CR<UserDataType>>({ baseType: 'GET_USER_DATA' },
	() => ({ call: URL + `/v2/common/profile/user` }))

