import {
	moduleName,
	CHECK_LOGIN_RESPONSE, checkLoginReducer,
	REGISTER_USER_RESPONSE, registerUserReducer,
	CHECK_EMAIL_RESPONSE, checkEmailReducer,
	LOGIN_USER_RESPONSE, loginUserReducer,
	LOGOUT_USER_RESPONSE, logoutUserReducer,
	CHECK_TOKEN_RESPONSE, checkTokenReducer,
	CHANGE_PASSWORD_RESPONSE, changePasswordReducer,
	PASSWORD_RESET_REQUEST_RESPONSE, resetPasswordRequestReducer,
	CONFIRM_EMAIL_RESPONSE, confirmEmailReducer,
	SEND_EMAIL_RESPONSE, sendEmailReducer,
} from '../index'


export default {
	[moduleName + CHECK_LOGIN_RESPONSE]: checkLoginReducer,
	[moduleName + REGISTER_USER_RESPONSE]: registerUserReducer,
	[moduleName + CHECK_EMAIL_RESPONSE]: checkEmailReducer,
	[moduleName + LOGIN_USER_RESPONSE]: loginUserReducer,
	[moduleName + LOGOUT_USER_RESPONSE]: logoutUserReducer,
	[moduleName + CHECK_TOKEN_RESPONSE]: checkTokenReducer,
	[moduleName + CHANGE_PASSWORD_RESPONSE]: changePasswordReducer,
	[moduleName + PASSWORD_RESET_REQUEST_RESPONSE]: resetPasswordRequestReducer,
	[moduleName + CONFIRM_EMAIL_RESPONSE]: confirmEmailReducer,
	[moduleName + SEND_EMAIL_RESPONSE]: sendEmailReducer,

}