import produce from 'immer'
import { URL, SUCCESS, START } from '../../../constants'
import { bypassReducer, reduxToolkit } from '../../../helpers/ReduxHelper'
import { urlBuild } from '../../../helpers/stringHelpers'
import { Action, CR, ID } from '../../../types'
import { LOCATION_CHANGED } from '../common'
import { NewsLetterType, SingleNewsLetterType } from './types'

export const moduleName = 'mailing'


const GET_ALL_MAILING = 'GET_ALL_MAILING'
export function getAllMailing(id, status_id?: number): Action {
	const query = urlBuild({ status_id })
	return {
		type: GET_ALL_MAILING,
		call: URL + `/v2/customer/mailing/${id}/list${query}`,
		need_auth_token: true,
	}
}
export const GET_ALL_MAILING_RESPONSE = 'GET_ALL_MAILING_RESPONSE'
export const getAllMailingReducer = bypassReducer<CR<NewsLetterType[]>>(GET_ALL_MAILING, true, {})


const GET_MAILING_COUNT = 'GET_MAILING_COUNT'
export function getMailingCount(id, params): Action {
	const query = urlBuild(params)
	return {
		type: GET_MAILING_COUNT,
		call: URL + `/v2/customer/mailing/${id}/count${query}`,
		need_auth_token: true,
	}
}
export const GET_MAILING_COUNT_RESPONSE = 'GET_MAILING_COUNT_RESPONSE'
export const getMailingCountReducer = bypassReducer<CR<NewsLetterType[]>>(GET_MAILING_COUNT, true, {})


const GET_MAILING = 'GET_MAILING'
/**
 * получить список рассылок
 */
export function getMailing(id: ID): Action {
	return {
		type: GET_MAILING,
		call: URL + `/v2/customer/mailing/${id}/view`,
		need_auth_token: true,
	}
}
export const GET_MAILING_RESPONSE = 'GET_MAILING_RESPONSE'
export const getMailingReducer = bypassReducer<CR<SingleNewsLetterType>>(GET_MAILING, true, {}, [LOCATION_CHANGED])


const GET_AUDIENCE_TAGS = 'GET_AUDIENCE_TAGS'
export function getAudienceTags(id: ID): Action {
	return {
		type: GET_AUDIENCE_TAGS,
		call: URL + `/v2/customer/audience/${id}/tags`,
		need_auth_token: true,
	}
}
export const GET_AUDIENCE_TAGS_RESPONSE = 'GET_AUDIENCE_TAGS_RESPONSE'
export const audienceTagsReducer = bypassReducer<CR<SingleNewsLetterType>>(GET_AUDIENCE_TAGS, true, {}, [LOCATION_CHANGED])


const GET_MAILING_ACTIONS = 'GET_MAILING_ACTIONS'
export function getMailingActions(id: string): Action {
	return {
		type: GET_MAILING_ACTIONS,
		call: URL + `/v2/customer/mailing/${id}/actions`,
		need_auth_token: true,
		payload: { id },
	}
}
export const GET_MAILING_ACTIONS_RESPONSE = 'GET_MAILING_ACTIONS_RESPONSE'
export const getMailingActionsReducer = produce((draft, action) => {
	const { type, payload, response } = action
	switch (type) {
		case GET_MAILING_ACTIONS + SUCCESS:
			draft[payload.id] = response.data
			return
		case SENDS_MAILING + START:
		case DELETE_MAILING + START:
		case UPDATE_MAILING + START:
		case FINISH_MAILING + START:
			return {}
		default:
			return draft
	}
}, {})

const GET_MAILING_LOGS = 'GET_MAILING_LOGS'
export function getMailingLogs(id: string): Action {
	return {
		type: GET_MAILING_LOGS,
		call: URL + `/v2/customer/mailing/${id}/logs`,
		need_auth_token: true,
	}
}
export const GET_MAILING_LOGS_RESPONSE = 'GET_MAILING_LOGS_RESPONSE'
export const getMailingLogsReducer = bypassReducer<CR<boolean>>(GET_MAILING_LOGS, true, {})

const CLEAR_ACTION_RESPONSE = 'CLEAR_ACTION_RESPONSE'
export function clearActionResponse() {
	return { type: CLEAR_ACTION_RESPONSE }
}

export const createMailing = reduxToolkit('CREATE_MAILING',
	(params) => ({
		callPOST: URL + `/v2/customer/mailing`, queryData: {
			MailingForm: params,
		},
		payload: { params },
	}))

const GET_MAILING_AUDIENCE = 'GET_MAILING_AUDIENCE'
/**
 * получить аудитории
 */
export function getMailingAudience(): Action {
	return {
		type: GET_MAILING_AUDIENCE,
		call: URL + `/v2/customer/mailing/audience`,
		need_auth_token: true,
	}
}
export const GET_MAILING_AUDIENCE_RESPONSE = 'GET_MAILING_AUDIENCE_RESPONSE'
export const getMailingAudienceReducer = bypassReducer<CR<boolean>>(GET_MAILING_AUDIENCE, true, {})


const UPDATE_MAILING = 'UPDATE_MAILING'
export function updateMailing(id: ID, params): Action {
	return {
		type: UPDATE_MAILING,
		callPOST: URL + `/v2/customer/mailing/${id}/update`,
		need_auth_token: true,
		queryData: {
			MailingForm: params,
		},
		payload: { params },
	}
}
export const UPDATE_MAILING_RESPONSE = 'UPDATE_MAILING_RESPONSE'
export const updateMailingReducer = bypassReducer<CR<boolean>>(UPDATE_MAILING, true, {}, [LOCATION_CHANGED, CLEAR_ACTION_RESPONSE])


const DELETE_MAILING = 'DELETE_MAILING'
export function deleteMailing(id: string): Action {
	return {
		type: DELETE_MAILING,
		callPOST: URL + `/v2/customer/mailing/${id}/delete`,
		need_auth_token: true,
		payload: { id },
	}
}
export const DELETE_MAILING_RESPONSE = 'DELETE_MAILING_RESPONSE'
export const deleteMailingReducer = bypassReducer<CR<boolean>>(DELETE_MAILING, true, {}, [LOCATION_CHANGED])


const FINISH_MAILING = 'FINISH_MAILING'
export function finishMailing(id: ID): Action {
	return {
		type: FINISH_MAILING,
		callPOST: URL + `/v2/customer/mailing/${id}/finish`,
		need_auth_token: true,
		payload: { id },
	}
}
export const FINISH_MAILING_RESPONSE = 'FINISH_MAILING_RESPONSE'
export const finishMailingReducer = bypassReducer<CR<boolean>>(FINISH_MAILING, true, {}, [LOCATION_CHANGED])


const SENDS_MAILING = 'SENDS_MAILING'
export function sendsMailing(id: number | string): Action {
	return {
		type: SENDS_MAILING,
		callPOST: URL + `/v2/customer/mailing/${id}/sends`,
		need_auth_token: true,
		payload: { id },
	}
}
export const SENDS_MAILING_RESPONSE = 'SENDS_MAILING_RESPONSE'
export const sendsMailingReducer = bypassReducer<CR<boolean>>(SENDS_MAILING, true, {}, [LOCATION_CHANGED])
