import { MESSAGE_LOG, MESSAGE_SUCCESS, START, FILE_FAIL } from '../../constants'
import { enqueueSnackbar } from '../ducks/notify'

export default (store) => (next) => (action) => {
	if (!action.type.includes(START)) {
		const { response: res, notifySettings = {} } = action
		if (res && res.messages && res.messages.length) {
			// eslint-disable-next-line no-plusplus
			for (let i = 0; i < res.messages.length; i++) {
				const { type, message } = res.messages[i]
				if (type != MESSAGE_LOG) {
					const variant = type == MESSAGE_SUCCESS ? 'success' : 'error'
					store.dispatch(enqueueSnackbar({ options: { variant }, message }))
				}
			}
		}
	}
	if(action.type === FILE_FAIL) store.dispatch(enqueueSnackbar({ options: { variant: 'error' }, message: action.error }))
	return next(action)
}
