import { Action, CR, ID } from '../../../types'
import { bypassReducer, reduxToolkit } from '../../../helpers/ReduxHelper'
import { URL } from '../../../constants'
import { LOCATION_CHANGED } from '../common'

const CLEAR_ACTION_RESPONSE = 'CLEAR_ACTION_RESPONSE'
export function clearActionRespone() {
	return { type: CLEAR_ACTION_RESPONSE }
}


const DELETE_CHANNEL = 'DELETE_CHANNEL'
export function deleteChannel(id: number): Action {
	return {
		type: DELETE_CHANNEL,
		callDELETE: URL + `/v2/customer/channel/${id}`,
		need_auth_token: true,
	}
}
export const DELETE_CHANNEL_RESPONSE = 'DELETE_CHANNEL_RESPONSE'
export const deleteChannelReducer = bypassReducer<CR<boolean>>(DELETE_CHANNEL, true, {}, [LOCATION_CHANGED, CLEAR_ACTION_RESPONSE])


const UPDATE_CHANNEL = 'UPDATE_CHANNEL'
/**
 * Update channel name
 *
 * @param id - channel id.
 * @param name - channel name.
 */
export function updateChannelName(id: number | string, name: string): Action {
	return {
		type: UPDATE_CHANNEL,
		callPOST: URL + `/v2/customer/channel/${id}/name`,
		payload: { name },
		queryData: { name },
		need_auth_token: true,
	}
}
export const UPDATE_CHANNEL_RESPONSE = 'UPDATE_CHANNEL_RESPONSE'
export const updateChannelReducer = bypassReducer<CR<boolean>>(UPDATE_CHANNEL, true, {}, [LOCATION_CHANGED, CLEAR_ACTION_RESPONSE])


const UPLOAD_OLD_VK_CONTACTS = 'UPLOAD_OLD_VK_CONTACTS'
export function uploadOldVKContacts(id: number | string): Action {
	return {
		type: UPLOAD_OLD_VK_CONTACTS,
		callPOST: URL + `/v2/customer/channel/${id}/old-contact`,
		// payload: {  },
		// queryData: {  },
		need_auth_token: true,
	}
}
export const UPLOAD_OLD_VK_CONTACTS_RESPONSE = 'UPLOAD_OLD_VK_CONTACTS_RESPONSE'
export const uploadOldVKContactsReducer = bypassReducer<CR<boolean>>(UPLOAD_OLD_VK_CONTACTS, true, {}, [LOCATION_CHANGED, CLEAR_ACTION_RESPONSE])

export const addCallbackUrl = reduxToolkit('ADD_CALLBACK_URL',
	(id, callback) => ({
		callPOST: `${URL}/v2/customer/channel/${id}/callback`,
		queryData: { callback },
		payload: { id, callback },
	}))

const ADD_CHANNEL_KEY = 'ADD_CHANNEL_KEY'
/**
 * Add channel key (telegram bot)
 *
 * @param id - channel id.
 * @param key - channel key.
 */
export function addChannelKey(id: number | string, key: string): Action {
	return {
		type: ADD_CHANNEL_KEY,
		callPOST: URL + `/v2/customer/channel/${id}/key`,
		payload: { key },
		queryData: { key },
		need_auth_token: true,
	}
}
export const ADD_CHANNEL_KEY_RESPONSE = 'ADD_CHANNEL_KEY_RESPONSE'
export const addChannelKeyReducer = bypassReducer<CR<boolean>>(ADD_CHANNEL_KEY, true, {}, [LOCATION_CHANGED, CLEAR_ACTION_RESPONSE])


const IMPORT_CONTACTS = 'IMPORT_CONTACTS'
export function importContacts(id: ID, contacts: string): Action {
	return {
		type: IMPORT_CONTACTS,
		callPOST: URL + `/v2/customer/channel/${id}/import`,
		payload: { contacts },
		queryData: { contacts },
		need_auth_token: true,
	}
}
const CLEAR_IMPORT_CONTACTS = 'CLEAR_IMPORT_CONTACTS'
export const clearImportContacts = () => ({ type: CLEAR_IMPORT_CONTACTS })
export const IMPORT_CONTACTS_RESPONSE = 'IMPORT_CONTACTS_RESPONSE'
export const importContactsReducer = bypassReducer<CR<any>>(IMPORT_CONTACTS, true, {}, [LOCATION_CHANGED, CLEAR_IMPORT_CONTACTS])


