import { URL } from '../../../constants'
import { reduxToolkit } from '../../../helpers/ReduxHelper'
import { urlBuild } from '../../../helpers/stringHelpers'


export const getAudienceColor = reduxToolkit('GET_AUDIENCE_COLOR',
	(id) => ({ call: `${URL}/v2/customer/audience/${id}/color` }))

export const changeAudienceParam = reduxToolkit('CHANGE_AUDIENCE_COLOR',
	(id, param: any) => ({
		callPOST: `${URL}/v2/customer/audience-color/${id}/${Object.keys(param)[0]}`,
		queryData: param,
	}))


export const getActiveAudienceColor = reduxToolkit('GET_ACTIVE_AUDIENCE_COLOR',
	(id, params) => ({ call: `${URL}/v2/customer/audience/${id}/color-active${urlBuild(params)}` }))

export const changeAudienceActiveColor = reduxToolkit('CHANGE_AUDIENCE_ACTIVE_COLOR',
	(contact_id: number, color_id: number) => ({
		callPOST: `${URL}/v2/customer/contact/${contact_id}/change-color`,
		queryData: { color_id },
	}))
