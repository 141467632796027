import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { ExitToApp, FirstPage, GetApp, VerifiedUser } from '@material-ui/icons';
import React, { useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import styled from 'styled-components'
import Debug from '../components/common/Debug'
import { sendErrorMessageToBot } from '../redux/ducks/common'
import { moduleName, performVKMethod } from '../redux/ducks/external'
import { RootState } from "../redux/store";


const Wrapper = styled.div`
  button {
    z-index: 999;
    position: fixed;
    bottom: 20px;
    right: 20px;
  }


`
const ListWrapper = styled.div`
  width: 250px;

  a {
    text-decoration: none;
    color: black;
  }
`

interface Props extends PropsFromRedux {
}

const DevPanel: React.FC<Props> = (props) => {
	const { methodResponse, userData } = props
	// useEffect(() => { props.getSomeData() }, [])
	const [state, setState] = useState(false)
	const [debugValue, setDebugValue] = useState(null)

	const handleClose = () => setState(false)
	const handleOpen = () => setState(true)
	const handleClick = () => {
		window.VK.Api.call('groups.get', {
			user_id: userData?.data?.vk?.user_id,
			extended: 1,
			v: '5.130',
			access_token: userData?.data?.vk?.access_token,
			// user_id: 617539684
			// access_token: "236f6431c9650c873741df08c0aabf063a404ccde66c05cdf69b732e37c8707e4e1e3ca28fc613c594e11"
		}, ({ response }) => {
			setDebugValue(response)
			console.log(response)
		})
		window.VK.Api.call('apps.get', {
			app_id: 32424,
			user_id: userData?.data?.vk?.user_id,
			access_token: userData?.data?.vk?.access_token,
			v: '5.130',
		}, ({ response }) => {
			setDebugValue(response)
			console.log(response)
		})
	}
	const handleStatus = () => {
		window.VK.Auth.getLoginStatus(({ sess, status }) => setDebugValue({ sess, status }))
	}
	const handleLogout = () => {
		console.log('1')
		window.VK.Auth.logout(r => setDebugValue(r))
	}

	return (
		<Wrapper>
			<Button variant="contained" onClick={handleOpen}>DevPanel</Button>
			<Drawer anchor="right" open={state} onClose={handleClose}>
				<ListWrapper>
					<List>
						<ListItem button>
							<ListItemIcon><FirstPage /></ListItemIcon>
							<ListItemText primary={<a href="/auth/sign-in">Войти</a>} />
						</ListItem>
						<Divider />
						<ListItem button onClick={handleClick}>
							<ListItemIcon><GetApp /></ListItemIcon>
							<ListItemText primary="Get Groups" />
							{/*<ListItemSecondaryAction></ListItemSecondaryAction>*/}
						</ListItem>
						<Divider />
						<ListItem button onClick={handleStatus}>
							<ListItemIcon><VerifiedUser /></ListItemIcon>
							<ListItemText primary="Status" />
						</ListItem>
						<Divider />
						<ListItem button onClick={() => {
							props.sendErrorMessageToBot({ hello: ":BOOT" })
						}}>
							<ListItemIcon><ExitToApp /></ListItemIcon>
							<ListItemText primary="BOT" />
						</ListItem>
						<Divider />
						<ListItem button onClick={handleLogout}>
							<ListItemIcon><ExitToApp /></ListItemIcon>
							<ListItemText primary="Logout" />
						</ListItem>
						<Divider />
					</List>
					<Debug value={debugValue} />
				</ListWrapper>
			</Drawer>
		</Wrapper>
	)
}


const connector = connect((state: RootState) => ({
	methodResponse: state[moduleName + performVKMethod.response_type],
	userData: state.common.userData,
	bot: state.common.sendErrorMessageToBotResponse,

}), {
	performVKMethod: performVKMethod.action, sendErrorMessageToBot: sendErrorMessageToBot.action
})

type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(DevPanel)
