import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import ruLocale from "date-fns/locale/ru";
import React from "react";

import { Helmet } from 'react-helmet';
import { connect } from "react-redux";
import { ThemeProvider } from "styled-components";
import DevPanel from './DevPanel/DevPanel'
import Routes from "./routes/Routes";
import maTheme from "./theme";


interface Props extends cProps, cState {
	theme: any
}

const App: React.FC<Props> = (props) => {
	const { theme } = props
	const dev = window.location.host.includes('localhost') ||  window.location.host.includes('kosmoz')

	return (
		<React.Fragment>
			<StylesProvider injectFirst>
				<MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
					<MuiThemeProvider theme={maTheme[theme.currentTheme]}>
						<ThemeProvider theme={maTheme[theme.currentTheme]}>
							{dev && <DevPanel />}
							<Routes />
						</ThemeProvider>
					</MuiThemeProvider>
				</MuiPickersUtilsProvider>
			</StylesProvider>
		</React.Fragment>
	);
}


interface cState {
	theme: any
}

interface cProps {
}

// @ts-ignore
export default connect((store) => ({ theme: store.themeReducer }), {})(App);
